export enum GeneralReducerActionType {
  SUCCESS = "SUCCESS",
  LOADING = "LOADING",
  ERROR = "ERROR",
  NONE = "NONE",
}

export interface GeneralReducerState<D = unknown> {
  data: D;
  error: string | null;
  status: GeneralReducerActionType;
}

export interface GeneralReducerAction {
  type: GeneralReducerActionType;
  payload?: any;
}

const { SUCCESS, LOADING, ERROR, NONE } = GeneralReducerActionType;

export function initialReducerState(data: any = null) {
  return {
    data,
    error: null,
    status: NONE,
  };
}

/**
 * The general reducer for common states
 */
function generalReducer(
  state: GeneralReducerState,
  type: GeneralReducerActionType,
  payload?: any
): GeneralReducerState {
  switch (type) {
    case ERROR:
      return { ...state, error: payload, status: ERROR };
    case LOADING:
      return { ...state, error: null, status: LOADING };
    case SUCCESS:
      return { ...state, data: payload, status: SUCCESS };
    case NONE:
      return {
        data: Array.isArray(state.data) ? [] : null,
        status: NONE,
        error: null,
      };
    default:
      return state;
  }
}

export default generalReducer;
