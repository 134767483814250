import { useSelector, useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { Dispatch } from "redux";

import {
  GlobalState,
  ShiftsState,
  SuppliesState,
  Location,
  MessageType,
} from "../../types";
import { fetchShifts, fetchSupplies } from "../../actions";
import {
  getOutOfStockCount,
  downloadCSV,
  getLocationUniqueShiftsList,
  getLocationUniqueMessagesList,
  getLocationCleaners,
  getEmployee,
  getLocationMessages,
} from "../../utils";
import { GeneralReducerActionType } from "../../reducers";
import { MessagesState } from "../../reducers/messages";
import { SchedulesState } from "../../reducers/schedules";
import { useEmployees, UseEmployeesState } from "hooks/useEmployees";
import { useLocations, UseLocationsState } from "hooks/useLocations";

const { LOADING } = GeneralReducerActionType;

interface State {
  employees: UseEmployeesState;
  filteredLocations: Location[];
  loading: boolean;
  locations: UseLocationsState;
  messages: MessagesState["messages"];
  shifts: ShiftsState["shifts"];
  supplies: SuppliesState;
  userId?: string | null;
}

interface Actions {
  downloadStockList: () => void;
  fetchMessages: () => typeof dispatchCallback;
  fetchShifts: () => typeof dispatchCallback;
  fetchSupplies: () => typeof dispatchCallback;
}

interface UseLocations {
  state: State;
  actions: Actions;
}

function useLocationsList(
  search: string,
  messageTypeFilter: MessageType[]
): UseLocations {
  const employees = useEmployees();
  const locations = useLocations();
  const messagesState = useSelector<GlobalState, MessagesState["messages"]>(
    (state) => state.messages.messages
  );
  const schedulesState = useSelector<GlobalState, SchedulesState["schedules"]>(
    (state) => state.schedules.schedules
  );
  const shifts = useSelector<GlobalState, ShiftsState["shifts"]>(
    (state) => state.shifts.shifts
  );
  const supplies = useSelector<GlobalState, SuppliesState>(
    (state) => state.supplies
  );
  const userId = useSelector<GlobalState, string | null>(
    (state) => state.authentication.payload
  );
  const user = useMemo(
    () => employees.data.find((employee) => employee.id === userId),
    [employees.data, userId]
  );
  const dispatch = useDispatch();
  const loading = [
    locations.status === LOADING,
    supplies.isFetching,
    shifts.status === LOADING,
    messagesState.status === LOADING,
    employees.status === LOADING,
    schedulesState.status === LOADING,
  ].includes(true);
  const parseLocation = useCallback(
    (location: Location) => {
      const locationShifts = getLocationUniqueShiftsList(
        location.id,
        shifts.data
      ).map((shift) => ({
        ...shift,
        employee: getEmployee(employees.data, shift.employee),
      }));
      const uniqueLocationMessages = getLocationUniqueMessagesList(
        location.id,
        messagesState.data
      );
      const locationMessages = getLocationMessages(
        location.id,
        messagesState.data
      );
      const outOfStockCount = getOutOfStockCount(
        supplies.payload!.find((supply) => supply.location === location.id)
      );
      const scheduledEmployees = getLocationCleaners(
        employees.data,
        schedulesState.data,
        location.id
      );
      const newMessageType = locationMessages.reduce<MessageType | null>(
        (type, locationMessage) => {
          // Urgent is always more important
          if (type === MessageType.URGENT) {
            return type;
          }
          if (user?.newMessages.includes(locationMessage.id)) {
            // Account for response without a type
            return locationMessage.type || MessageType.INFO;
          }
          return type;
        },
        null
      );
      return {
        ...location,
        shifts: locationShifts,
        messages: uniqueLocationMessages,
        outOfStockCount: outOfStockCount,
        newMessageType,
        scheduledEmployees,
      };
    },
    [
      employees.data,
      messagesState.data,
      schedulesState.data,
      shifts.data,
      supplies.payload,
      user?.newMessages,
    ]
  );
  const parsedLocations = useMemo(
    () => locations.data.map(parseLocation),
    [locations.data, parseLocation]
  );

  /**
   * Downloads the out of stock csv
   */
  function downloadStockList() {
    const suppliesOutOfStock = supplies.payload
      .filter((supply) => getOutOfStockCount(supply))
      .map((supply) => {
        const location = locations.data.find(
          (item) => item.id === supply.location
        );
        const list = supply.list
          .filter((item) => !item.inStock)
          .map((item) => item.name)
          .join(", ");
        return {
          locationId: supply.location,
          locationName: location ? location.name : "Unknown Location",
          list,
        };
      });

    const csvData = suppliesOutOfStock.map((item) => {
      return {
        "Location ID": item.locationId,
        Location: item.locationName,
        "Supplies Out of Stock": item.list,
      };
    });

    downloadCSV(csvData, `bishop-oos-${new Date()}.csv`);
  }

  const filteredLocations = useMemo(() => {
    if (!loading) {
      if (!search && !messageTypeFilter.length) {
        return parsedLocations;
      }
      // Narrow by search
      return parsedLocations.filter((location) => {
        let searchMatch = false;
        let messageMatch = false;
        const lowerCaseSearch = search && search.toLowerCase();
        // Search by location name or employee name
        if (
          search &&
          (location.name.toLowerCase().includes(lowerCaseSearch) ||
            location.scheduledEmployees.some((employee) =>
              `${employee.firstName}${employee.lastName}`
                .toLowerCase()
                .includes(lowerCaseSearch)
            ))
        ) {
          searchMatch = true;
        }
        if (messageTypeFilter.length) {
          messageMatch = location.messages.some((message) =>
            messageTypeFilter.includes(message.type as MessageType)
          );
        }
        if (search && messageTypeFilter.length) {
          return searchMatch && messageMatch;
        }
        return searchMatch || messageMatch;
      });
    }
    return [];
  }, [loading, parsedLocations, search, messageTypeFilter]);

  return {
    state: {
      employees: employees,
      filteredLocations,
      loading,
      locations: locations,
      messages: messagesState,
      shifts,
      supplies,
      userId,
    },
    actions: {
      fetchMessages: () => dispatchCallback(fetchShifts, dispatch, useCallback),
      fetchShifts: () => dispatchCallback(fetchShifts, dispatch, useCallback),
      fetchSupplies: () =>
        dispatchCallback(fetchSupplies, dispatch, useCallback),
      downloadStockList,
    },
  };
}

function dispatchCallback(action: any, dispatch: Dispatch, callback: Function) {
  return callback(() => action(), [dispatch]);
}

export default useLocationsList;
