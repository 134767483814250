import {
  SecurityActionType,
  SecurityAction,
  SecurityState,
  Security,
} from "../types";

export default function securityReducer(
  state: SecurityState = {
    isFetching: false,
    payload: [],
    error: false,
  },
  { type, payload }: SecurityAction
) {
  switch (type) {
    case SecurityActionType.GET_SECURITY_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case SecurityActionType.GET_SECURITY_SUCCESS:
      return {
        ...state,
        payload: payload as Security[],
        isFetching: false,
      };
    case SecurityActionType.GET_SECURITY_ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    case SecurityActionType.UPDATE_SECURITY_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case SecurityActionType.UPDATE_SECURITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case SecurityActionType.UPDATE_SECURITY_ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    default:
      return state;
  }
}
