import * as React from "react";
import { Route, Routes as RRRoutes } from "react-router-dom";

import Dashboard from "./containers/dashboard/Dashboard";
import Login from "./containers/login/Login";
import FillIn from "./containers/fillIn/FillIn";
import Notifications from "./containers/notifications/Notifications";
import LocationDetails from "./containers/locations/LocationDetails";
import EmployeeDetails from "./containers/employees/EmployeeDetails";
import {
  InstructionsForm,
  SuppliesForm,
  SecurityForm,
  InspectionsForm,
} from "./containers/locations/forms";
import { CleaningHistory, MessageBoard } from "./containers/locations/views";
import { CleaningHistory as EmployeeCleaningHistory } from "./containers/employees/views";
import {
  ReassignShiftsForm,
  SendNotificationForm,
} from "./containers/employees/forms";
import Locations from "containers/locations/Locations";
import Employees from "containers/employees/Employees";
import AssignedCleanersForm from "features/AssignedCleanersForm/AssignedCleanersForm";

const Routes = () => {
  return (
    <RRRoutes>
      <Route path="/locations/:id" element={<LocationDetails />} />
      <Route
        path="/locations/:id/assignedCleaners"
        element={<AssignedCleanersForm />}
      />
      <Route
        path="/locations/:id/instructions"
        element={<InstructionsForm />}
      />
      <Route path="/locations/:id/inspections" element={<InspectionsForm />} />
      <Route path="/locations/:id/supplies" element={<SuppliesForm />} />
      <Route path="/locations/:id/security" element={<SecurityForm />} />
      <Route
        path="/locations/:id/cleaningHistory"
        element={<CleaningHistory />}
      />
      <Route path="/locations/:id/messageBoard" element={<MessageBoard />} />
      <Route path="/employees/:id" element={<EmployeeDetails />} />
      <Route
        path="/employees/:id/cleaningHistory"
        element={<EmployeeCleaningHistory />}
      />
      <Route
        path="/employees/:id/reassignShifts"
        element={<ReassignShiftsForm />}
      />
      <Route
        path="/employees/:id/new/sendNotification"
        element={<SendNotificationForm />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Dashboard />}>
        <Route path="locations" element={<Locations />} />
        <Route path="employees" element={<Employees />} />
        <Route path="fill-in" element={<FillIn />} />
        <Route path="notifications" element={<Notifications />} />
      </Route>
    </RRRoutes>
  );
};

export default Routes;
