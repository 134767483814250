import * as React from "react";
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import { getListCount, getLocationSecurity } from "../../../utils";
import { Security, GlobalState } from "../../../types";

interface Props {
  locationId: string;
}

function SecurityCard({ locationId }: Props) {
  const navigate = useNavigate();
  const security = useSelector<GlobalState, Security[]>(
    (state) => state.security.payload
  );
  if (!security) {
    return null;
  }
  const locationSecurity = getLocationSecurity(security, locationId);
  const listCount = getListCount(locationSecurity);
  return (
    <Card className="card-spacing">
      <CardHeader
        title="Security"
        avatar={
          <Avatar>
            <SupervisorAccount />
          </Avatar>
        }
      />
      <CardContent>
        {locationSecurity ? (
          <div className="ma w100p tac df">
            <div className={listCount ? "prs br" : ""}>
              <Typography variant="caption">Items</Typography>
              <Typography variant="body2">{listCount}</Typography>
            </div>
            {listCount ? (
              <div className="pls tal">
                <Typography variant="caption">
                  {locationSecurity.list[0].title}
                </Typography>
                <Typography variant="body2">
                  {locationSecurity.list[0].text}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          "No security set"
        )}
      </CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/locations/${locationId}/security`)}
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default SecurityCard;
