import {
  FilesActionType,
  FilesAction,
  ThunkResult,
  Attachment,
} from "../types";
import { filesService } from "../services";
import { UploadType } from "../services/filesService";
import { getDownloadURL } from "firebase/storage";

export const uploadFile = (payload: Attachment): ThunkResult<FilesAction> => {
  return async (dispatch) => {
    dispatch(uploadFileLoading());
    const snapshot = await filesService.uploadImage(
      payload.url,
      payload.name,
      UploadType.NOTIFICATIONS
    );
    const url = await getDownloadURL(snapshot.ref);
    dispatch(uploadFileSuccess({ ...payload, url }));
  };
};

export const uploadFileSuccess = (payload: Attachment): FilesAction => {
  return {
    type: FilesActionType.UPLOAD_FILE_SUCCESS,
    payload,
  };
};

export const uploadFileError = (): FilesAction => ({
  type: FilesActionType.UPLOAD_FILE_ERROR,
});

export const uploadFileLoading = (): FilesAction => ({
  type: FilesActionType.UPLOAD_FILE_LOADING,
});

export const uploadFileReset = (): FilesAction => ({
  type: FilesActionType.UPLOAD_FILE_RESET,
});
