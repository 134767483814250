import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { updateSecurity } from "../../../actions";
import {
  removeDeletedItems,
  getLocationSecurity,
  getLocation,
} from "../../../utils";
import { Security, SecurityItem, GlobalState, Location } from "../../../types";
import { Fab, DialogContent, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ClassNames } from "@emotion/react";
import { useLocations } from "hooks/useLocations";

interface State {
  security: Security;
}

function SecurityForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [state, setState] = React.useState<State>({
    security: {
      list: [],
      location: id!,
      id: "",
    },
  });
  const isSecurityLoading = useSelector<GlobalState, boolean>(
    (state) => state.security.isFetching
  );
  const locations = useLocations();
  const security = useSelector<GlobalState, Security[]>(
    (state) => state.security.payload
  );

  React.useEffect(() => {
    if (security) {
      const locationSecurity = getLocationSecurity(security, id!);
      if (locationSecurity) {
        setState({ security: locationSecurity });
      }
    }
  }, [security, id]);

  /** Handles generic changes */
  function handleChange(data: string, index: number, type: string) {
    const { security } = state;
    setState({
      security: {
        ...(security as Security),
        list: Object.assign([], (security as Security).list, {
          [index]: {
            ...(security as Security).list[index],
            [type]: data,
          },
        }),
      },
    });
  }

  /** Adds a new security item to the beginning of the list */
  function handleAddItem() {
    const { security } = state;
    if (security) {
      const list = security.list;
      list.unshift({
        title: "",
        text: "",
        deleted: false,
      });
      setState({
        security: {
          ...security,
          list,
        },
      });
    }
  }

  /** Handles item deletion */
  function handleDeletion(i: number) {
    const { security } = state;
    if (security) {
      setState({
        security: {
          ...security,
          list: Object.assign([], security.list, {
            [i]: {
              ...security.list[i],
              deleted: true,
            },
          }),
        },
      });
    }
  }

  /** Handles moving of up and down */
  function handleMove(i: number, direction: "up" | "down") {
    const { security } = state;
    if (security) {
      const displacedItem = security.list.splice(
        direction === "down" ? i + 1 : i - 1,
        1
      )[0];
      security.list.splice(i, 0, displacedItem);
      const newInstructions = { ...security };
      setState({ security: newInstructions });
    }
  }

  /** Renders the security cards */
  function renderSecurity(securityItem: SecurityItem, i: number) {
    return (
      <Card
        className={`mbm ${securityItem.deleted ? "card-disabled" : ""}`}
        key={i}
      >
        <CardContent>
          <div className="card-actions">
            <ArrowDownward
              onClick={() => handleMove(i, "down")}
              className={`cp ${
                i !== (state.security as Security).list.length - 1
              }`}
              color="action"
            />
            <ArrowUpward
              onClick={() => handleMove(i, "up")}
              className={`cp ${i !== 0}`}
              color="action"
            />
            <Delete
              onClick={() => handleDeletion(i)}
              className="cp"
              color="action"
            />
          </div>
          <div className="pbs">
            <TextField
              label="Title"
              onChange={(e) => handleChange(e.target.value, i, "title")}
              value={securityItem.title}
            />
          </div>
          <div>
            <TextField
              label="Text"
              onChange={(e) => handleChange(e.target.value, i, "text")}
              value={securityItem.text}
              multiline={true}
              fullWidth={true}
            />
          </div>
        </CardContent>
      </Card>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${id}`);
  }

  /** Handles save */
  function handleSave() {
    const { id, ...thisSecurity } = state.security as Security;
    const updatedSecurity = {
      ...thisSecurity,
      list: removeDeletedItems(thisSecurity.list),
    };
    dispatch(updateSecurity(updatedSecurity, id));
  }

  /** Render content */
  function renderContent() {
    const { security } = state;
    if (isSecurityLoading || !security) {
      return <CircularProgress />;
    }
    return security.list && security.list.map(renderSecurity);
  }

  const location = getLocation(locations.data, id!);
  return (
    <ClassNames>
      {({ css }) => {
        const appBar = css({
          position: "relative",
        });
        const flex = css({
          flex: 1,
        });
        const fab = css({
          position: "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        return (
          <Dialog fullScreen={true} open={true} onClose={handleClose}>
            <AppBar className={appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={flex}>
                  Security | {location && location.name}
                </Typography>
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent className="ptm">{renderContent()}</DialogContent>
            <Fab
              onClick={handleAddItem}
              color="primary"
              className={fab}
              variant="extended"
            >
              <Add />
              Add Security Item
            </Fab>
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default SecurityForm;
