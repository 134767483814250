import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  DialogContent,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  InputLabel,
  Fab,
  Divider,
} from "@mui/material";
import { red } from "@mui/material/colors";

import { addMessage, removeNewMessages } from "../../../actions";
import {
  getIcon,
  getLocationThreadedMessages,
  getEmployee,
  getMessage,
  getLocation,
} from "../../../utils";
import { GlobalState, Location, Message, MessageType } from "../../../types";
import { messageTypes } from "../../../constants";
import { GeneralReducerActionType } from "../../../reducers";
import { Thread } from "../../../types";
import { MessagesState } from "../../../reducers/messages";
import { ClassNames } from "@emotion/react";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";

const { LOADING, SUCCESS } = GeneralReducerActionType;

const initialNewMessage = {
  body: "",
  type: MessageType.INFO,
};

function MessageBoard() {
  const [addingMessage, setAddingMessage] = React.useState(false);
  const [replyingToId, setReplyingToId] = React.useState("");
  const [newMessage, setNewMessage] = React.useState(initialNewMessage);
  const [viewMoreId, setViewMoreId] = React.useState("");
  const employees = useEmployees();
  const messagesState = useSelector<GlobalState, MessagesState["messages"]>(
    (state) => state.messages.messages
  );
  const addMessageState = useSelector<GlobalState, MessagesState["add"]>(
    (state) => state.messages.add
  );
  const userId = useSelector<GlobalState, string | null>(
    (state) => state.authentication.payload
  );
  const locations = useLocations();
  const user = employees.data.find((employee) => employee.id === userId);
  const params = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = React.useMemo(
    () => getLocation(locations.data, params.id!!),
    [params.id, locations.data]
  );
  const threadedMessages = React.useMemo(
    () =>
      getLocationThreadedMessages(
        messagesState.data,
        params.id!!,
        user ? user.newMessages : undefined
      ),
    [params.id, messagesState.data, user]
  );

  /** Shows message area */
  function handleReply(id: string) {
    setAddingMessage(true);
    setReplyingToId(id);
  }

  /** Renders message replies */
  function renderReplies({ replies = [], id }: Message) {
    if (id === viewMoreId) {
      return renderMessages(replies as Message[], true);
    }
    if (replies.length > 3) {
      return renderMessages(
        id === viewMoreId
          ? (replies as Message[])
          : (replies.slice(0, 3) as Message[]),
        true
      );
    } else {
      return replies && renderMessages(replies as Message[], true);
    }
  }

  /** Renders a single message */
  function renderMessage(message: Thread | Message, isReply?: boolean) {
    const employee = getEmployee(employees.data, message.by);
    const name = employee
      ? `${employee.firstName} ${employee.lastName} `
      : "Unknown name";
    return (
      <ClassNames>
        {({ css }) => {
          const parentMessage = css({
            minWidth: 32,
          });
          const newIndicator = css({
            backgroundColor: red[500],
            color: "white",
          });
          const nameContainer = css({
            display: "flex",
            justifyContent: "space-between",
          });
          return (
            <>
              <ListItem key={message.id} alignItems="flex-start">
                {!isReply && (
                  <ListItemIcon className={parentMessage}>
                    {getIcon(message.type)}
                  </ListItemIcon>
                )}
                <ListItemText
                  inset={isReply}
                  primary={
                    <div className={nameContainer}>
                      <span>{name}</span>
                      {(message as Thread).new && (
                        <Chip
                          size="small"
                          label="New"
                          className={newIndicator}
                        />
                      )}
                    </div>
                  }
                  secondary={
                    <>
                      <Typography variant="caption" gutterBottom={true}>
                        {moment(message.date).format("MMMM DD h:mm a")}
                      </Typography>
                      <Typography variant="body1">{message.body}</Typography>
                      <div className="df aic">
                        {message.replies &&
                          message.replies.length > 2 &&
                          viewMoreId !== message.id && (
                            <Typography variant="caption" className="mrs">
                              3 of {message.replies.length} replies
                            </Typography>
                          )}
                      </div>
                    </>
                  }
                />
                {!isReply && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleReply(message.id)}
                  >
                    Reply
                  </Button>
                )}
              </ListItem>
              {renderReplies(message)}
              {message.replies.length &&
              viewMoreId !== message.id &&
              message.replies.length > 2 ? (
                <Button
                  size="small"
                  className="mrs"
                  color="primary"
                  onClick={() => handleViewPreviousReplies(message.id)}
                >
                  View previous replies
                </Button>
              ) : (
                ""
              )}
              {!isReply && <Divider />}
            </>
          );
        }}
      </ClassNames>
    );
  }

  function renderMessages(messages: (Thread | Message)[], isReply?: boolean) {
    return (
      // @ts-ignore
      <List component="div" disablePadding={isReply}>
        {React.Children.toArray(
          messages.map((message) => renderMessage(message, isReply))
        )}
      </List>
    );
  }

  /** Show message textarea */
  function handleAddMessage() {
    setAddingMessage(true);
  }

  /** Handle message change */
  function handleChange(data: string, type: string) {
    setNewMessage({ ...newMessage, [type]: data });
  }

  /** Posts message */
  function handlePost() {
    const { body, type } = newMessage;
    const replyingToMessage = getMessage(messagesState.data, replyingToId);
    let message = {
      by: user ? user.id! : "",
      date: new Date(),
      location: params.id!,
      body,
      type: replyingToId ? null : type,
      replies: [],
    };

    dispatch(addMessage(message, employees.data, replyingToMessage));
  }

  /** Sets which message replies are being viewed */
  function handleViewPreviousReplies(id: string) {
    setViewMoreId(id);
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${params.id}`);
  }

  /** Renders content */
  function renderContent() {
    if ([employees.status, messagesState.status].includes(LOADING)) {
      return <CircularProgress />;
    }
    return threadedMessages.length ? (
      <Card className="mtm">
        <CardContent>{renderMessages(threadedMessages)}</CardContent>
      </Card>
    ) : (
      "No messages"
    );
  }

  /** Update new messages for user */
  React.useEffect(() => {
    return () => {
      const newMessages = threadedMessages
        .filter((message) => message.new)
        .map((message) => message.id);
      if (newMessages.length) {
        dispatch(removeNewMessages(userId!, newMessages));
      }
    };
  });

  /** Reset message on add success */
  React.useEffect(() => {
    if (addMessageState.status === SUCCESS) {
      setReplyingToId("");
      setAddingMessage(false);
      setNewMessage(initialNewMessage);
    }
  }, [addMessageState.status]);

  return (
    <ClassNames>
      {({ css }) => {
        const appBar = css({
          position: "relative",
        });
        const flex = css({
          flex: 1,
        });
        const fab = css({
          position: "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        return (
          <Dialog fullScreen={true} open={true} onClose={handleClose}>
            <AppBar className={appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={flex}>
                  Message Board | {location && location.name}
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent>{renderContent()}</DialogContent>
            {!addingMessage && (
              <Fab
                onClick={handleAddMessage}
                className={fab}
                color="primary"
                variant="extended"
              >
                <Add />
                Add Message
              </Fab>
            )}
            {addingMessage && (
              <div className="fixed-textarea">
                {!replyingToId && (
                  <>
                    <InputLabel htmlFor="locations">Type</InputLabel>
                    <Select
                      value={newMessage.type}
                      // @ts-ignore
                      onChange={(e) => handleChange(e.target.value, "type")}
                      className="mbs"
                      renderValue={(selected) =>
                        messageTypes.find((type) => type.key === selected)!
                          .label
                      }
                    >
                      {messageTypes.map((type) => (
                        <MenuItem value={type.key} key={type.key}>
                          {getIcon(type.key)}
                          <ListItemText primary={type.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                <textarea
                  placeholder="Enter a message"
                  value={newMessage.body}
                  autoFocus={true}
                  onChange={(e) => handleChange(e.target.value, "body")}
                />
                <div className="df jcfe">
                  <Button
                    color="primary"
                    onClick={handlePost}
                    variant="contained"
                    endIcon={
                      addMessageState.status === LOADING ? (
                        <CircularProgress size={16} color="inherit" />
                      ) : null
                    }
                  >
                    Post
                  </Button>
                </div>
              </div>
            )}
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default MessageBoard;
