import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import PermContactCalendar from "@mui/icons-material/PermContactCalendar";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  orderWeekdays,
  getLocation,
  getEmployeeSchedule,
} from "../../../utils";
import { Schedule, GlobalState, Location } from "../../../types";
import { useLocations } from "hooks/useLocations";

function ScheduleCard() {
  const { id } = useParams();
  const schedules = useSelector<GlobalState, Schedule[]>(
    (state) => state.schedules.schedules.data
  );
  const locations = useLocations();
  const schedule = React.useMemo(
    () => getEmployeeSchedule(schedules, id!),
    [schedules, id]
  );

  /** Renders the schedule for each location */
  function renderLocationSchedule(schedule: Schedule) {
    const location = getLocation(locations.data, schedule.location);
    return (
      <Card className="mrm mbs w25p" variant="outlined">
        <CardContent>
          <Typography variant="body1" gutterBottom={true}>
            {location ? location.name : "Unknown location"}
          </Typography>
          <Typography variant="caption">
            {orderWeekdays(schedule.weekdays).join(" - ")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card style={{ margin: "0 0 1.5rem 0" }} className="schedule-card">
      <CardHeader
        title="Schedule"
        avatar={
          <Avatar>
            <PermContactCalendar />
          </Avatar>
        }
      />
      <CardContent>
        <div className="df fww">
          {schedule && schedule.length
            ? React.Children.toArray(schedule.map(renderLocationSchedule))
            : "No assigned schedule"}
        </div>
      </CardContent>
    </Card>
  );
}

export default ScheduleCard;
