import { onSnapshot, serverTimestamp } from "firebase/firestore";
import {
  collection,
  doc,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import moment from "moment";
import { Action } from "redux";

import { db } from "../firebaseconfig";
import { Message, ThunkResult, User } from "../types";
import { extractSnapshot } from "../utils";

export interface MessagesAction extends Action {
  type: MessagesActionType;
  payload?: Message[];
}

export enum MessagesActionType {
  GET_MESSAGES_LOADING = "GET_MESSAGES_LOADING",
  GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR",
  GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS",
  ADD_MESSAGE_LOADING = "ADD_MESSAGE_LOADING",
  ADD_MESSAGE_ERROR = "ADD_MESSAGE_ERROR",
  ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS",
}

/** Fetches all messages */
export const fetchMessages = (): ThunkResult<Message[]> => {
  return async (dispatch) => {
    dispatch(fetchMessagesLoading());
    onSnapshot(
      query(
        collection(db, "messages"),
        where(
          "date",
          ">=",
          moment().subtract(1, "months").startOf("day").toDate()
        ),
        orderBy("date", "desc")
      ),
      (messages) => {
        dispatch(fetchMessagesSuccess(extractSnapshot(messages)));
      }
    );
  };
};

/** Adds a message (posts message) */
export const addMessage = (
  message: Pick<Message, Exclude<keyof Message, "id">>,
  users: User[],
  replyingToMessage?: Message
): ThunkResult<MessagesAction> => {
  let batch = writeBatch(db);
  const newId = doc(collection(db, "messages")).id;
  const timestamped = {
    ...message,
    timestamp: serverTimestamp(),
  };
  // Write the new message
  batch.set(doc(db, "messages", newId), timestamped);
  if (replyingToMessage) {
    // Update the replies for the replying to message
    if (replyingToMessage.replies) {
      batch.update(doc(db, "messages", replyingToMessage.id), {
        replies: (replyingToMessage.replies as string[]).concat(newId),
      });
    } else {
      batch.update(doc(db, "messages", replyingToMessage.id), {
        replies: [newId],
      });
    }
  }
  return async (dispatch, store) => {
    dispatch(addMessageLoading());
    const newMessageId = (replyingToMessage && replyingToMessage.id) || newId;
    const locationSchedules = store().schedules.schedules.data.filter(
      (schedule) => schedule.location === message.location
    );
    const employees = users.reduce((accumulator, user) => {
      // Do not update creator's new messages since they are posting this new message
      if (user.id === message.by) {
        return accumulator;
      }
      // An admin and inspector should get all updates
      // All other users if they are scheduled for the location
      if (
        user.admin ||
        user.inspector ||
        locationSchedules.some((schedule) => schedule.employee === user.id)
      ) {
        return [...accumulator, user];
      }
      return accumulator;
    }, [] as User[]);

    // TODO: This should really be calling a function instead of doing this in the UI
    employees!.forEach((employee) => {
      const existingMessages = employee.newMessages;
      const newMessages = existingMessages
        ? [newMessageId].concat(existingMessages)
        : [newMessageId];
      batch.update(doc(db, "employees", employee.id!!), {
        newMessages,
      });
    });
    return batch.commit().then(
      () => dispatch(addMessageSuccess()),
      (error) => {
        dispatch(addMessageError());
      }
    );
  };
};

export const addMessageSuccess = (): MessagesAction => ({
  type: MessagesActionType.ADD_MESSAGE_SUCCESS,
});

export const addMessageError = (): MessagesAction => ({
  type: MessagesActionType.ADD_MESSAGE_ERROR,
});

export const addMessageLoading = (): MessagesAction => ({
  type: MessagesActionType.ADD_MESSAGE_LOADING,
});

export const fetchMessagesSuccess = (payload: Message[]): MessagesAction => ({
  type: MessagesActionType.GET_MESSAGES_SUCCESS,
  payload,
});

export const fetchMessagesError = (): MessagesAction => ({
  type: MessagesActionType.GET_MESSAGES_ERROR,
});

export const fetchMessagesLoading = (): MessagesAction => ({
  type: MessagesActionType.GET_MESSAGES_LOADING,
});
