import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import PeopleIcon from "@mui/icons-material/People";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { TextField, CircularProgress, Grid, Alert } from "@mui/material";
import { useSelector } from "react-redux";

import FillInList from "./FillInList";
import { Shift, GlobalState, ShiftsState } from "../../types";
import { GeneralReducerActionType } from "../../reducers";
import {
  renderCleaner,
  getNewAssignments,
  getEmployee,
  getReassignedShifts,
  sortByStartDate,
  getLocation,
} from "../../utils";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";
import { shiftsService } from "services";
import { Status } from "types/status";
// @ts-ignore
import styles from "./FillIn.module.scss";

const { LOADING } = GeneralReducerActionType;

function FillIn() {
  const [submissionStatus, setSubmissionStatus] = React.useState(Status.Idle);
  const [search, setSearch] = React.useState("");
  const [startDate, setStartDate] = React.useState(
    moment().subtract(10, "days").startOf("day").toDate()
  );
  const [endDate, setEndDate] = React.useState(moment().endOf("day").toDate());
  const [reassigning, setReassigning] = React.useState(false);
  const [assignTo, setAssignTo] = React.useState("");
  const [selectedShifts, setSelectedShifts] = React.useState<number[]>([]);
  const [selectedShift, setSelectedShift] = React.useState(-1);
  const [fillInShifts, setFillInShifts] = React.useState<Shift[]>([]);
  const locations = useLocations();
  const shiftsState = useSelector<GlobalState, ShiftsState["shifts"]>(
    (state) => state.shifts.shifts
  );
  const employees = useEmployees();
  const allShifts = React.useMemo(
    () => getReassignedShifts(shiftsState.data).sort(sortByStartDate),
    [shiftsState.data]
  );
  const loading = [locations.status, shiftsState.status].includes(LOADING);

  const getFillInShifts = React.useCallback(() => {
    if (!search && !endDate) {
      return allShifts;
    }
    // Narrow by search (location, employee name, end date)
    return allShifts.filter((shift) => {
      const dateMatch = filterShiftByDates(shift);
      const lowerCaseSearch = search && search.toLowerCase().replace(" ", "");
      const location = getLocation(locations.data, shift.location);
      const originalEmployee = getEmployee(employees.data, shift.employee);
      const originalEmployeeName = originalEmployee
        ? `${originalEmployee.firstName}${originalEmployee.lastName}`
            .toLowerCase()
            .replace(" ", "")
        : "";
      const employee = getEmployee(employees.data, shift.reassignedTo || "");
      const employeeName = employee
        ? `${employee.firstName}${employee.lastName}`
            .toLowerCase()
            .replace(" ", "")
        : "";
      // Search by location name or employee name
      if (search) {
        if (
          ((location &&
            location.name
              .toLowerCase()
              .replace(" ", "")
              .includes(lowerCaseSearch)) ||
            originalEmployeeName.includes(lowerCaseSearch) ||
            employeeName.includes(lowerCaseSearch)) &&
          dateMatch
        ) {
          return true;
        }
        return false;
      }
      return dateMatch;
    });
  }, [search, endDate, startDate, allShifts]);

  /**
   * Filters shifts by start and end date
   */
  function filterShiftByDates(shift: Shift) {
    if (moment(endDate).isValid() && moment(startDate).isValid()) {
      return (
        moment(shift.endDate).isBefore(endDate) &&
        moment(shift.startDate).isAfter(startDate)
      );
    }
    return true;
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  React.useEffect(() => {
    setFillInShifts(getFillInShifts());
  }, [getFillInShifts]);

  /** Saves shift assignments */
  async function handleSave() {
    const shifts = getNewAssignments(fillInShifts) as Shift[];

    if (shifts.length) {
      setSubmissionStatus(Status.Pending);
      await shiftsService.update(shifts);
      setSubmissionStatus(Status.Success);
    }
    setSelectedShifts([]);
    setSelectedShift(-1);
  }

  /** Closes the assign modal */
  function handleAssignToCleanerClose() {
    setReassigning(false);
    setSelectedShift(-1);
  }

  /** Handles cleaner assigning changes */
  function handleAssignToCleanerChange({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) {
    setAssignTo(value);
  }

  /** Handles the selection */
  function handleSelect(checked: boolean, index: number) {
    if (checked) {
      setSelectedShifts([index].concat(selectedShifts));
    } else {
      setSelectedShifts(
        selectedShifts.filter((shiftIndex) => shiftIndex !== index)
      );
    }
  }

  /** Handles unassign all */
  function handleUnassignAll() {
    const allSelected = fillInShifts.length === selectedShifts.length;
    const updatedShifts = fillInShifts.map((shift, index) => {
      if (allSelected || selectedShifts.includes(index)) {
        return {
          ...shift,
          newAssignment: true,
          reassignedTo: "",
          unassigned: true,
        };
      }
      return shift;
    });
    setFillInShifts(updatedShifts);
  }

  /** Assigns a shift to a cleaner */
  function handleAssignToCleaner() {
    const allSelected = fillInShifts.length === selectedShifts.length;
    let updatedShifts: any = [];
    if (selectedShift !== -1) {
      updatedShifts = Object.assign([], fillInShifts, {
        [selectedShift]: {
          ...fillInShifts[selectedShift],
          unassigned: false,
          reassignedTo: assignTo,
          newAssignment: true,
        },
      });
      setSelectedShift(-1);
    } else {
      updatedShifts = fillInShifts.map((shift, index) => {
        if (allSelected || selectedShifts.includes(index)) {
          return {
            ...shift,
            unassigned: false,
            reassignedTo: assignTo,
            newAssignment: true,
          };
        } else {
          return shift;
        }
      });
    }
    setReassigning(false);
    setAssignTo("");
    setFillInShifts(updatedShifts);
  }

  function handleAssignAll() {
    setReassigning(true);
  }

  /** Unassigns a shift */
  function handleToggleUnassignShift(index: number) {
    const updatedFillInShifts = Object.assign([], fillInShifts, {
      [index]: {
        ...fillInShifts[index],
        unassigned: true,
        reassignedTo: null,
        newAssignment: true,
      },
    });
    setFillInShifts(updatedFillInShifts);
  }

  /**
   * Toggles the selected shifts from all or none
   */
  function toggleSelectAll() {
    if (selectedShifts.length) {
      setSelectedShifts([]);
    } else {
      setSelectedShifts(fillInShifts.map((shift, index) => index));
    }
  }

  /** Shows modal and sets the selected shift if shiftSelected exists */
  function showAssignToCleanerModal(shift: number) {
    setReassigning(true);
    setSelectedShift(shift);
  }

  const assignedToEmployee = getEmployee(employees.data, assignTo);

  return (
    <div className="df unassigned">
      <Dialog open={reassigning}>
        <DialogTitle>Assign to cleaner</DialogTitle>
        <DialogContent>
          <div className="df aic">
            <PeopleIcon className="mrs" />
            <Select
              className={styles.cleaner}
              value={assignTo}
              input={<Input id="cleaner" />}
              // @ts-ignore
              onChange={handleAssignToCleanerChange}
              renderValue={() =>
                assignedToEmployee
                  ? `${assignedToEmployee.firstName} ${assignedToEmployee.lastName}`
                  : "Unknown"
              }
            >
              {employees.data.map(renderCleaner)}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignToCleanerClose}>Cancel</Button>
          <Button color="primary" onClick={handleAssignToCleaner}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container={true} spacing={8}>
        <Grid item={true} md={3} sm={12} xs={12}>
          <Typography variant="h6" gutterBottom={true}>
            Search
          </Typography>
          <TextField
            placeholder="Search by location or cleaner"
            onChange={handleSearchChange}
            value={search}
            className={styles.input}
          />
          <Typography variant="h6" gutterBottom={true}>
            Filters
          </Typography>
          <TextField
            label="Start Date"
            id="startDate"
            type="date"
            defaultValue={moment(startDate).format("YYYY-MM-DD")}
            onChange={(e) =>
              setStartDate(moment(e.target.value).startOf("day").toDate())
            }
            className={styles.date}
            required={true}
          />
          <TextField
            label="End Date"
            className={styles.date}
            id="endDate"
            type="date"
            defaultValue={moment(endDate).format("YYYY-MM-DD")}
            onChange={(e) =>
              setEndDate(moment(e.target.value).endOf("day").toDate())
            }
            required={true}
            inputProps={{
              min: moment(startDate).format("YYYY-MM-DD"),
            }}
          />
        </Grid>
        <Grid item={true} md={9} sm={12}>
          <div className="df jcsb aic">
            <div className="df">
              <Typography variant="h6" gutterBottom={true}>
                {loading ? "-" : fillInShifts.length}
              </Typography>
              <div className="mrs" />
              <Typography variant="h6" gutterBottom={true}>
                Shifts
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              className="mvs"
              disabled={submissionStatus === Status.Pending}
              startIcon={
                submissionStatus === Status.Pending ? (
                  <CircularProgress color="secondary" size={16} />
                ) : undefined
              }
            >
              Save
            </Button>
          </div>
          {submissionStatus === Status.Success ? (
            <Alert variant="filled" severity="success" className={styles.alert}>
              Shifts updated successfully.
            </Alert>
          ) : null}
          {loading ? (
            <CircularProgress />
          ) : (
            <FillInList
              onAssign={showAssignToCleanerModal}
              onAssignAll={handleAssignAll}
              onSelect={handleSelect}
              onToggleSelectAll={toggleSelectAll}
              onUnassign={handleToggleUnassignShift}
              onUnassignAll={handleUnassignAll}
              selectedShifts={selectedShifts}
              shifts={fillInShifts}
              employees={employees.data}
              locations={locations.data}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default FillIn;
