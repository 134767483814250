import { db } from "../firebaseconfig";
import { extractSnapshot } from "../utils";
import {
  ThunkResult,
  Notification,
  NotificationsAction,
  NotificationsActionType,
  PendingNotification,
} from "../types";
import { notificationsService } from "../services";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import {
  collection,
  doc,
  orderBy,
  query,
  writeBatch,
} from "firebase/firestore";

export const fetchNotifications = (): ThunkResult<NotificationsAction> => {
  return async (dispatch) => {
    dispatch(fetchNotificationsLoading());
    onSnapshot(
      query(collection(db, "notifications"), orderBy("date", "desc")),
      (notifications) => {
        dispatch(fetchNotificationsSuccess(extractSnapshot(notifications)));
      }
    );
  };
};

export const updateNotifications = (
  notifications: PendingNotification[]
): ThunkResult<NotificationsAction> => {
  let batch = writeBatch(db);
  for (let i = 0; i < notifications.length; i++) {
    const { id, newlyAdded, deleted, ...rest } = notifications[i];
    if (newlyAdded) {
      const timestamped = {
        ...rest,
        timestamp: serverTimestamp(),
      };
      batch.set(doc(db, "notifications"), timestamped);
    } else {
      if (deleted) {
        batch.delete(doc(db, "notifications", id!!));
      } else {
        batch.set(doc(db, "notifications", id!!), rest);
      }
    }
  }
  return async (dispatch) => {
    dispatch(updateNotificationsLoading());
    return batch.commit().then(() => dispatch(updateNotificationsSuccess()));
  };
};

export const createNotification = (
  notification: Pick<Notification, Exclude<keyof Notification, "id" | "new">>
): ThunkResult<NotificationsAction> => {
  return async (dispatch) => {
    dispatch(createNotificationLoading());
    const timestamped = {
      ...notification,
      timestamp: serverTimestamp(),
    };
    return notificationsService
      .create(timestamped)
      .then(() => dispatch(createNotificationSuccess()));
  };
};

export const fetchNotificationsSuccess = (
  payload: Notification[]
): NotificationsAction => ({
  type: NotificationsActionType.GET_NOTIFICATIONS_SUCCESS,
  payload,
});

export const fetchNotificationsError = (): NotificationsAction => ({
  type: NotificationsActionType.GET_NOTIFICATIONS_ERROR,
});

export const fetchNotificationsLoading = (): NotificationsAction => ({
  type: NotificationsActionType.GET_NOTIFICATIONS_LOADING,
});

export const updateNotificationsSuccess = (): NotificationsAction => ({
  type: NotificationsActionType.UPDATE_NOTIFICATIONS_SUCCESS,
});

export const updateNotificationsError = (): NotificationsAction => ({
  type: NotificationsActionType.UPDATE_NOTIFICATIONS_ERROR,
});

export const updateNotificationsLoading = (): NotificationsAction => ({
  type: NotificationsActionType.UPDATE_NOTIFICATIONS_LOADING,
});

export const createNotificationSuccess = (): NotificationsAction => ({
  type: NotificationsActionType.CREATE_NOTIFICATION_SUCCESS,
});

export const createNotificationError = (): NotificationsAction => ({
  type: NotificationsActionType.CREATE_NOTIFICATION_ERROR,
});

export const createNotificationLoading = (): NotificationsAction => ({
  type: NotificationsActionType.CREATE_NOTIFICATION_LOADING,
});

export const createNotificationReset = (): NotificationsAction => ({
  type: NotificationsActionType.CREATE_NOTIFICATION_RESET,
});
