import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  Fab,
  ListItemText,
  InputLabel,
  ListItemIcon,
  Select,
  FormControl,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ClassNames } from "@emotion/react";

import { updateInstructions } from "../../../actions";
import {
  removeDeletedItems,
  getIcon,
  getLocationInstructions,
  getLocation,
} from "../../../utils";
import {
  Instruction,
  InstructionItem,
  GlobalState,
} from "../../../types";
import { instructionTypes } from "../../../constants";
import { useLocations } from "hooks/useLocations";

interface State {
  instruction: Instruction | null;
}

const initialState: State = {
  instruction: null,
};

function InstructionsForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const instructions = useSelector<GlobalState, Instruction[]>(
    (state) => state.instructions.payload
  );
  const locations = useLocations();
  const [state, setState] = React.useState(initialState);

  React.useEffect(() => {
    // Set instruction
    if (instructions && !state.instruction) {
      const instruction = getLocationInstructions(instructions, id!);
      setState({ instruction: instruction || null });
    }
  }, [instructions, state.instruction, id]);

  /** Handles generic changes */
  function handleChange(data: string, i: number, type: string) {
    const { instruction } = state;
    setState({
      instruction: {
        ...(instruction! as Instruction),
        list: Object.assign([], (instruction! as Instruction).list, {
          [i]: {
            ...(instruction! as Instruction).list[i],
            [type]: data,
          },
        }),
      },
    });
  }

  /** Adds items */
  function handleAddInstruction() {
    const { instruction } = state;
    if (instruction) {
      setState({
        instruction: {
          ...(instruction! as Instruction),
          list: [
            {
              text: "",
              title: "",
              type: "",
            },
            ...(instruction! as Instruction).list,
          ],
        },
      });
    } else {
      setState({
        instruction: {
          list: [
            {
              text: "",
              title: "",
              type: "",
            },
          ],
          location: id!,
          id: "",
        },
      });
    }
  }

  /** Deletes items */
  function handleDeletion(i: number) {
    const { instruction } = state;
    setState({
      instruction: {
        ...(instruction! as Instruction),
        list: Object.assign([], (instruction! as Instruction).list, {
          [i]: {
            ...(instruction! as Instruction).list[i],
            deleted: true,
          },
        }),
      },
    });
  }

  /** Moves items */
  function handleMove(i: number, direction: "down" | "up") {
    const { instruction } = state;
    const displacedItem = (instruction! as Instruction).list.splice(
      direction === "down" ? i + 1 : i - 1,
      1
    )[0];
    (instruction! as Instruction).list.splice(i, 0, displacedItem);
    const newInstruction = { ...(instruction! as Instruction) };
    setState({ instruction: newInstruction });
  }

  function renderInstructions(instruction: InstructionItem, i: number) {
    return (
      <Card
        className={`mbm ${instruction.deleted ? "card-disabled" : ""}`}
        key={i}
      >
        <CardContent>
          <div className="card-actions">
            <ArrowDownward
              onClick={() => handleMove(i, "down")}
              className={`cp ${
                i !== (state.instruction! as Instruction).list.length - 1
              }`}
            />
            <ArrowUpward
              onClick={() => handleMove(i, "up")}
              className={`cp ${i !== 0}`}
            />
            <Delete onClick={() => handleDeletion(i)} className="cp" />
          </div>
          <div className="pbs df aic">
            <div>
              <FormControl sx={{ minWidth: 124 }}>
                <InputLabel id="type">Type</InputLabel>
                <Select
                  label="Type"
                  labelId="type"
                  value={instruction.type}
                  // @ts-ignore
                  onChange={(e) => handleChange(e.target.value, i, "type")}
                  className="mrm"
                  renderValue={(selected) =>
                    instructionTypes.find((type) => type.key === selected)!
                      .label
                  }
                >
                  <MenuItem value="daily">
                    <ListItemIcon>{getIcon("daily")}</ListItemIcon>
                    <ListItemText primary="Daily" />
                  </MenuItem>
                  <MenuItem value="weekly">
                    <ListItemIcon>{getIcon("weekly")}</ListItemIcon>
                    <ListItemText primary="Weekly" />
                  </MenuItem>
                  <MenuItem value="monthly">
                    <ListItemIcon>{getIcon("monthly")}</ListItemIcon>
                    <ListItemText primary="Monthly" />
                  </MenuItem>
                  <MenuItem value="special">
                    <ListItemIcon>{getIcon("special")}</ListItemIcon>
                    <ListItemText primary="Special" />
                  </MenuItem>
                  <MenuItem value="new">
                    <ListItemIcon>{getIcon("new")}</ListItemIcon>
                    <ListItemText primary="New" />
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                label="Title"
                onChange={(e) => handleChange(e.target.value, i, "title")}
                value={instruction.title}
              />
            </div>
          </div>
          <div>
            <TextField
              label="Text"
              onChange={(e) => handleChange(e.target.value, i, "text")}
              value={instruction.text}
              multiline={true}
              fullWidth={true}
            />
          </div>
        </CardContent>
      </Card>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${id}`);
  }

  /** Handles save */
  function handleSave() {
    const { id, ...theseInstructions } = state.instruction! as Instruction;
    const instruction = {
      ...theseInstructions,
      list: removeDeletedItems(theseInstructions.list) as InstructionItem[],
    };
    dispatch(updateInstructions(instruction, id));
  }

  const location = React.useMemo(
    () => getLocation(locations.data, id!),
    [locations, id]
  );
  const { instruction } = state;
  return (
    <ClassNames>
      {({ css }) => {
        const appBar = css({
          position: "relative",
        });
        const flex = css({
          flex: 1,
        });
        const fab = css({
          position: "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        return (
          <Dialog fullScreen={true} open={true} onClose={handleClose}>
            <AppBar className={appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={flex}>
                  Instructions | {location && location.name}
                </Typography>
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="pm">
              {instruction &&
                (instruction as Instruction).list &&
                (instruction as Instruction).list.map(renderInstructions)}
            </div>
            <Fab
              onClick={handleAddInstruction}
              color="primary"
              className={fab}
              variant="extended"
            >
              <Add />
              Add Instruction
            </Fab>
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default InstructionsForm;
