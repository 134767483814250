import { db } from "../firebaseconfig";
import { extractSnapshot } from "../utils";
import {
  ThunkResult,
  Supply,
  SuppliesActionType,
  SuppliesAction,
} from "../types";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";

/** Fetch supplies */
export const fetchSupplies = (): ThunkResult<Supply[]> => {
  return async (dispatch) => {
    dispatch(fetchSuppliesLoading());
    onSnapshot(collection(db, "supplies"), (supplies) => {
      dispatch(fetchSuppliesSuccess(extractSnapshot(supplies)));
    });
  };
};

export const updateSupplies = (supply: Supply): ThunkResult<SuppliesAction> => {
  const { id, ...rest } = supply;
  return async (dispatch) => {
    const timestamped = {
      ...rest,
      timestamp: serverTimestamp(),
    };
    return id
      ? // @ts-ignore
        updateDoc(doc(db, "supplies", id), rest).then(() =>
          dispatch(updateSuppliesSuccess())
        )
      : addDoc(collection(db, "supplies"), timestamped).then(() =>
          dispatch(updateSuppliesSuccess())
        );
  };
};

export const fetchSuppliesSuccess = (payload: Supply[]) => {
  return {
    type: SuppliesActionType.GET_SUPPLIES_SUCCESS,
    payload,
  };
};

export const fetchSuppliesError = (): SuppliesAction => ({
  type: SuppliesActionType.GET_SUPPLIES_ERROR,
});

export const fetchSuppliesLoading = (): SuppliesAction => ({
  type: SuppliesActionType.GET_SUPPLIES_LOADING,
});

export const updateSuppliesSuccess = (): SuppliesAction => ({
  type: SuppliesActionType.UPDATE_SUPPLIES_SUCCESS,
});

export const updateSuppliesError = (): SuppliesAction => ({
  type: SuppliesActionType.UPDATE_SUPPLIES_ERROR,
});

export const updateSuppliesLoading = (): SuppliesAction => ({
  type: SuppliesActionType.UPDATE_SUPPLIES_LOADING,
});
