import {
  onSnapshot,
  collection,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import { db } from "../firebaseconfig";
import { Shift, ShiftsActionType, ThunkResult } from "../types";
import { extractSnapshot } from "../utils";

export const fetchShifts = (): ThunkResult<Shift[]> => {
  return async (dispatch) => {
    onSnapshot(
      query(
        collection(db, "shifts"),
        where(
          "startDate",
          ">=",
          moment().subtract(1, "months").startOf("day").toDate()
        ),
        orderBy("startDate", "desc")
      ),
      (shifts) => {
        const parsedShifts = extractSnapshot<Shift[]>(shifts);
        dispatch(fetchShiftsSuccess(parsedShifts));
      }
    );
  };
};

export const fetchShiftsSuccess = (payload: Shift[]) => ({
  type: ShiftsActionType.GET_SHIFTS_SUCCESS,
  payload,
});

export const fetchShiftsError = () => ({
  type: ShiftsActionType.GET_SHIFTS_ERROR,
});

export const fetchShiftsLoading = () => ({
  type: ShiftsActionType.GET_SHIFTS_LOADING,
});
