import * as React from "react";
import { connect } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ChatBubble from "@mui/icons-material/ChatBubble";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import {
  CardActions,
  DialogActions,
  Typography,
  DialogContent,
} from "@mui/material";

import { User, Credentials } from "../../../types";
import { updateUserLogin } from "../../../actions";

interface MapDispatchToProps {
  updateUserLogin: (credentials: Credentials, id: string) => void;
}

interface PinCardProps {
  employee?: User;
}

interface State {
  pin: string;
  originalPin: string;
  isPinVisible: boolean;
  isResetting: boolean;
}

type Props = PinCardProps & MapDispatchToProps;

class PinCard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { employee } = this.props;
    this.state = {
      pin: employee ? employee.pin : "",
      originalPin: employee ? employee.pin : "",
      isPinVisible: false,
      isResetting: false,
    };
  }

  componentDidUpdate() {
    if (this.props.employee) {
      const { pin } = this.props.employee;
      if (pin !== this.state.originalPin) {
        this.setState({
          pin,
          originalPin: pin,
        });
      }
    }
  }

  handleResetSave = () => {
    if (this.props.employee) {
      this.setState({ isResetting: false });
      this.props.updateUserLogin(
        { pin: this.state.pin },
        this.props.employee.id as string
      );
    }
  };

  handleResetCancel = () => {
    this.setState({ pin: this.state.originalPin, isResetting: false });
  };

  handleReset = () => {
    this.setState({ isResetting: true });
  };

  togglePinShow = () => {
    this.setState({ isPinVisible: !this.state.isPinVisible });
  };

  handlePinChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ pin: value });
  };

  render() {
    const { isPinVisible, isResetting, pin, originalPin } = this.state;
    return (
      <>
        <Card style={{ margin: "1.5rem 0 1.5rem 0" }}>
          <CardHeader
            title="Security Pin"
            subheader="View or change security pin"
            avatar={
              <Avatar>
                <ChatBubble />
              </Avatar>
            }
          />
          <CardContent>
            <Typography variant="caption">Pin</Typography>
            <Typography variant="body1">
              {isPinVisible ? originalPin : "******"}
            </Typography>
          </CardContent>
          <CardActions>
            <div className="mla">
              <Button color="primary" onClick={this.togglePinShow}>
                {isPinVisible ? "Hide" : "Show"}
              </Button>
              <Button
                color="primary"
                onClick={this.handleReset}
                variant="contained"
              >
                Reset
              </Button>
            </div>
          </CardActions>
        </Card>
        <Dialog title="Reset Security Pin" open={isResetting}>
          <DialogContent>
            <TextField
              label="Pin"
              className="mrm iwm"
              onChange={this.handlePinChange}
              value={pin}
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleResetCancel} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleResetSave}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(null, { updateUserLogin })(PinCard);
