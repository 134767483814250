import * as React from "react";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Search from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getStatus,
  getLocationRecentInspection,
  getEmployee,
} from "../../../utils";
import { Inspection, GlobalState } from "../../../types";
import { useEmployees } from "hooks/useEmployees";
import { GeneralReducerActionType } from "reducers";

interface Props {
  locationId: string;
}

function InspectionsCard({ locationId }: Props) {
  const navigate = useNavigate();
  const inspections = useSelector<GlobalState, Inspection[]>(
    (state) => state.inspections.inspections.data
  );
  const employees = useEmployees();
  const inspection = getLocationRecentInspection(inspections, locationId);
  const employee = inspection
    ? getEmployee(employees.data, inspection.inspector)
    : null;
  if (!inspections || employees.status === GeneralReducerActionType.LOADING) {
    return null;
  }
  return (
    <Card>
      <CardHeader
        title="Inspections"
        avatar={
          <Avatar>
            <Search />
          </Avatar>
        }
      />
      <CardContent>
        {inspection ? (
          <div>
            <Typography variant="caption">Inspector</Typography>
            <Typography variant="body1">
              {employee ? `${employee.firstName} ${employee.lastName}` : ""}
            </Typography>
            <Typography variant="caption">Last Date of Inspection</Typography>
            <Typography variant="body1">
              {moment(inspection.date).format("MM/DD/YYYY")}
            </Typography>
            <Typography variant="caption">Status</Typography>
            <Typography variant="body1">
              {getStatus(inspection.status)}
            </Typography>
          </div>
        ) : (
          "No inspections set"
        )}
      </CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/locations/${locationId}/inspections`)}
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default InspectionsCard;
