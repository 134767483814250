import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Send from "@mui/icons-material/Send";
import { CardActions, Typography } from "@mui/material";

import { GlobalState, Notification } from "../../../types";
import { getEmployeeNotifications } from "../../../utils";

function SendNotificationCard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const notifications = useSelector<GlobalState, Notification[]>(
    (state) => state.notifications.notifications.data
  );
  const employeeNotifications = getEmployeeNotifications(notifications, id!);

  return (
    <Card style={{ margin: "1.5rem 0 1.5rem 0" }}>
      <CardHeader
        title="Notifications"
        subheader="Manage cleaner notifications"
        avatar={
          <Avatar>
            <Send />
          </Avatar>
        }
      />
      <CardContent>
        {employeeNotifications ? (
          <div className="ma wmc tac">
            <Typography variant="caption">Sent</Typography>
            <Typography variant="body2">
              {employeeNotifications.length}
            </Typography>
          </div>
        ) : (
          "No notifications"
        )}
      </CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/employees/${id}/new/sendNotification`)}
            variant="contained"
          >
            New
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default SendNotificationCard;
