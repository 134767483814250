import {
  doc,
  FieldPath,
  serverTimestamp,
  WhereFilterOp,
  writeBatch,
} from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Schedule } from "types";

import { db } from "../firebaseconfig";

const frequentShiftsService = {
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(
        collection(db, "frequentShifts"),
        where(fieldPath, operation, value)
      )
    );
  },
  async update(schedules: Omit<Schedule, "timestamp">[]) {
    let batch = writeBatch(db);

    for (let i = 0; i < schedules.length; i++) {
      const { id, newShift, deleted, ...thisShift } = schedules[i];

      if (newShift) {
        const newId = doc(collection(db, "frequentShifts")).id;
        const timestamped = {
          ...thisShift,
          timestamp: serverTimestamp(),
        };
        batch.set(doc(db, "frequentShifts", newId), timestamped);
      } else {
        if (deleted) {
          batch.delete(doc(db, "frequentShifts", id));
        } else {
          if (
            JSON.stringify(thisShift) !==
            JSON.stringify(schedules.find((shift) => shift.id === id))
          ) {
            batch.set(doc(db, "frequentShifts", id), thisShift);
          }
        }
      }
    }

    return await batch.commit();
  },
};

export default frequentShiftsService;
