import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB7zC-X0qCh7PmHO5DSRgws75cAgNG147w",
  authDomain: "bishop-clean-care.firebaseapp.com",
  projectId: "bishop-clean-care",
  storageBucket: "bishop-clean-care.appspot.com",
};
const app = initializeApp(firebaseConfig);
// import { getAuth } from "firebase/auth";

// Required for side-effects
// require("firebase/firestore");

// firebase.initializeApp({
//   apiKey: 'AIzaSyB7zC-X0qCh7PmHO5DSRgws75cAgNG147w',
//   authDomain: 'bishop-clean-care.firebaseapp.com',
//   projectId: 'bishop-clean-care',
//   storageBucket: 'bishop-clean-care.appspot.com'
// });

// Initialize Cloud Firestore through Firebase
// export const db = firebase.firestore();
export const db = getFirestore(app);
export const storage = getStorage(app);

// export const dbAuth = getAuth(app);
