import { endOfWeek, isBefore } from "date-fns";
import moment from "moment";
import { ReassignShift, Schedule, ScheduleFrequency, Shift } from "types";
import { DateFilter } from "types/filters";
import { orderWeekdays } from "utils";

// Uses frequent shifts to build out 'faux' shifts which can be reassigned that occur in the future
export default function upcomingUserShifts(
  schedules: Schedule[] = [],
  existingShifts: Shift[] = [],
  dateFilter: DateFilter
) {
  let weeks = moment(dateFilter.end).diff(dateFilter.start, "week") + 2;
  let upcomingShifts: ReassignShift[] = [];
  let sortedShifts = [];
  // @ts-ignore
  const endDate = dateFilter.end
    ? moment(dateFilter.end).endOf("day")
    : moment().endOf("day");
  let startDate = moment(dateFilter.start).startOf("day");
  schedules.forEach((schedule) => {
    // For every weekday on this schedule
    orderWeekdays(schedule.weekdays).forEach((day) => {
      // Build out X weeks for this weekday
      for (let j = 0; j < weeks; j++) {
        // Get current date based on week and weekday
        let date = startDate.clone().add(j, "weeks").day(day);
        const shiftStartDate = moment(
          `${date.format("MM/DD/YYYY")} ${schedule.start}`
        ).toDate();

        // Schedules that are only meant to last for one week should break the loop if the date range is past the first week
        if (schedule.frequency === ScheduleFrequency.SingleWeek) {
          const endWeekday = endOfWeek(schedule.timestamp?.toDate());

          if (!isBefore(shiftStartDate, endWeekday)) {
            continue;
          }
        }

        // Show only shifts after the start date, before the end date
        if (
          moment(date).isSameOrAfter(startDate) &&
          moment(date).isSameOrBefore(endDate) &&
          moment(shiftStartDate).isAfter(startDate)
        ) {
          const upcomingShift = {
            ...schedule,
            id: "",
            date: shiftStartDate,
            status: "",
            startDate: shiftStartDate,
            endDate: moment(
              `${
                schedule.end === "12:00 AM"
                  ? moment(date).add(1, "d").format("MM/DD/YYYY")
                  : date.format("MM/DD/YYYY")
              } ${schedule.end}`
            ).toDate(),
            checkOut: null,
            checkIn: null,
            scheduleId: schedule.id,
            unassigned: false,
            deleted: false,
          };
          // Add existing shift to list
          if (!existingShifts.length) {
            upcomingShifts.push(upcomingShift);
          } else {
            // Existing shift for this date
            const thisExistingShift = existingShifts.find((existingShift) => {
              if (
                moment(existingShift.startDate).format("MM/DD/YYYY") ===
                  date.format("MM/DD/YYYY") &&
                existingShift.scheduleId === schedule.id &&
                (existingShift.employee === schedule.employee ||
                  existingShift.reassignedTo === schedule.employee)
              ) {
                return true;
              }
              return false;
            });
            if (!thisExistingShift) {
              upcomingShifts.push(upcomingShift);
            }
          }
        }
      }
    });
  });
  let missingShifts: ReassignShift[] = [];
  if (existingShifts && existingShifts.length) {
    // Check for existing shifts that have not beed added
    missingShifts = existingShifts
      .filter(
        (existingShift) =>
          moment(existingShift.startDate).isSameOrAfter(startDate) &&
          moment(existingShift.endDate).isSameOrBefore(endDate) &&
          !upcomingShifts.find(
            (upcomingShift) => upcomingShift.id === existingShift.id
          )
      )
      .map((shift) => ({ ...shift, deleted: false }));
  }
  // Concat shifts and sort
  sortedShifts = upcomingShifts
    .concat(missingShifts)
    .sort((a, b) => moment(a.startDate).diff(b.startDate));
  return sortedShifts;
}
