import * as React from "react";
import Linkify from "react-linkify";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { ClassNames } from "@emotion/react";
import { ListItemIcon, Divider } from "@mui/material";

import { Media } from "../components";
import { Notification as NotificationType, User } from "../types";
import { getIcon, getEmployee } from "../utils";

interface Props {
  notification: NotificationType;
  employees: User[] | null;
}

/** A single notification */
function Notification({ notification, employees }: Props) {
  /** Renders employee names */
  function renderEmployeeNames(users: string[]) {
    const employeeNames = users
      .map((user) => {
        const employee = getEmployee(employees, user);
        return employee
          ? `${employee.firstName} ${employee.lastName}`
          : "Unknown Name";
      })
      .join(", ");

    return (
      <ClassNames>
        {({ css }) => (
          <Typography
            variant="caption"
            component="div"
            className={css({ margin: "1rem 0 .5rem 0" })}
          >
            Sent to: {employeeNames}
          </Typography>
        )}
      </ClassNames>
    );
  }
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>{getIcon(notification.type)}</ListItemIcon>
      <ListItemText
        primary={notification.title}
        secondary={
          <>
            <Typography variant="caption" gutterBottom={true}>
              {moment(notification.date).format("MMMM DD YYYY h:mm:ss a")}
            </Typography>
            <Typography gutterBottom={true}>
              {<Linkify>{notification.text}</Linkify>}
            </Typography>
            {notification.attachment && (
              <Media attachment={notification.attachment} />
            )}
            {renderEmployeeNames(notification.users)}
          </>
        }
      />
      <Divider />
    </ListItem>
  );
}

export default Notification;
