import * as React from "react";
import AppBar from "@mui/material/AppBar";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import LocationOn from "@mui/icons-material/LocationOn";
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ClassNames } from "@emotion/react";

import { LocationEditForm } from "./forms";
import {
  AssignedCleanersCard,
  CleaningHistoryCard,
  InspectionsCard,
  InstructionsCard,
  MessageBoardCard,
  SecurityCard,
  SuppliesCard,
} from "./cards";
import { getLocation } from "../../utils";
import { GlobalState, Location, Message, User } from "../../types";
import { GeneralReducerActionType, GeneralReducerState } from "../../reducers";
import { deleteLocation, deleteLocationReset } from "../../actions";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";

const { LOADING, SUCCESS } = GeneralReducerActionType;

function LocationDetails() {
  const { id: locationId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locations = useLocations();
  const deleteLocationState = useSelector<GlobalState, GeneralReducerState>(
    (storeState) => storeState.location.delete
  );
  const employees = useEmployees();
  const userId = useSelector<GlobalState, string | null>(
    (storeState) => storeState.authentication.payload
  );
  const user = React.useMemo(
    () => employees.data.find((employee) => employee.id === userId),
    [employees.data, userId]
  );
  const messages = useSelector<GlobalState, Message[]>(
    (storeState) => storeState.messages.messages.data
  );
  const [deleting, setDeleting] = React.useState(false);
  const location = React.useMemo(
    () => getLocation(locations.data, locationId!),
    [locations.data, locationId]
  );
  const deletePending = deleteLocationState.status === LOADING;

  const redirectToLocations = React.useCallback(() => {
    navigate("/locations");
  }, [navigate]);

  function toggleDeleteLocation() {
    setDeleting(!deleting);
  }

  // Redirect on delete success
  React.useEffect(() => {
    if (deleteLocationState.status === SUCCESS) {
      dispatch(deleteLocationReset());
      redirectToLocations();
    }
  }, [deleteLocationState.status, dispatch, redirectToLocations]);

  function handleSeeAll() {
    navigate(`/locations/${locationId}/messageBoard`);
  }

  if (!location) {
    return null;
  }
  return (
    <ClassNames>
      {({ css }) => {
        const toolbar = css({
          display: "flex",
          justifyContent: "space-between",
        });
        const whiteIcon = css({
          color: "white",
        });
        return (
          <div>
            <Dialog open={deleting}>
              <DialogTitle>Delete Location</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this location?
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleDeleteLocation}>Cancel</Button>
                <Button
                  color="secondary"
                  onClick={() => dispatch(deleteLocation(locationId!))}
                  variant="contained"
                  disabled={deletePending}
                >
                  {deletePending ? "Deleting..." : "Delete"}
                </Button>
              </DialogActions>
            </Dialog>
            <AppBar position="static" color="primary">
              <Toolbar className={toolbar}>
                <IconButton color="inherit" onClick={redirectToLocations}>
                  <ArrowBack />
                </IconButton>
                <IconButton color="inherit" onClick={toggleDeleteLocation}>
                  <Delete />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="bgprimary pbm phl pts location-header">
              <div className="df">
                <Typography variant="h4" className="cw" color="inherit">
                  {location.name}
                </Typography>
                <p className="ctrans mls">{location.assignedId}</p>
              </div>
              <div className="df pts location-summary cwhite fww">
                <div className="df mrm mbs">
                  <LocationOn className={whiteIcon} />
                  <div className="pls">
                    <Typography className="ctrans" variant="caption">
                      Location
                    </Typography>
                    <p>{location.address.line1}</p>
                    <p>{location.address.line2}</p>
                    <p>{`${location.address.city}, ${location.address.state} ${location.address.postal}`}</p>
                    <p>{location.address.phone}</p>
                  </div>
                </div>
                <div className="df mrm mbs">
                  <SupervisorAccount className={whiteIcon} />
                  <div className="pls">
                    <Typography className="ctrans" variant="caption">
                      Supervisor
                    </Typography>
                    <p>
                      {location.supervisor ? location.supervisor.name : "-"}
                    </p>
                  </div>
                </div>
                <div className="df mrm mbs">
                  <PhoneIphone className={whiteIcon} />
                  <div className="pls">
                    <Typography className="ctrans" variant="caption">
                      Contact (Primary)
                    </Typography>
                    {location.contact.primary ? (
                      <>
                        <p>{location.contact.primary.name}</p>
                        <p>{location.contact.primary.phone}</p>
                        <p>{location.contact.primary.email}</p>
                      </>
                    ) : (
                      "- "
                    )}
                  </div>
                </div>
                {location.contact.secondary && location.contact.secondary.name && (
                  <div className="df mrm mbs">
                    <PhoneIphone className={whiteIcon} />
                    <div className="pls">
                      <Typography className="ctrans" variant="caption">
                        Contact (Secondary)
                      </Typography>
                      <p>{location.contact.secondary.name}</p>
                      <p>{location.contact.secondary.phone}</p>
                      <p>{location.contact.secondary.email}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <LocationEditForm location={location} />
            <div style={{ padding: 32 }}>
              <Grid container={true} spacing={8}>
                <Grid item={true} md={8} xs={12}>
                  <Grid item={true} md={12} xs={12}>
                    <CleaningHistoryCard locationId={locationId!} />
                  </Grid>
                  <Grid item={true} md={12} xs={12}>
                    <AssignedCleanersCard locationId={locationId!} />
                  </Grid>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} md={4} xs={12}>
                      <SuppliesCard locationId={locationId!} />
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                      <InstructionsCard locationId={locationId!} />
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                      <SecurityCard locationId={locationId!} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} md={4} xs={12}>
                  <Grid item={true} md={12} xs={12}>
                    <InspectionsCard locationId={locationId!} />
                  </Grid>
                  <Grid item={true} md={12} xs={12}>
                    <MessageBoardCard
                      employees={employees.data}
                      locationId={locationId!}
                      messages={messages}
                      newMessages={user && user.newMessages}
                      onSeeAll={handleSeeAll}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      }}
    </ClassNames>
  );
}

export default LocationDetails;
