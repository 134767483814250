import { limit, serverTimestamp } from "firebase/firestore";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { Action } from "redux";

import { db } from "../firebaseconfig";
import { userService } from "../services";
import { User, ThunkResult, Credentials } from "../types";

export interface UserAction extends Action {
  type: UserActionType;
  payload?: null;
}

export enum UserActionType {
  ADD_USER_ERROR = "ADD_USER_ERROR",
  ADD_USER_LOADING = "ADD_USER_LOADING",
  ADD_USER_SUCCESS = "ADD_USER_SUCCESS",
  DELETE_USER_ERROR = "DELETE_USER_ERROR",
  DELETE_USER_LOADING = "DELETE_USER_LOADING",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  REMOVE_NEW_MESSAGES_ERROR = "REMOVE_NEW_MESSAGES_ERROR",
  REMOVE_NEW_MESSAGES_LOADING = "REMOVE_NEW_MESSAGES_LOADING",
  REMOVE_NEW_MESSAGES_SUCCESS = "REMOVE_NEW_MESSAGES_SUCCESS",
  REMOVE_ALL_NEW_MESSAGES_ERROR = "REMOVE_ALL_NEW_MESSAGES_ERROR",
  REMOVE_ALL_NEW_MESSAGES_LOADING = "REMOVE_ALL_NEW_MESSAGES_LOADING",
  REMOVE_ALL_NEW_MESSAGES_SUCCESS = "REMOVE_ALL_NEW_MESSAGES_SUCCESS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
  UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
  UPDATE_USER_LOGIN_ERROR = "UPDATE_USER_LOGIN_ERROR",
  UPDATE_USER_LOGIN_LOADING = "UPDATE_USER_LOGIN_LOADING",
  UPDATE_USER_LOGIN_SUCCESS = "UPDATE_USER_LOGIN_SUCCESS",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
}

/** Updates a user login */
export const updateUserLogin = (
  login: Credentials,
  id: string
): ThunkResult<UserAction> => {
  return async (dispatch) => {
    dispatch(updateUserLoginLoading());
    return setDoc(doc(db, "employees", id), login, { merge: true }).then(() =>
      dispatch(updateUserLoginSuccess())
    );
  };
};

/** Updates a user's info */
export const updateUser = (
  user: Partial<User>,
  id: string
): ThunkResult<UserAction> => {
  return async (dispatch) => {
    dispatch(updateUserLoading());
    return setDoc(doc(db, "employees", id), user, { merge: true }).then(() =>
      dispatch(updateUserSuccess())
    );
  };
};

/** Deletes a user */
export const deleteUser = (id: string): ThunkResult<null> => {
  let batch = writeBatch(db);
  return async (dispatch) => {
    dispatch(deleteUserLoading());
    return getDocs(
      query(collection(db, "notifications"), where("employee", "==", id))
    )
      .then((querySnapshot) =>
        querySnapshot.forEach((doc) => batch.delete(doc.ref))
      )
      .then(() =>
        getDocs(
          query(collection(db, "inspections"), where("inspector", "==", id))
        )
          .then((querySnapshot) =>
            querySnapshot.forEach((doc) => batch.delete(doc.ref))
          )
          .then(() =>
            getDocs(
              query(
                collection(db, "frequentShifts"),
                where("employee", "==", id)
              )
            )
              .then((querySnapshot) =>
                querySnapshot.forEach((doc) => batch.delete(doc.ref))
              )
              .then(() =>
                getDocs(
                  query(
                    collection(db, "shifts"),
                    where("employee", "==", id),
                    limit(495)
                  )
                )
                  .then((querySnapshot) =>
                    querySnapshot.forEach((doc) => batch.delete(doc.ref))
                  )
                  .then(() =>
                    getDocs(
                      query(
                        collection(db, "frequentInspections"),
                        where("inspector", "==", id)
                      )
                    )
                      .then((querySnapshot) =>
                        querySnapshot.forEach((doc) => batch.delete(doc.ref))
                      )
                      .then(async () => {
                        batch.delete(doc(db, "employees", id));
                        return batch
                          .commit()
                          .then(() => dispatch(deleteUserSuccess()));
                      })
                  )
              )
          )
      );
  };
};

export const addUser = (user: User): ThunkResult<UserAction> => {
  return async (dispatch) => {
    dispatch(addUserLoading());
    const timestamped = {
      ...user,
      timestamp: serverTimestamp(),
    };
    return addDoc(collection(db, "employees"), timestamped).then(() =>
      dispatch(addUserSuccess())
    );
  };
};

/** Removes new messages from the user's new messages list */
export const removeNewMessages = (
  userId: string,
  messageIds: string[]
): ThunkResult<UserAction> => {
  return async (dispatch) => {
    dispatch(removeNewMessagesLoading());
    return userService
      .removeNewMessages(userId, messageIds)
      .then(() => dispatch(removeNewMessagesSuccess()));
  };
};

/** Removes new messages from the user's new messages list */
export const removeAllNewMessages = (
  userId: string
): ThunkResult<UserAction> => {
  return async (dispatch) => {
    dispatch(removeAllNewMessagesLoading());
    return userService
      .removeAllNewMessages(userId)
      .then(() => dispatch(removeAllNewMessagesSuccess()));
  };
};

export const updateUserLoginSuccess = (): UserAction => ({
  type: UserActionType.UPDATE_USER_LOGIN_SUCCESS,
});

export const updateUserLoginLoading = (): UserAction => ({
  type: UserActionType.UPDATE_USER_LOGIN_LOADING,
});

export const updateUserSuccess = (): UserAction => ({
  type: UserActionType.UPDATE_USER_SUCCESS,
});

export const updateUserLoading = (): UserAction => ({
  type: UserActionType.UPDATE_USER_LOADING,
});

export const deleteUserSuccess = (): UserAction => ({
  type: UserActionType.DELETE_USER_SUCCESS,
});

export const deleteUserLoading = (): UserAction => ({
  type: UserActionType.DELETE_USER_LOADING,
});

export const addUserSuccess = (): UserAction => ({
  type: UserActionType.ADD_USER_SUCCESS,
});

export const addUserLoading = (): UserAction => ({
  type: UserActionType.ADD_USER_LOADING,
});

export const removeNewMessagesSuccess = (): UserAction => ({
  type: UserActionType.REMOVE_NEW_MESSAGES_SUCCESS,
});

export const removeNewMessagesLoading = (): UserAction => ({
  type: UserActionType.REMOVE_NEW_MESSAGES_LOADING,
});

export const removeAllNewMessagesSuccess = (): UserAction => ({
  type: UserActionType.REMOVE_ALL_NEW_MESSAGES_SUCCESS,
});

export const removeAllNewMessagesLoading = (): UserAction => ({
  type: UserActionType.REMOVE_ALL_NEW_MESSAGES_LOADING,
});
