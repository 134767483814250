import { UserAction, UserActionType } from "../actions";
import { UserState } from "../types";

export default function userReducer(
  state: UserState = {
    isFetching: false,
    error: false,
    payload: null,
  },
  { type }: UserAction
) {
  switch (type) {
    case UserActionType.ADD_USER_LOADING:
    case UserActionType.DELETE_USER_LOADING:
    case UserActionType.UPDATE_USER_LOGIN_LOADING:
    case UserActionType.UPDATE_USER_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case UserActionType.ADD_USER_SUCCESS:
    case UserActionType.DELETE_USER_SUCCESS:
    case UserActionType.UPDATE_USER_LOGIN_SUCCESS:
    case UserActionType.UPDATE_USER_SUCCESS:
    case UserActionType.ADD_USER_ERROR:
    case UserActionType.DELETE_USER_ERROR:
    case UserActionType.UPDATE_USER_LOGIN_ERROR:
    case UserActionType.UPDATE_USER_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
