import React from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

import {
  Inspection,
  InspectionFrequency,
  User,
  InspectionSchedule as IInspectionSchedule,
} from "types";
import { getEmployee } from "utils";
import Event from "@mui/icons-material/Event";
import moment from "moment";
import { SupervisorAccount } from "@mui/icons-material";
import { inspectionFrequencies } from "../constants";

interface Props {
  employees: User[];
  inspections: Inspection[];
  onChange(value: string, name: string): void;
  schedule: IInspectionSchedule | null;
}

function InspectionSchedule({
  employees,
  onChange,
  schedule,
  inspections,
}: Props) {
  const inspectors = employees!.filter((employee) => employee.inspector);
  const inspectorMenuItems = inspectors.map((inspector) => {
    const employee = getEmployee(employees, inspector.id!)!;
    return (
      <MenuItem value={employee.id} key={employee.id}>
        <ListItemText primary={`${employee.firstName} ${employee.lastName}`} />
      </MenuItem>
    );
  });

  /** Gets the next inspection date based on last inspection date. */
  function getNextInspectionDate(frequency?: InspectionFrequency) {
    const hasInspection = inspections && inspections.length;
    const lastInspectionDate = hasInspection
      ? (inspections[0] as Inspection).date
      : moment();
    return hasInspection
      ? moment(lastInspectionDate).add(frequency, "w").format("MM/DD/YYYY")
      : "No recent inspection.";
  }

  return (
    <div className="df aic">
      <div className="df aic">
        <Event className="mrs" color="action" />
        <div>
          <InputLabel>Next Inspection Date</InputLabel>
          {getNextInspectionDate(schedule?.frequency)}
        </div>
      </div>
      <div className="df aic mls">
        <Event className="mrs" color="action" />
        <div>
          <FormControl sx={{ minWidth: 124 }}>
            <InputLabel id="frequency">Frequency</InputLabel>
            <Select
              label="Frequency"
              labelId="frequency"
              value={schedule ? schedule.frequency : ""}
              className="mrm"
              // @ts-ignore
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLSelectElement>) =>
                onChange(value, "frequency")
              }
            >
              {Object.keys(inspectionFrequencies).map((frequency) => (
                <MenuItem value={frequency} key={frequency}>
                  <ListItemText
                    primary={inspectionFrequencies[Number(frequency)]}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="df aic mls">
        <SupervisorAccount className="mrs" color="action" />
        <div>
          <FormControl>
            <InputLabel id="inspector">Inspector</InputLabel>
            <Select
              label="Inspector"
              labelId="inspector"
              value={
                schedule && schedule.inspector
                  ? schedule.inspector
                  : inspectors[0]
                  ? inspectors[0].id
                  : ""
              }
              // @ts-ignore
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLSelectElement>) =>
                onChange(value, "inspector")
              }
              className="mrm"
            >
              {inspectorMenuItems}
            </Select>
          </FormControl>
        </div>
      </div>
      <Divider />
    </div>
  );
}

export default InspectionSchedule;
