import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Event from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import PeopleIcon from "@mui/icons-material/People";
import Person from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  InputLabel,
  TextField,
  Chip,
  Alert,
  CircularProgress,
} from "@mui/material";

import {
  renderCleaner,
  getNewAssignments,
  getEmployee,
  getLocation,
  getStatus,
} from "../../../utils";
import { GlobalState, Shift, ReassignShift } from "../../../types";
import { useNavigate, useParams } from "react-router-dom";
import { DateFilter } from "types/filters";
import upcomingUserShifts from "utils/upcomingUserShifts";
import { useEmployees } from "hooks/useEmployees";
import { GeneralReducerActionType } from "reducers";
import { useLocations } from "hooks/useLocations";
// @ts-ignore
import styles from "./ReassignShiftsForm.module.scss";
import { shiftsService } from "services";
import { Status } from "types/status";
import { useSchedules } from "hooks/useSchedules";

interface State {
  isAllChecked: boolean;
  assignedTo: string;
  checked: number[];
  dateFilter: DateFilter;
  isReassigning: boolean;
  shiftSelected: number | null;
  shifts: Shift[];
  upcomingShifts: ReassignShift[];
}

const initialState: State = {
  isAllChecked: false,
  assignedTo: "",
  checked: [-1],
  dateFilter: {
    start: moment().startOf("day").toDate(),
    end: moment().add(2, "weeks").endOf("day").toDate(),
  },
  isReassigning: false,
  shiftSelected: null,
  shifts: [],
  upcomingShifts: [],
};

function ReassignShiftsForm() {
  const params = useParams();
  const navigate = useNavigate();
  const [state, setState] = React.useState(initialState);
  const [submissionStatus, setSubmissionStatus] = React.useState(Status.Idle);
  const employees = useEmployees();
  const locations = useLocations();
  const schedules = useSchedules();
  const shifts = useSelector<GlobalState, Shift[]>(
    (state) => state.shifts.shifts.data
  );

  /** Handles assign select change */
  function handleAssignToCleanerChange({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) {
    setState({ ...state, assignedTo: value });
  }

  function handleChecked(checked: boolean, index: number) {
    setState({
      ...state,
      checked: checked
        ? [index].concat(state.checked)
        : state.checked.filter((item) => item !== index),
    });
  }

  function handleToggleUnassignShift(index: number) {
    const { upcomingShifts } = state;
    const shift = upcomingShifts[index];
    setState({
      ...state,
      upcomingShifts: Object.assign([], upcomingShifts, {
        [index]: {
          ...(shift as ReassignShift),
          unassigned: !(shift as Shift).unassigned,
          reassignedTo: null,
          newAssignment:
            !(shift as Shift).unassigned || (shift as Shift).id ? true : false,
        },
      }),
    });
  }

  /** Assigns a shift to a cleaner */
  function handleAssignToCleaner() {
    const { isAllChecked, checked, shiftSelected, upcomingShifts, assignedTo } =
      state;
    let updatedShifts = [];
    if (shiftSelected !== null) {
      // @ts-ignore
      const shift = upcomingShifts[shiftSelected as number];
      updatedShifts = Object.assign([], upcomingShifts, {
        // @ts-ignore
        [shiftSelected as number]: {
          ...(shift as ReassignShift),
          reassignedTo: assignedTo,
          newAssignment: true,
          unassigned: false,
        },
      });
    } else {
      updatedShifts = (upcomingShifts as ReassignShift[]).map(
        (shift, index) => {
          if (isAllChecked || checked.indexOf(index) > -1) {
            return {
              ...shift,
              reassignedTo: assignedTo,
              newAssignment: true,
              unassigned: false,
            };
          } else {
            return shift;
          }
        }
      );
    }

    setState({
      ...state,
      upcomingShifts: updatedShifts,
      assignedTo: "",
      isReassigning: false,
      shiftSelected: null,
    });
  }

  /** Unassigns all shifts selected */
  function handleUnassignAll() {
    const { isAllChecked, checked, upcomingShifts } = state;
    let updatedShifts = [];
    updatedShifts = (upcomingShifts as ReassignShift[]).map((shift, index) => {
      if (isAllChecked || checked.indexOf(index) > -1) {
        return {
          ...shift,
          unassigned: !shift.unassigned,
          reassignedTo: "",
          newAssignment: !shift.unassigned ? true : false,
        };
      } else {
        return shift;
      }
    });

    setState({
      ...state,
      upcomingShifts: updatedShifts,
    });
  }

  function handleAssignToCleanerClose() {
    setState({
      ...state,
      isReassigning: false,
      shiftSelected: null,
    });
  }

  function getAllCheckedValues(isAllChecked: boolean) {
    if (isAllChecked) {
      return [];
    } else {
      return state.upcomingShifts.map((item, i) => i);
    }
  }

  function toggleSelectAll() {
    setState({
      ...state,
      isAllChecked: !state.isAllChecked,
      checked: getAllCheckedValues(state.isAllChecked),
    });
  }

  function handleAssignAll() {
    setState({ ...state, isReassigning: true });
  }

  /** Sets start or end date */
  function handleDateChange({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      dateFilter: {
        ...state.dateFilter,
        [name]:
          name === "start"
            ? moment(value).startOf("day").toDate()
            : moment(value).endOf("day").toDate(),
      },
    });
  }

  /** Renders all shifts */
  function renderShift(shift: ReassignShift, index: number) {
    const employee = getEmployee(employees.data, shift.reassignedTo || "");
    const location = getLocation(locations.data, shift.location);
    return (
      <div className="separate" key={index}>
        <div
          className={`hidden-actions pvs df jcsb aic ${
            shift.deleted ? "card-disabled" : ""
          }`}
        >
          <div className="df aic">
            <Checkbox
              className="checkbox--small-padding"
              checked={
                state.checked.indexOf(index) !== -1 || state.isAllChecked
              }
              key={index}
              onChange={(e) => handleChecked(e.target.checked, index)}
              color="primary"
            />
            <div>
              <Typography variant="body1">
                {location ? location.name : "Unknown location"}
              </Typography>
              <Typography variant="caption">
                {moment(shift.startDate).format("ddd | MMMM DD")}{" "}
                {moment(shift.startDate).format("h:mm A")} -{" "}
                {moment(shift.endDate).format("h:mm A")}{" "}
              </Typography>
              {shift.status ? getStatus(shift.status) : null}
            </div>
          </div>
          <div className="df aic">
            {shift.reassignedTo && (
              <Chip
                label={`Reassigned to: ${
                  employee
                    ? `${employee.firstName} ${employee.lastName}`
                    : "Unknown cleaner"
                }`}
                className="mls"
              />
            )}
            {shift.unassigned && (
              <Chip label="Unassigned" variant="outlined" className="mls" />
            )}
            <div className="mls df soh">
              <PersonOutline
                className="cp mrs"
                onClick={() => handleToggleUnassignShift(index)}
              />
              <Person
                onClick={() => showAssignToCleanerModal(index)}
                className="cp"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/employees/${params.id}`);
  }

  /** Handles saving */
  async function handleSave() {
    if (state.upcomingShifts) {
      const upcomingShifts = getNewAssignments(state.upcomingShifts);
      if (upcomingShifts.length) {
        setSubmissionStatus(Status.Pending);
        try {
          // @ts-expect-error Need to fix this type
          await shiftsService.update(upcomingShifts);
          setSubmissionStatus(Status.Success);
        } catch (error) {
          console.log(error);
          setSubmissionStatus(Status.Error);
        }
      }
    }
  }

  /** Shows modal and sets the selected shift if shiftSelected exists */
  function showAssignToCleanerModal(shiftSelected: number) {
    setState({ ...state, isReassigning: true, shiftSelected });
  }

  /** Sets the list of shifts to reassign */
  const setUpcomingShifts = React.useCallback(() => {
    const { dateFilter } = state;
    if (shifts && schedules.data.length) {
      const filteredShifts = shifts.filter(
        (shift) =>
          shift.employee === params.id || shift.reassignedTo === params.id
      );
      const filteredschedules = schedules.data.filter(
        (shift) => shift.employee === params.id
      );

      setState({
        ...state,
        upcomingShifts: upcomingUserShifts(
          filteredschedules,
          filteredShifts,
          dateFilter
        ),
        checked: [],
        isAllChecked: false,
      });
    }
  }, [state.dateFilter, shifts, schedules.data]);

  React.useEffect(() => {
    setUpcomingShifts();
  }, [setUpcomingShifts]);

  const {
    isReassigning,
    dateFilter: { end },
    assignedTo,
  } = state;
  if (
    !shifts ||
    [employees.status, locations.status, schedules.status].includes(
      GeneralReducerActionType.LOADING
    )
  ) {
    return null;
  }
  const employee = getEmployee(employees.data, params.id!);
  return (
    <div className="bglg vh100 edit-form">
      <Dialog fullScreen={true} open={true} onClose={handleClose}>
        <AppBar className={styles.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={styles.flex}>
              Reassign Shifts
            </Typography>
            <IconButton
              color="inherit"
              onClick={handleSave}
              disabled={submissionStatus === Status.Pending}
            >
              {submissionStatus === Status.Pending ? (
                <CircularProgress color="secondary" size={16} />
              ) : (
                <SaveIcon />
              )}
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={styles.content}>
          {submissionStatus === Status.Success ? (
            <Alert variant="filled" severity="success" className={styles.alert}>
              Shifts updated successfully.
            </Alert>
          ) : null}
          {submissionStatus === Status.Error ? (
            <Alert variant="filled" severity="error" className={styles.alert}>
              There was an error reassigning shifts.
            </Alert>
          ) : null}
          {(state.dateFilter.start || state.dateFilter.end) &&
          !state.upcomingShifts.length ? (
            <Alert variant="filled" severity="info" className={styles.alert}>
              No upcoming shifts for the selected date range.
            </Alert>
          ) : null}
          {!state.dateFilter.start &&
          !state.dateFilter.end &&
          !state.upcomingShifts.length ? (
            <Alert variant="filled" severity="info" className={styles.alert}>
              No upcoming shifts.
            </Alert>
          ) : null}
          <div className="df aic separate pvs mbs">
            <Event className="mrs" color="action" />
            <TextField
              id="startDate"
              label="Start Date"
              type="date"
              name="start"
              defaultValue={moment().startOf("day").format("YYYY-MM-DD")}
              onChange={handleDateChange}
              inputProps={{
                min: moment().startOf("day").format("YYYY-MM-DD"),
              }}
              className="mrs"
            />
            <TextField
              id="endDate"
              label="End Date"
              type="date"
              name="end"
              defaultValue={moment(end).format("YYYY-MM-DD")}
              onChange={handleDateChange}
            />
          </div>
          <Typography gutterBottom={true} variant="h6">
            {`Upcoming shifts for ${
              employee ? `${employee.firstName}  ${employee.lastName}` : ""
            }`}
          </Typography>
          {state.upcomingShifts.length ? (
            <Card>
              <CardContent>
                <div className="pvs separate df jcsb aic">
                  <div className="df aic">
                    <Checkbox
                      className="checkbox--small-padding"
                      onChange={toggleSelectAll}
                      color="primary"
                    />
                    <InputLabel>Select All</InputLabel>
                  </div>
                  {state.checked.length ? (
                    <div className="df">
                      <PersonOutline
                        onClick={() => handleUnassignAll()}
                        className="cp mrs"
                      />
                      <Person
                        onClick={() => handleAssignAll()}
                        className="cp"
                      />
                    </div>
                  ) : null}
                </div>
                {state.upcomingShifts.map(renderShift)}
              </CardContent>
            </Card>
          ) : null}
        </DialogContent>
      </Dialog>
      <Dialog open={isReassigning} onClose={handleAssignToCleanerClose}>
        <DialogTitle>Assign to cleaner</DialogTitle>
        <DialogContent>
          <div className="df aic">
            <PeopleIcon className="mrs" color="action" />
            <Select
              value={assignedTo}
              className={styles.cleaner}
              // @ts-ignore
              onChange={handleAssignToCleanerChange}
              input={<Input />}
            >
              {employees.data.map(renderCleaner)}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignToCleanerClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleAssignToCleaner}
            variant="contained"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReassignShiftsForm;
