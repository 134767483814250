import { Action } from "redux";

import { db } from "../firebaseconfig";
import { ThunkResult, Location } from "../types";
import { locationService } from "../services";
import { serverTimestamp } from "firebase/firestore";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";

export interface LocationAction extends Action {
  type: LocationActionType;
  payload?: Location[];
}

export enum LocationActionType {
  ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR",
  ADD_LOCATION_LOADING = "ADD_LOCATION_LOADING",
  ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS",
  DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR",
  DELETE_LOCATION_LOADING = "DELETE_LOCATION_LOADING",
  DELETE_LOCATION_RESET = "DELETE_LOCATION_RESET",
  DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS",
  GET_LOCATION_ERROR = "GET_LOCATION_ERROR",
  GET_LOCATION_LOADING = "GET_LOCATION_LOADING",
  GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS",
  UPDATE_LOCATION_ERROR = "UPDATE_LOCATION_ERROR",
  UPDATE_LOCATION_LOADING = "UPDATE_LOCATION_LOADING",
  UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS",
}

export const updateLocation = (
  location: Pick<Location, Exclude<keyof Location, "id">>,
  id: string
): ThunkResult<LocationAction> => {
  return async (dispatch) => {
    dispatch(updateLocationLoading());
    setDoc(doc(db, "locations", id), location).then(() =>
      dispatch(updateLocationSuccess())
    );
  };
};

export const addLocation = (
  location: Partial<Location>
): ThunkResult<LocationAction> => {
  return async (dispatch) => {
    dispatch(addLocationLoading());
    const timestamped = {
      ...location,
      timestamp: serverTimestamp(),
    };
    addDoc(collection(db, "locations"), timestamped).then(() =>
      dispatch(addLocationSuccess())
    );
  };
};

export const deleteLocation = (id: string): ThunkResult<LocationAction> => {
  return async (dispatch) => {
    dispatch(deleteLocationLoading());
    locationService.delete(id).then(() => dispatch(deleteLocationSuccess()));
  };
};

export const addLocationSuccess = () => ({
  type: LocationActionType.ADD_LOCATION_SUCCESS,
});

export const addLocationError = () => ({
  type: LocationActionType.ADD_LOCATION_ERROR,
});

export const addLocationLoading = () => ({
  type: LocationActionType.ADD_LOCATION_LOADING,
});

export const updateLocationSuccess = () => ({
  type: LocationActionType.UPDATE_LOCATION_SUCCESS,
});

export const updateLocationError = () => ({
  type: LocationActionType.UPDATE_LOCATION_ERROR,
});

export const updateLocationLoading = () => ({
  type: LocationActionType.UPDATE_LOCATION_LOADING,
});

export const deleteLocationSuccess = () => ({
  type: LocationActionType.DELETE_LOCATION_SUCCESS,
});

export const deleteLocationReset = () => ({
  type: LocationActionType.DELETE_LOCATION_RESET,
});

export const deleteLocationError = () => ({
  type: LocationActionType.DELETE_LOCATION_ERROR,
});

export const deleteLocationLoading = () => ({
  type: LocationActionType.DELETE_LOCATION_LOADING,
});
