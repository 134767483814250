import * as React from "react";
import AddCircle from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";

import { Inspection, InspectionItem } from "../../../types";
import { InspectionForm } from "components";

// TODO: There is a lot of prop drilling happening here, fix it

interface EditInspectionProps {
  inspection: Inspection;
  isAddingItem: boolean;
  isEditing: boolean;
  isOpen: boolean;
  item: InspectionItem;
  locationName: string;
  onAddItem: () => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onClose: () => void;
  onDeleteListItem: (index: number) => void;
  onEditItem: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
  onToggleAddItem: () => void;
}

type Props = EditInspectionProps;

function EditInspection({
  inspection,
  isAddingItem,
  isEditing,
  isOpen,
  item,
  locationName,
  onAddItem,
  onChange,
  onClose,
  onDeleteListItem,
  onEditItem,
  onSave,
  onToggleAddItem,
}: Props) {
  /** Handles the deletion of an item */
  function handleListItemDeletion(itemIndex: number) {
    onDeleteListItem(itemIndex);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        scroll="body"
        className="print-modal edit-inspection"
      >
        <DialogTitle>
          {isEditing ? "Edit Inspection" : "Add Inspection"}
        </DialogTitle>
        <DialogContent>
          <InspectionForm
            onItemDeletion={handleListItemDeletion}
            inspection={inspection}
            locationName={locationName}
            onChange={onChange}
          />
          <Button
            className="hide-for-print"
            onClick={onToggleAddItem}
            color="primary"
            variant="outlined"
          >
            <AddCircle className="mrs" />
            Add Inspection Item
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" className="hide-for-print">
            Cancel
          </Button>
          <Button
            onClick={onSave}
            color="primary"
            className="hide-for-print"
            variant="contained"
          >
            {isEditing ? "Save" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isAddingItem} onClose={onToggleAddItem}>
        <DialogTitle>Add Inspection Item</DialogTitle>
        <DialogContent>
          <Input
            onChange={onEditItem}
            defaultValue={item.title}
            fullWidth={true}
            placeholder="Title"
            name="inspectionItemTitle"
            className="mbm"
          />
          <TextField
            onChange={onEditItem}
            defaultValue={item.text}
            fullWidth={true}
            placeholder="Text"
            name="inspectionItemText"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onToggleAddItem}
            color="primary"
            className="hide-for-print"
          >
            Cancel
          </Button>
          <Button
            onClick={onAddItem}
            color="primary"
            className="hide-for-print"
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditInspection;
