import { ref, uploadString } from "firebase/storage";
import { storage } from "../firebaseconfig";

export enum UploadType {
  INSPECTIONS = "inspections",
  NOTIFICATIONS = "notifications",
}

const filesService = {
  uploadImage(url: string, fileName: string, type: UploadType) {
    return uploadString(ref(storage, `${type}/${fileName}`), url, "data_url");
  },
};

export default filesService;
