import * as React from "react";
import AddCircle from "@mui/icons-material/AddCircle";
import Checkbox from "@mui/material/Checkbox";
import Edit from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import FormControlLabel from "@mui/material/FormControlLabel";
import PhoneIPhone from "@mui/icons-material/PhoneIphone";
import Save from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";

import { User } from "../../../types";
import { formatPhoneNumber } from "../../../utils";
import { updateUser } from "../../../actions";

interface Props {
  employee: User;
}

interface FormData {
  admin: boolean;
  email: string;
  firstName: string;
  inspector: boolean;
  lastName: string;
  phone: string;
  secondaryPhone?: string;
}

function EmployeeEditForm({ employee }: Props) {
  const dispatch = useDispatch();
  const [editing, setEditing] = React.useState(false);
  const [formData, setFormData] = React.useState<FormData>({
    admin: employee.admin || false,
    email: employee.email || "",
    firstName: employee.firstName || "",
    inspector: employee.inspector || false,
    lastName: employee.lastName || "",
    phone: employee.phone || "",
    secondaryPhone: employee.secondaryPhone || "",
  });

  /** Handle general input change */
  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setFormData({ ...formData, [name]: value });
  }

  /** Handle phone number blur */
  function handlePhoneBlur({
    target: { name, value },
  }: React.FocusEvent<HTMLInputElement>) {
    setFormData({ ...formData, [name]: formatPhoneNumber(value) });
  }

  function handleEditEmployee() {
    if (editing) {
      dispatch(updateUser(formData, employee.id as string));
    }
    setEditing(!editing);
  }

  function handleCheckboxChange(name: "admin" | "inspector") {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  }

  return (
    <div className="pr">
      <div className="employee-edit-form">
        <div className={`${editing ? "visible" : "hidden"}`}>
          <div className="pvm phl bgwhite">
            <div className="df aic separate pbs">
              <AddCircle className="mrs" />
              <TextField
                label="First Name"
                onChange={handleChange}
                value={formData.firstName}
                className="mrs"
                name="firstName"
              />
              <TextField
                label="Last Name"
                onChange={handleChange}
                value={formData.lastName}
                name="lastName"
              />
            </div>
            <div className="df aic pbs mts">
              <PhoneIPhone className="mrs" />
              <TextField
                label="Phone"
                onChange={handleChange}
                value={formData.phone}
                onBlur={handlePhoneBlur}
                className="mrs"
                name="phone"
              />
              <TextField
                label="Secondary Phone"
                onChange={handleChange}
                value={formData.secondaryPhone}
                onBlur={handlePhoneBlur}
                className="mrs"
                name="secondaryPhone"
              />
              <TextField
                label="Email"
                onChange={handleChange}
                value={formData.email}
                name="email"
              />
            </div>
            <div className="ml40">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.admin}
                    onChange={() => handleCheckboxChange("admin")}
                    color="primary"
                  />
                }
                label="Admin"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.inspector}
                    onChange={() => handleCheckboxChange("inspector")}
                    color="primary"
                  />
                }
                label="Inspector"
              />
            </div>
          </div>
        </div>
      </div>
      <Fab
        onClick={handleEditEmployee}
        style={{
          position: "absolute",
          right: "5rem",
          marginTop: "-30px",
          zIndex: 1100,
        }}
        color="primary"
      >
        {editing ? <Save /> : <Edit />}
      </Fab>
    </div>
  );
}

export default EmployeeEditForm;
