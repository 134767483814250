import generalReducer, {
  GeneralReducerActionType,
  GeneralReducerState,
  initialReducerState,
} from "./generalReducer";
import { InspectionSchedule } from "../types";
import {
  InspectionSchedulesAction,
  InspectionSchedulesActionType,
} from "actions";

const { ERROR, SUCCESS, LOADING } = GeneralReducerActionType;
const initialInspectionSchedulesState = {
  inspectionSchedules: initialReducerState([]),
};
export interface InspectionSchedulesState {
  inspectionSchedules: GeneralReducerState<InspectionSchedule[]>;
}

export default function inspectionSchedulesReducer(
  state: InspectionSchedulesState = initialInspectionSchedulesState,
  action: InspectionSchedulesAction
) {
  switch (action.type) {
    case InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_LOADING:
      return {
        ...state,
        inspectionSchedules: generalReducer(state.inspectionSchedules, LOADING),
      };
    case InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_SUCCESS:
      return {
        ...state,
        inspectionSchedules: generalReducer(
          state.inspectionSchedules,
          SUCCESS,
          action.payload
        ),
      };
    case InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_ERROR:
      return {
        ...state,
        inspectionSchedules: generalReducer(
          state.inspectionSchedules,
          ERROR,
          action.payload
        ),
      };
    case InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_LOADING:
      return {
        ...state,
        inspectionSchedules: generalReducer(state.inspectionSchedules, LOADING),
      };
    case InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_SUCCESS:
      return {
        ...state,
        inspectionSchedules: generalReducer(
          state.inspectionSchedules,
          SUCCESS,
          state.inspectionSchedules.data
        ),
      };
    case InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_ERROR:
      return {
        ...state,
        inspectionSchedules: generalReducer(state.inspectionSchedules, ERROR),
      };
    default:
      return state;
  }
}
