import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ClassNames } from "@emotion/react";
import { useDispatch } from "react-redux";

import { formatPhoneNumber } from "../../../utils";
import { addLocation } from "../../../actions";
import { Address, Contacts, Supervisor } from "../../../types";

interface Props {
  isOpen: boolean;
  toggleAddNewLocation: () => void;
}

interface State {
  assignedId: string;
  address: Address;
  contact: Contacts;
  name: string;
  supervisor: Supervisor;
}

const initialState: State = {
  assignedId: "",
  address: {
    city: "",
    line1: "",
    line2: "",
    phone: "",
    postal: "",
    state: "",
  },
  contact: {
    primary: {
      email: "",
      name: "",
      phone: "",
    },
    secondary: {
      email: "",
      name: "",
      phone: "",
    },
  },
  name: "",
  supervisor: {
    name: "",
  },
};

function AddNewLocation({ isOpen, toggleAddNewLocation }: Props) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState(initialState);

  /** Handles address changes */
  function handleAddressChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: value,
      },
    });
  }

  /** Handles supervisor changes */
  function handleSupervisorChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      supervisor: {
        ...state.supervisor,
        [name]: value,
      },
    });
  }

  /** Handles primary contact changes */
  function handlePrimaryContactChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        primary: {
          ...state.contact.primary!,
          [name]: value,
        },
      },
    });
  }

  /** Handles secondary contact changes */
  function handleSecondaryContactChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        secondary: {
          ...state.contact.secondary!,
          [name]: value,
        },
      },
    });
  }

  /** Formats phone number */
  function handlePhoneBlur(phone: string, key: string, keyProp?: string) {
    if (keyProp) {
      setState({
        ...state,
        [key]: {
          // @ts-ignore
          ...state[key],
          [keyProp]: {
            // @ts-ignore
            ...state[key][keyProp],
            phone: formatPhoneNumber(phone),
          },
        },
      });
    } else {
      setState({
        ...state,
        [key]: {
          // @ts-ignore
          ...state[key],
          phone: formatPhoneNumber(phone),
        },
      });
    }
  }

  /** Handles input changes */
  function handleLocationChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      [name]: value,
    });
  }

  /** Submits location creation and closes modal */
  function handleAddLocationSave() {
    dispatch(addLocation(state));
    toggleAddNewLocation();
    setState(initialState);
  }

  /** handles toggle visibility */
  function handleToggle() {
    setState(initialState);
    toggleAddNewLocation();
  }

  const { address, supervisor, contact } = state;
  if (!isOpen) {
    return null;
  }
  return (
    <ClassNames>
      {({ css }) => {
        const mrm = css({
          marginRight: "1.5rem",
        });
        const name = css({
          marginRight: "1.5rem",
          width: "13rem",
        });
        const stateField = css({
          marginRight: "1.5rem",
          width: "5rem",
        });
        const postal = css({
          marginRight: "1.5rem",
          width: "5rem",
        });
        const phone = css({
          marginRight: "1.5rem",
          width: "9rem",
        });
        return (
          <Dialog open={true}>
            <DialogTitle>Add New Location</DialogTitle>
            <DialogContent>
              <div className="df">
                <div className="mrs">
                  <TextField
                    margin="dense"
                    label="Name"
                    name="name"
                    className={name}
                    onChange={handleLocationChange}
                    value={state.name}
                  />
                </div>
                <div>
                  <TextField
                    margin="dense"
                    label="Id"
                    name="assignedId"
                    onChange={handleLocationChange}
                    value={state.assignedId}
                  />
                </div>
              </div>
              <div className="mvm df">
                <div className="mrs">
                  <TextField
                    label="Address Line 1"
                    className={mrm}
                    name="line1"
                    onChange={handleAddressChange}
                    value={address.line1}
                  />
                </div>
                <div>
                  <TextField
                    label="Address Line 2"
                    className={mrm}
                    name="line2"
                    onChange={handleAddressChange}
                    value={address.line2}
                  />
                </div>
              </div>
              <div className="df mbm">
                <div className="mrs">
                  <TextField
                    label="City"
                    className={mrm}
                    name="city"
                    onChange={handleAddressChange}
                    value={address.city}
                  />
                </div>
                <div className="mrs">
                  <TextField
                    label="State"
                    name="state"
                    className={stateField}
                    onChange={handleAddressChange}
                    value={address.state}
                    fullWidth={true}
                  />
                </div>
                <div>
                  <TextField
                    label="Zip Code"
                    name="postal"
                    className={postal}
                    onChange={handleAddressChange}
                    value={address.postal}
                    fullWidth={true}
                  />
                </div>
              </div>
              <TextField
                label="Phone"
                name="phone"
                className={phone}
                onChange={handleAddressChange}
                value={address.phone}
                onBlur={(e) => handlePhoneBlur(e.target.value, "address")}
                fullWidth={true}
              />
              <div className="mvm">
                <TextField
                  label="Supervisor"
                  className={mrm}
                  name="name"
                  onChange={handleSupervisorChange}
                  value={supervisor.name}
                />
              </div>
              <div className="df mbm">
                <div className="mrs">
                  <TextField
                    label="Contact Name (Primary)"
                    value={contact.primary?.name}
                    className={name}
                    name="name"
                    onChange={handlePrimaryContactChange}
                  />
                </div>
                <div className="mrs">
                  <TextField
                    label="Contact Phone"
                    value={contact.primary?.phone}
                    name="phone"
                    className={mrm}
                    onChange={handlePrimaryContactChange}
                    onBlur={(e) =>
                      handlePhoneBlur(e.target.value, "contact", "primary")
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Contact Email"
                    value={contact.primary?.email}
                    name="email"
                    onChange={handlePrimaryContactChange}
                  />
                </div>
              </div>
              <div className="df">
                <div className="mrs">
                  <TextField
                    label="Contact Name (Secondary)"
                    value={contact.secondary?.name}
                    className={name}
                    name="name"
                    onChange={handleSecondaryContactChange}
                  />
                </div>
                <div className="mrs">
                  <TextField
                    label="Contact Phone"
                    value={contact.secondary?.phone}
                    name="phone"
                    className={mrm}
                    onChange={handleSecondaryContactChange}
                    onBlur={(e) =>
                      handlePhoneBlur(e.target.value, "contact", "secondary")
                    }
                  />
                </div>
                <div>
                  <TextField
                    label="Contact Email"
                    value={contact.secondary?.email}
                    name="email"
                    onChange={handleSecondaryContactChange}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleToggle} color="primary">
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleAddLocationSave}
                disabled={
                  !state.assignedId ||
                  !state.name ||
                  !address.line1 ||
                  !address.city ||
                  !address.state ||
                  !address.postal
                }
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default AddNewLocation;
