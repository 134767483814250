import * as React from "react";
import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  InputLabel,
  ListItemText,
  DialogContent,
  CircularProgress,
  Fab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { updateNotifications } from "../../../actions";
import { getIcon } from "../../../utils";
import {
  Notification,
  PendingNotification,
  GlobalState,
  MessageType,
} from "../../../types";
import { messageTypes } from "../../../constants";
import { GeneralReducerActionType } from "../../../reducers";

interface State {
  notifications: (Notification | PendingNotification)[];
}

const initialState: State = {
  notifications: [],
};

function SendNotificationForm() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState(initialState);
  const { id } = useParams();
  const navigate = useNavigate();
  const notifications = useSelector<
    GlobalState,
    (Notification | PendingNotification)[] | null
  >((state) => state.notifications.notifications.data);
  const isNotificationsLoading = useSelector<GlobalState, boolean>(
    (state) =>
      state.notifications.notifications.status ===
      GeneralReducerActionType.LOADING
  );

  React.useEffect(() => {
    if (notifications) {
      setState({
        notifications: notifications.filter((notification) =>
          notification.users.includes(id!)
        ),
      });
    }
  }, [notifications, id]);

  /** Handle generic change */
  function handleChange(data: string, index: number, type: string) {
    const { notifications } = state;
    const notification = (
      notifications!
    )[index];
    setState({
      notifications: Object.assign([], notifications, {
        [index]: { ...notification, [type]: data },
      }),
    });
  }

  /** Adds a notification */
  function handleAddItem() {
    const { notifications } = state;
    const pendingNotification = {
      text: "",
      title: "",
      type: MessageType.INFO,
      newlyAdded: true,
      new: true,
      users: [id],
      date: new Date(),
      deleted: false,
    };
    setState({
      // @ts-ignore
      notifications: [pendingNotification].concat(notifications),
    });
  }

  /** Visually deletes notification */
  function handleDeletion(index: number) {
    const { notifications } = state;
    const notification = (
      notifications! as (Notification | PendingNotification)[]
    )[index];
    setState({
      notifications: Object.assign([], notifications, {
        [index]: { ...notification, deleted: true },
      }),
    });
  }

  /** Renders a notification */
  function renderNotification(
    notification: Notification | PendingNotification,
    index: number
  ) {
    const disabled =
      (notification as PendingNotification).deleted ||
      !(notification as PendingNotification).newlyAdded;
    return (
      <Card className="mvs" key={index}>
        <CardContent>
          {!disabled && (
            <div className="card-actions">
              <IconButton onClick={() => handleDeletion(index)}>
                <Delete color="action" />
              </IconButton>
            </div>
          )}
          <div className="df aic mbs">
            <div>
              <InputLabel>Type</InputLabel>
              <Select
                value={notification.type}
                // @ts-ignore
                onChange={(e) => handleChange(e.target.value, index, "type")}
                className="mrm"
                disabled={!(notification as PendingNotification).newlyAdded}
                renderValue={(selected) =>
                  messageTypes.find((type) => type.key === selected)!.label
                }
              >
                {messageTypes.map((type) => (
                  <MenuItem value={type.key} key={type.key}>
                    {getIcon(type.key)}
                    <ListItemText primary={type.label} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <InputLabel>Title</InputLabel>
              <TextField
                onChange={(e) => handleChange(e.target.value, index, "title")}
                value={notification.title}
                disabled={
                  !(notification as PendingNotification).newlyAdded || disabled
                }
              />
            </div>
          </div>
          <div>
            <InputLabel>Text</InputLabel>
            <TextField
              onChange={(e) => handleChange(e.target.value, index, "text")}
              value={notification.text}
              fullWidth={true}
              disabled={
                !(notification as PendingNotification).newlyAdded || disabled
              }
            />
          </div>
        </CardContent>
      </Card>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/employees/${id}`);
  }

  /** Handles save */
  function handleSave() {
    dispatch(updateNotifications(state.notifications as PendingNotification[]));
  }

  return (
    <ClassNames>
      {({ css }) => {
        const appBar = css({
          position: "relative",
        });
        const flex = css({
          flex: 1,
        });
        const fab = css({
          position: "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        return (
          <Dialog fullScreen={true} open={true} onClose={handleClose}>
            <AppBar className={appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={flex}>
                  Send Notifications
                </Typography>
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              {isNotificationsLoading && <CircularProgress />}
              {!isNotificationsLoading &&
                state.notifications.map(renderNotification)}
            </DialogContent>
            <Fab
              onClick={handleAddItem}
              className={fab}
              color="primary"
              variant="extended"
            >
              <Add />
              Add Notification
            </Fab>
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default SendNotificationForm;
