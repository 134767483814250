import * as React from "react";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import History from "@mui/icons-material/History";
import Typography from "@mui/material/Typography";
import { CircularProgress, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getStatus,
  getLocationCleaningHistory,
  getEmployee,
  getEmployeeIdForShift,
} from "../../../utils";
import {
  GlobalState,
  User,
  Shift,
  Location,
  CleaningHistory,
} from "../../../types";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";
import { GeneralReducerActionType } from "reducers";

interface Props {
  locationId: string;
}

function CleaningHistoryCard({ locationId }: Props) {
  const navigate = useNavigate();
  const shifts = useSelector<GlobalState, Shift[]>(
    (state) => state.shifts.shifts.data
  );
  const employees = useEmployees();
  const locations = useLocations();
  const cleaningHistory = React.useMemo(
    () => getLocationCleaningHistory(shifts, locations.data, locationId),
    [shifts, locations.data, locationId]
  );

  /** Renders the shift for the cleaning history date */
  function renderCleaningHistoryShift(shift: Shift, i: number) {
    const employee = getEmployee(employees.data, getEmployeeIdForShift(shift));
    return (
      <Card className="mrm mbs w25p" variant="outlined">
        <CardContent>
          <Typography variant="body1">
            {employee ? `${employee.firstName} ${employee.lastName}` : ""}
          </Typography>
          <Typography variant="caption">
            {`${
              shift.checkIn ? moment(shift.checkIn).format("h:mm a") : "--:--"
            } ${
              shift.status === "complete"
                ? "- " + moment(shift.checkOut).format("h:mm a")
                : ""
            }`}
          </Typography>
          <Typography>{getStatus(shift.status)}</Typography>
        </CardContent>
      </Card>
    );
  }

  /** Renders cleaning history */
  function renderCleaningHistory(cleaningHistory: CleaningHistory, i: number) {
    return (
      <div className="separate pbs mbs" key={i}>
        <Typography gutterBottom={true} variant="h6">
          {`${moment(cleaningHistory.date).format("dddd")}, ${moment(
            cleaningHistory.date
          ).format("MMM")} ${moment(cleaningHistory.date).format("DD")}`}
        </Typography>
        <div className="df fww w100p">
          {React.Children.toArray(
            cleaningHistory.shifts.map(renderCleaningHistoryShift)
          )}
        </div>
      </div>
    );
  }

  if (locations.status === GeneralReducerActionType.LOADING || !shifts) {
    return <CircularProgress />;
  }
  return (
    <Card className="cleaning-history-card">
      <CardHeader
        title="Cleaning History"
        avatar={
          <Avatar>
            <History />
          </Avatar>
        }
      />
      <CardContent>
        {cleaningHistory && cleaningHistory.length
          ? cleaningHistory.slice(0, 3).map(renderCleaningHistory)
          : "No cleaning history"}
      </CardContent>
      <CardActions>
        <div className="mla">
          {cleaningHistory && cleaningHistory.length > 3 && (
            <Button
              color="primary"
              onClick={() =>
                navigate(`/locations/${locationId}/cleaningHistory`)
              }
              variant="contained"
            >
              See All
            </Button>
          )}
        </div>
      </CardActions>
    </Card>
  );
}

export default CleaningHistoryCard;
