import * as React from "react";
import AppBar from "@mui/material/AppBar";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PhoneIPhone from "@mui/icons-material/PhoneIphone";
import Toolbar from "@mui/material/Toolbar";
import { ClassNames } from "@emotion/react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  CleaningHistoryCard,
  LoginCard,
  PinCard,
  ReassignShiftsCard,
  SendNotificationCard,
  ScheduleCard,
} from "./cards";
import { deleteUser } from "../../actions";
import { EmployeeEditForm } from "./forms";
import { getEmployee } from "../../utils";
import { useEmployees } from "hooks/useEmployees";

interface State {
  isDeleting: boolean;
}

const initialState: State = {
  isDeleting: false,
};

function EmployeeDetails() {
  const employees = useEmployees();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = React.useState(initialState);

  /** Closes deletion modal */
  function handleDeleteEmployeeClose() {
    setState({ ...state, isDeleting: false });
  }

  /** Deletes employee */
  function handleDeleteEmployeeConfirmation() {
    dispatch(deleteUser(id!));
    navigate("/employees");
  }

  /** Shows deletion modal */
  function showDeleteEmployee() {
    setState({ isDeleting: true });
  }

  const { isDeleting } = state;
  if (!employees.data) {
    return null;
  }
  const employee = getEmployee(employees.data, id!);
  return (
    <ClassNames>
      {({ css }) => {
        const toolbar = css({
          display: "flex",
          justifyContent: "space-between",
        });
        const whiteIcon = css({
          color: "white",
        });
        return (
          <div>
            <Dialog open={isDeleting}>
              <DialogTitle>Delete Employee</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this employee?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteEmployeeClose}>Cancel</Button>
                <Button
                  color="secondary"
                  onClick={handleDeleteEmployeeConfirmation}
                  variant="contained"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <AppBar position="static" color="primary">
              <Toolbar className={toolbar}>
                <IconButton
                  color="inherit"
                  onClick={() => navigate("/employees")}
                >
                  <ArrowBack />
                </IconButton>
                <IconButton color="inherit" onClick={showDeleteEmployee}>
                  <Delete />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="bgprimary pbm phl location-header">
              <Typography variant="h4" color="inherit">
                {employee
                  ? `${employee.firstName} ${employee.lastName}`
                  : "Unknown name"}
              </Typography>
              <div className="df pts location-summary cwhite">
                <div className="df">
                  <PhoneIPhone className={whiteIcon} />
                  <div className="pls">
                    <Typography className="ctrans" variant="caption">
                      Contact
                    </Typography>
                    <p>{employee ? employee.phone : "Unknown phone"}</p>
                    <p>{employee ? employee.secondaryPhone : ""}</p>
                    <p>{employee ? employee.email : "Unknown email"}</p>
                  </div>
                </div>
              </div>
            </div>
            {employee && <EmployeeEditForm employee={employee} />}
            <div className="df pm">
              <main className="mrm mbm w100p">
                <ScheduleCard />
                <CleaningHistoryCard />
              </main>
              <aside className="w300px">
                <LoginCard employee={employee} />
                <PinCard employee={employee} />
                <ReassignShiftsCard />
                <SendNotificationCard />
              </aside>
            </div>
          </div>
        );
      }}
    </ClassNames>
  );
}

export default EmployeeDetails;
