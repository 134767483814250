import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getListCount, getLocationInstructions } from "../../../utils";
import { GlobalState, Instruction, Location } from "../../../types";

interface Props {
  locationId: string;
}

function InstructionsCard({ locationId }: Props) {
  const navigate = useNavigate();
  const instructions = useSelector<GlobalState, Instruction[]>(
    (state) => state.instructions.payload
  );
  const instruction = getLocationInstructions(instructions, locationId);
  const listCount = getListCount(instruction);

  if (!instructions) {
    return null;
  }
  return (
    <Card className="card-spacing">
      <CardHeader
        title="Instructions"
        avatar={
          <Avatar>
            <PlaylistAddCheck />
          </Avatar>
        }
      />
      <CardContent>
        {instruction ? (
          <div className="ma w100p tac df">
            <div className={listCount ? "prs br" : ""}>
              <Typography variant="caption">Items</Typography>
              <Typography variant="body2">{listCount}</Typography>
            </div>
            {listCount ? (
              <div className="pls tal">
                <Typography variant="caption">
                  {instruction.list[0].title}
                </Typography>
                <Typography variant="body2">
                  {instruction.list[0].text}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          "No instructions set"
        )}
      </CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/locations/${locationId}/instructions`)}
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default InstructionsCard;
