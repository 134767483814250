import React, { useState } from "react";
import moment from "moment";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Dialog, DialogContent, Typography } from "@mui/material";

import { Inspection } from "types";
import { getCSVData } from "utils";
import { inspectionStatus, inspectionRating } from "../constants";
import Close from "@mui/icons-material/Close";

export interface Props {
  inspection: Inspection;
  locationName: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onItemDeletion: (index: number) => void;
}

function InspectionForm({
  inspection,
  locationName,
  onItemDeletion,
  onChange,
}: Props) {
  const [selectedImage, setSelectedImage] = useState<string>();

  /** Downloads the CSV data */
  function downloadCSV() {
    let csvData = [
      {
        "Inspection ID": inspection.id,
        Location: locationName,
        Date: moment(inspection.date).format("MM/DD/YYYY"),
        "Overall Status": inspection.status,
        Rating: inspection.rating,
        Title: "",
        Text: "",
      },
    ];
    const inspectionListData = inspection.list.map((item) => {
      return {
        "Inspection ID": "",
        Location: "",
        Date: "",
        "Overall Status": "",
        Rating: "",
        Title: item.title,
        Text: item.text,
      };
    });

    let csv = getCSVData({
      // @ts-ignore
      data: csvData.concat(inspectionListData),
    });
    if (csv == null) {
      return;
    }

    const filename = `bishop-inspection-${new Date()}.csv`;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = "data:text/csv;charset=utf-8," + csv;
    }
    const encodedData = encodeURI(csv);

    const link = document.createElement("a");
    link.setAttribute("href", encodedData);
    link.setAttribute("download", filename);
    link.click();
  }

  return (
    <>
      <Dialog open={!!selectedImage}>
        <IconButton
          aria-label="close"
          onClick={() => setSelectedImage("")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <img
            src={selectedImage}
            alt="selected inspection"
            className="w100p mtl"
          />
        </DialogContent>
      </Dialog>
      <div>
        <div className="print__view__icon hide-for-print">
          <IconButton color="primary" onClick={downloadCSV}>
            <CloudDownload />
          </IconButton>
        </div>
        <InputLabel htmlFor="status" className="db">
          Date
        </InputLabel>
        <Typography variant="body1" gutterBottom={true}>
          {inspection && moment(inspection.date).format("MM/DD/YYYY")}
        </Typography>
        <InputLabel htmlFor="status" className="db">
          Overall Status
        </InputLabel>
        <Select
          value={inspection && inspection.status}
          // @ts-ignore
          onChange={onChange}
          name="status"
          input={<Input id="status" />}
          renderValue={(status) => inspectionStatus[status as string]}
          className="db w300px mbm"
        >
          {Object.keys(inspectionStatus).map((status) => (
            <MenuItem value={status} key={status}>
              <ListItemText primary={inspectionStatus[status]} />
            </MenuItem>
          ))}
        </Select>
        <InputLabel htmlFor="rating" className="db">
          Rating
        </InputLabel>
        <Select
          value={inspection && inspection.rating}
          // @ts-ignore
          onChange={onChange}
          name="rating"
          input={<Input id="rating" />}
          renderValue={(rating) => inspectionRating[rating as string]}
          className="db w300px mbm"
        >
          {Object.keys(inspectionRating).map((rating) => (
            <MenuItem value={rating} key={rating}>
              <ListItemText primary={inspectionRating[rating]} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <InputLabel className="db mbs">List</InputLabel>
      {inspection.list.length ? (
        React.Children.toArray(
          inspection.list.map((item, j) => (
            <div className="__card mbs">
              <div className="hidden-actions df jcsb aic">
                <div>
                  <Typography variant="body1">{item.title}</Typography>
                  <Typography variant="body2">{item.text}</Typography>
                  {item.images && item.images.length ? (
                    <div className="df aic mvs">
                      {React.Children.toArray(
                        item.images.map((image) => (
                          <img
                            src={image}
                            className="rc mrs w100p cp"
                            onClick={() => setSelectedImage(image)}
                            alt="inspection list"
                          />
                        ))
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="df soh">
                  <Delete onClick={() => onItemDeletion(j)} className="cp" />
                </div>
              </div>
            </div>
          ))
        )
      ) : (
        <Typography variant="body1" gutterBottom={true}>
          No items added
        </Typography>
      )}
    </>
  );
}

export default InspectionForm;
