import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

interface Props {
  isOpen: boolean;
  onAddItem: (name: string) => void;
  onToggleAddItem: () => void;
}

interface State {
  name: string;
}

const initialState = {
  name: "",
};

export default class AddSupplyItem extends React.Component<Props, State> {
  state = initialState;

  /** Handles change of name */
  handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ name: value });
  };

  handleAddItem = () => {
    this.setState({ name: "" });
    this.props.onAddItem(this.state.name);
  };

  /** Handles the toggle by resetting value of name */
  handleToggle = () => {
    this.setState({ name: "" });
    this.props.onToggleAddItem();
  };

  render() {
    if (!this.props.isOpen) {
      return null;
    }
    return (
      <Dialog open={true}>
        <DialogTitle>Add Supply Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleToggle}
            color="primary"
            className="hide-for-print"
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleAddItem}
            color="primary"
            className="hide-for-print"
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
