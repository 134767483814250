import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Supply, GlobalState } from "../../../types";
import { getLocationSupplies } from "../../../utils";

interface Props {
  locationId: string;
}

function SuppliesCard({ locationId }: Props) {
  const navigate = useNavigate();
  const supplies = useSelector<GlobalState, Supply[]>(
    (state) => state.supplies.payload
  );
  const supply = getLocationSupplies(supplies, locationId);

  function renderSupplies(supply: Supply) {
    return (
      <div className="df jcc tac">
        <div className="prs br">
          <Typography variant="caption">Next Delivery</Typography>
          <Typography variant="body2">
            {supply.nextDelivery || "Unspecified"}
          </Typography>
        </div>
        <div className="mls">
          <Typography variant="caption">Out of Stock</Typography>
          <Typography variant="body2">{supply.outOfStockCount}</Typography>
        </div>
      </div>
    );
  }

  if (!supplies) {
    return null;
  }
  return (
    <Card className="card-spacing">
      <CardHeader
        title="Supplies"
        avatar={
          <Avatar>
            <ShoppingCart />
          </Avatar>
        }
      />
      <CardContent>
        {supply ? renderSupplies(supply) : "No supplies set"}
      </CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/locations/${locationId}/supplies`)}
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default SuppliesCard;
