import generalReducer, {
  GeneralReducerActionType,
  initialReducerState,
} from "./generalReducer";
import { FilesAction, FilesActionType, FilesState } from "../types";

const { LOADING, SUCCESS, ERROR, NONE } = GeneralReducerActionType;

const initialFilesState = {
  uploadFile: initialReducerState(),
};

export default function filesReducer(
  state: FilesState = initialFilesState,
  action: FilesAction
) {
  switch (action.type) {
    case FilesActionType.UPLOAD_FILE_LOADING:
      return {
        ...state,
        uploadFile: generalReducer(state.uploadFile, LOADING),
      };
    case FilesActionType.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFile: generalReducer(state.uploadFile, SUCCESS, action.payload),
      };
    case FilesActionType.UPLOAD_FILE_ERROR:
      return {
        ...state,
        uploadFile: generalReducer(state.uploadFile, ERROR, action.payload),
      };
    case FilesActionType.UPLOAD_FILE_RESET:
      return {
        ...state,
        uploadFile: generalReducer(state.uploadFile, NONE),
      };
    default:
      return state;
  }
}
