import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "firebaseconfig";
import * as React from "react";
import { User } from "types";
import { extractSnapshot } from "utils";
import { GeneralReducerActionType } from "reducers";

export interface UseEmployeesState {
  data: User[];
  error?: string;
  status: GeneralReducerActionType;
}

interface Props {
  children: React.ReactNode;
}

const defaultState = {
  data: [],
  error: undefined,
  status: GeneralReducerActionType.NONE,
};

const EmployeesContext = React.createContext<UseEmployeesState>(defaultState);

function EmployeesProvider({ children }: Props) {
  const [employeesState, setEmployeesState] =
    React.useState<UseEmployeesState>(defaultState);

  React.useEffect(() => {
    setEmployeesState({
      ...employeesState,
      status: GeneralReducerActionType.LOADING,
      error: undefined,
    });

    onSnapshot(
      query(collection(db, "employees"), orderBy("firstName", "asc")),
      (users) => {
        setEmployeesState({
          status: GeneralReducerActionType.SUCCESS,
          data: extractSnapshot<User[]>(users),
          error: undefined,
        });
      }
    );
  }, []);

  return (
    <EmployeesContext.Provider value={employeesState}>
      {children}
    </EmployeesContext.Provider>
  );
}

function useEmployees() {
  const context = React.useContext(EmployeesContext);
  if (context === undefined) {
    throw new Error("useEmployees must be used within a EmployeesProvider");
  }
  return context;
}

export { EmployeesProvider, useEmployees };
