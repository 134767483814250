import { Schedule } from "../types";
import {
  generalReducer,
  GeneralReducerActionType,
  GeneralReducerState,
  initialReducerState,
} from ".";
import { SchedulesAction, SchedulesActionType } from "../actions/schedules";

export interface SchedulesState {
  schedules: GeneralReducerState<Schedule[]>;
}

const { LOADING, SUCCESS, ERROR } = GeneralReducerActionType;
const initialSchedulesState = {
  schedules: initialReducerState([]),
};

export default function schedulesReducer(
  state: SchedulesState = initialSchedulesState,
  action: SchedulesAction
) {
  switch (action.type) {
    case SchedulesActionType.GET_SCHEDULES_LOADING:
      return {
        ...state,
        schedules: generalReducer(state.schedules, LOADING),
      };
    case SchedulesActionType.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: generalReducer(state.schedules, SUCCESS, action.payload),
      };
    case SchedulesActionType.GET_SCHEDULES_ERROR:
      return {
        ...state,
        schedules: generalReducer(state.schedules, ERROR, action.payload),
      };
    default:
      return state;
  }
}
