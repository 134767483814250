import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { MessageType } from "../types";
import { ClassNames } from "@emotion/react";

interface Props {
  messageType: MessageType[];
  onMessageTypeChange: Function;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  search: string;
}
type LocationFiltersProps = Props;

function LocationFilters({
  messageType = [],
  onMessageTypeChange,
  onSearchChange,
  search,
}: LocationFiltersProps) {
  return (
    <ClassNames>
      {({ css }) => {
        const input = css({
          width: "100%",
          marginBottom: 16,
        });
        return (
          <>
            <Typography variant="h6" gutterBottom={true}>
              Search
            </Typography>
            <TextField
              placeholder="Search by location or cleaner"
              onChange={onSearchChange}
              value={search}
              className={input}
            />
            <Typography variant="h6" gutterBottom={true}>
              Filters
            </Typography>
            <InputLabel htmlFor="message-type">Message Type</InputLabel>
            <Select
              className={input}
              value={messageType}
              // @ts-ignore
              onChange={(e) => onMessageTypeChange(e.target.value)}
              input={<Input id="message-type" />}
              renderValue={(selected) =>
                (selected as MessageType[])!.join(", ")
              }
              multiple={true}
            >
              <MenuItem value="time">
                <Checkbox
                  checked={messageType.includes(MessageType.TIME)}
                  color="primary"
                />
                <ListItemText primary="Time" />
              </MenuItem>
              <MenuItem value="info">
                <Checkbox
                  checked={messageType.includes(MessageType.INFO)}
                  color="primary"
                />
                <ListItemText primary="Info" />
              </MenuItem>
              <MenuItem value="security">
                <Checkbox
                  checked={messageType.includes(MessageType.SECURITY)}
                  color="primary"
                />
                <ListItemText primary="Security" />
              </MenuItem>
              <MenuItem value="urgent">
                <Checkbox
                  checked={messageType.includes(MessageType.URGENT)}
                  color="primary"
                />
                <ListItemText primary="Urgent" />
              </MenuItem>
            </Select>
          </>
        );
      }}
    </ClassNames>
  );
}

export default LocationFilters;
