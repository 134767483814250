import { db } from "../firebaseconfig";
import { extractSnapshot } from "../utils";
import {
  SecurityActionType,
  SecurityAction,
  ThunkResult,
  Security,
} from "../types";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";

export const fetchSecurity = (): ThunkResult<Security[]> => {
  return async (dispatch) => {
    dispatch(fetchSecurityLoading());
    onSnapshot(collection(db, "security"), (security) => {
      dispatch(fetchSecuritySuccess(extractSnapshot(security)));
    });
  };
};

export const updateSecurity = (
  security: Omit<Security, "id">,
  id: string
): ThunkResult<SecurityAction> => {
  return async (dispatch) => {
    dispatch(updateSecurityLoading());
    const timestamped = {
      ...security,
      timestamp: serverTimestamp(),
    };
    return id
      ? setDoc(doc(db, "security", id), security).then(() =>
          dispatch(updateSecuritySuccess())
        )
      : addDoc(collection(db, "security"), timestamped).then(() =>
          dispatch(updateSecuritySuccess())
        );
  };
};

export const fetchSecuritySuccess = (payload: Security[]): SecurityAction => {
  return {
    type: SecurityActionType.GET_SECURITY_SUCCESS,
    payload,
  };
};

export const fetchSecurityError = (): SecurityAction => ({
  type: SecurityActionType.GET_SECURITY_ERROR,
});

export const fetchSecurityLoading = (): SecurityAction => ({
  type: SecurityActionType.GET_SECURITY_LOADING,
});

export const updateSecuritySuccess = (): SecurityAction => ({
  type: SecurityActionType.UPDATE_SECURITY_SUCCESS,
});

export const updateSecurityError = (): SecurityAction => ({
  type: SecurityActionType.UPDATE_SECURITY_ERROR,
});

export const updateSecurityLoading = (): SecurityAction => ({
  type: SecurityActionType.UPDATE_SECURITY_LOADING,
});
