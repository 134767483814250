import { onSnapshot, serverTimestamp } from "firebase/firestore";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseconfig";
import {
  ThunkResult,
  InstructionsAction,
  Instruction,
  InstructionsActionType,
} from "../types";
import { extractSnapshot } from "../utils";

export const fetchInstructions = (): ThunkResult<Instruction[]> => {
  return async (dispatch) => {
    dispatch(fetchInstructionsLoading());
    onSnapshot(collection(db, "instructions"), (messages) => {
      dispatch(fetchInstructionsSuccess(extractSnapshot(messages)));
    });
  };
};

export const updateInstructions = (
  instruction: Pick<Instruction, Exclude<keyof Instruction, "id">>,
  id: string
): ThunkResult<InstructionsAction> => {
  return async (dispatch) => {
    dispatch(updateInstructionsLoading());
    const timestamped = {
      ...instruction,
      timestamp: serverTimestamp(),
    };
    return id
      ? setDoc(doc(db, "instructions", id), instruction).then(() =>
          dispatch(updateInstructionsSuccess())
        )
      : addDoc(collection(db, "instructions"), timestamped).then(() =>
          dispatch(updateInstructionsSuccess())
        );
  };
};

export const updateInstructionsSuccess = (): InstructionsAction => ({
  type: InstructionsActionType.UPDATE_INSTRUCTIONS_SUCCESS,
});

export const updateInstructionsError = (): InstructionsAction => ({
  type: InstructionsActionType.UPDATE_INSTRUCTIONS_ERROR,
});

export const updateInstructionsLoading = (): InstructionsAction => ({
  type: InstructionsActionType.UPDATE_INSTRUCTIONS_LOADING,
});

export const fetchInstructionsSuccess = (
  payload: Instruction[]
): InstructionsAction => ({
  type: InstructionsActionType.GET_INSTRUCTIONS_SUCCESS,
  payload,
});

export const fetchInstructionsError = (): InstructionsAction => ({
  type: InstructionsActionType.GET_INSTRUCTIONS_ERROR,
});

export const fetchInstructionsLoading = (): InstructionsAction => ({
  type: InstructionsActionType.GET_INSTRUCTIONS_LOADING,
});
