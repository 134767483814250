import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "firebaseconfig";
import * as React from "react";
import { Location } from "types";
import { extractSnapshot } from "utils";
import { GeneralReducerActionType } from "reducers";

export interface UseLocationsState {
  data: Location[];
  error?: string;
  status: GeneralReducerActionType;
}

interface Props {
  children: React.ReactNode;
}

const defaultState = {
  data: [],
  error: undefined,
  status: GeneralReducerActionType.NONE,
};

const LocationsContext = React.createContext<UseLocationsState>(defaultState);

function LocationsProvider({ children }: Props) {
  const [locationsState, setLocationsState] =
    React.useState<UseLocationsState>(defaultState);

  React.useEffect(() => {
    setLocationsState({
      ...locationsState,
      status: GeneralReducerActionType.LOADING,
      error: undefined,
    });

    onSnapshot(
      query(collection(db, "locations"), orderBy("name", "asc")),
      (locations) => {
        setLocationsState({
          status: GeneralReducerActionType.SUCCESS,
          data: extractSnapshot<Location[]>(locations),
          error: undefined,
        });
      }
    );
  }, []);

  return (
    <LocationsContext.Provider value={locationsState}>
      {children}
    </LocationsContext.Provider>
  );
}

function useLocations() {
  const context = React.useContext(LocationsContext);
  if (context === undefined) {
    throw new Error("useLocations must be used within a LocationsProvider");
  }
  return context;
}

export { LocationsProvider, useLocations };
