import { FieldPath, WhereFilterOp } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebaseconfig";

const frequentInspectionsService = {
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(
        collection(db, "frequentInspections"),
        where(fieldPath, operation, value)
      )
    );
  },
};

export default frequentInspectionsService;
