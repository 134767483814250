import { Action } from "redux";
import { onSnapshot, collection } from "firebase/firestore";

import { db } from "../firebaseconfig";
import { extractSnapshot } from "../utils";
import { Schedule, ThunkResult } from "../types";

export interface SchedulesAction extends Action {
  type: SchedulesActionType;
  payload?: Schedule[];
}

export enum SchedulesActionType {
  GET_SCHEDULES_LOADING = "GET_SCHEDULES_LOADING",
  GET_SCHEDULES_ERROR = "GET_SCHEDULES_ERROR",
  GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS",
}

/** Gets all frequent shifts */
export const fetchSchedules = (): ThunkResult<SchedulesAction> => {
  return async (dispatch, store) => {
    dispatch(fetchSchedulesLoading());
    onSnapshot(collection(db, "frequentShifts"), (schedules) => {
      dispatch(fetchSchedulesSuccess(extractSnapshot(schedules)));
    });
  };
};

export const fetchSchedulesSuccess = (payload: Schedule[]) => {
  return {
    type: SchedulesActionType.GET_SCHEDULES_SUCCESS,
    payload,
  };
};

export const fetchSchedulesLoading = (): SchedulesAction => ({
  type: SchedulesActionType.GET_SCHEDULES_LOADING,
});
