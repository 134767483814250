import { arrayRemove } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";

import { db } from "../firebaseconfig";

const userService = {
  // Removes a list of messages as new based on a list of ids
  removeNewMessages(userId: string, messageIds: string[]) {
    return updateDoc(doc(db, "employees", userId), {
      newMessages: arrayRemove(...messageIds),
    });
  },
  // Removes all of a certain user's new messages
  removeAllNewMessages(userId: string) {
    return updateDoc(doc(db, "employees", userId), {
      newMessages: [],
    });
  },
};

export default userService;
