import * as React from "react";
import { connect } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import TextField from "@mui/material/TextField";
import {
  CardActions,
  Typography,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { User, Credentials } from "../../../types";
import { updateUserLogin } from "../../../actions";

interface MapDispatchToProps {
  updateUserLogin: (login: Credentials, userId: string) => void;
}

interface LoginCardProps {
  employee?: User;
}

interface State {
  password: string;
  userName: string;
  originalPassword: string;
  originalUserName: string;
  isPasswordVisible: boolean;
  isResetting: boolean;
}

type Props = MapDispatchToProps & LoginCardProps;

class LoginCard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { employee } = this.props;
    this.state = {
      password: employee ? employee.password : "",
      userName: employee ? employee.userName : "",
      originalPassword: employee ? employee.password : "",
      originalUserName: employee ? employee.userName : "",
      isPasswordVisible: false,
      isResetting: false,
    };
  }

  componentDidUpdate() {
    if (this.props.employee) {
      const { password, userName } = this.props.employee;
      if (
        password !== this.state.originalPassword ||
        userName !== this.state.originalUserName
      ) {
        this.setState({
          password,
          userName,
          originalPassword: password,
          originalUserName: userName,
        });
      }
    }
  }

  handleResetSave = () => {
    if (this.props.employee) {
      const login = {
        userName: this.state.userName,
        password: this.state.password,
      };
      this.setState({ isResetting: false });
      this.props.updateUserLogin(login, this.props.employee.id as string);
    }
  };

  handleResetCancel = () => {
    this.setState({
      password: this.state.originalPassword,
      userName: this.state.originalUserName,
      isResetting: false,
    });
  };

  handleReset = () => {
    this.setState({ isResetting: true });
  };

  togglePasswordShow = () => {
    this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
  };

  /** Handle input changes */
  handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [name]: value });
  };

  render() {
    const {
      isPasswordVisible,
      isResetting,
      password,
      userName,
      originalPassword,
      originalUserName,
    } = this.state;
    return (
      <>
        <Card style={{ margin: "0 0 1.5rem 0" }}>
          <CardHeader
            title="Login"
            subheader="Manage login credentials"
            avatar={
              <Avatar>
                <SupervisorAccount />
              </Avatar>
            }
          />
          <CardContent>
            <Typography variant="caption">User Name</Typography>
            <Typography variant="body1">{originalUserName}</Typography>
            <Typography variant="caption">Password</Typography>
            <Typography variant="body1">
              {isPasswordVisible ? originalPassword : "******"}
            </Typography>
          </CardContent>
          <CardActions>
            <div className="mla">
              <Button color="primary" onClick={this.togglePasswordShow}>
                {isPasswordVisible ? "Hide" : "Show"}
              </Button>
              <Button
                color="primary"
                onClick={this.handleReset}
                variant="contained"
              >
                Reset
              </Button>
            </div>
          </CardActions>
        </Card>
        <Dialog
          title="Reset Login"
          open={isResetting}
          onClose={this.handleResetCancel}
        >
          <DialogContent>
            <TextField
              label="User Name"
              className="mrm iwm"
              name="userName"
              onChange={this.handleChange}
              value={userName}
              fullWidth={true}
            />
            <TextField
              label="Password"
              className="mrm iwm"
              name="password"
              onChange={this.handleChange}
              value={password}
              fullWidth={true}
            />
          </DialogContent>
          <DialogActions>
            <div className="mla">
              <Button color="primary" onClick={this.handleResetCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={this.handleResetSave}
                variant="contained"
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(null, { updateUserLogin })(LoginCard);
