import * as React from "react";
import Add from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { CircularProgress, Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { ClassNames } from "@emotion/react";

import AddNotificationForm from "./AddNotificationForm";
import { GlobalState } from "../../types";
import { Notification as NotificationComponent } from "../../components";
import { GeneralReducerActionType } from "../../reducers";
import { useEmployees } from "hooks/useEmployees";

const { LOADING } = GeneralReducerActionType;

function Notifications() {
  const employees = useEmployees();
  const isNotificationsLoading = useSelector(
    (state: GlobalState) => state.notifications.notifications.status === LOADING
  );
  const notifications = useSelector(
    (state: GlobalState) => state.notifications.notifications.data
  );
  const [isAddingNotification, setIsAddingNotification] = React.useState(false);

  /** Renders the notifications content */
  function renderContent() {
    if (
      employees.status === GeneralReducerActionType.LOADING ||
      isNotificationsLoading
    ) {
      return <CircularProgress />;
    }
    if (notifications.length) {
      return (
        <Card>
          <List style={{ padding: 0 }} className="list">
            {React.Children.toArray(
              notifications.map((notification) => (
                // tslint:disable-next-line:jsx-key
                <NotificationComponent
                  notification={notification}
                  employees={employees.data}
                />
              ))
            )}
          </List>
        </Card>
      );
    } else {
      return "No notifications";
    }
  }

  return (
    <div className="notification">
      <div className="pbm">
        <Typography variant="h6" gutterBottom={true}>
          {`${notifications ? notifications.length : "-"} Notifications`}
        </Typography>
        {renderContent()}
      </div>
      {!isAddingNotification && (
        <ClassNames>
          {({ css }) => (
            <Fab
              onClick={() => setIsAddingNotification(true)}
              className={css`
                position: fixed;
                bottom: 3rem;
                right: 1.5rem;
              `}
              color="primary"
              variant="extended"
            >
              <Add
                className={css`
                  color: white;
                  marginright: 1rem;
                `}
              />
              Add Notification
            </Fab>
          )}
        </ClassNames>
      )}
      {isAddingNotification && (
        <AddNotificationForm
          employees={employees.data}
          onClose={() => setIsAddingNotification(false)}
        />
      )}
    </div>
  );
}

export default Notifications;
