import * as React from "react";
import AssignmentReturn from "@mui/icons-material/AssignmentReturn";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { CardActions } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

function ReassignShiftsCard() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Card style={{ margin: "1.5rem 0 1.5rem 0" }}>
      <CardHeader
        title="Reassign Shifts"
        subheader="Temporarily assign shifts to another cleaner"
        avatar={
          <Avatar>
            <AssignmentReturn />
          </Avatar>
        }
      />
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() => navigate(`/employees/${id}/reassignShifts`)}
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default ReassignShiftsCard;
