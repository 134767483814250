import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebaseconfig";
import { NewNotification } from "../types";

const notificationsService = {
  create(notification: NewNotification) {
    return addDoc(collection(db, "notifications"), notification);
  },
};

export default notificationsService;
