import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Person from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Chip } from "@mui/material";

import { User, Location, Shift } from "../../types";
import { getEmployee, getLocation } from "../../utils";

interface Props {
  checked: boolean;
  employees: User[];
  locations: Location[];
  onAssign: () => void;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUnassign: () => void;
  shift: Shift;
}

function FillInShift({
  shift,
  employees,
  locations,
  checked,
  onCheck,
  onUnassign,
  onAssign,
}: Props) {
  const originalEmployee = getEmployee(employees, shift.employee);
  const employee = getEmployee(employees, shift.reassignedTo || "");
  const shiftLocation = getLocation(locations, shift.location);
  return (
    <div className="separate">
      <div className="hidden-actions pvs df jcsb aic">
        <div className="df aic">
          <Checkbox
            className="checkbox--small-padding"
            checked={checked}
            onChange={onCheck}
            color="primary"
          />
          <div>
            <Typography variant="body1">
              {shiftLocation ? shiftLocation.name : "Unknown location"}
            </Typography>
            <Typography variant="caption">
              {originalEmployee
                ? `${originalEmployee.firstName} ${originalEmployee.lastName}`
                : "Unknown cleaner"}{" "}
            </Typography>
            <Typography variant="caption">
              {moment(shift.date).format("ddd | MM/DD/YYYY")}
            </Typography>
          </div>
        </div>
        <div className="df aic">
          {shift.reassignedTo && (
            <Chip
              label={`Reassigned to: ${
                employee
                  ? `${employee.firstName} ${employee.lastName}`
                  : "Unknown cleaner"
              }`}
              className="mls"
            />
          )}
          {!shift.reassignedTo && (
            <Chip label="Unassigned" variant="outlined" className="mls" />
          )}
          <div className="mls df soh">
            <PersonOutline className="cp mrs" onClick={onUnassign} />
            <Person onClick={onAssign} className="cp" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillInShift;
