import {
  SuppliesState,
  SuppliesAction,
  SuppliesActionType,
  Supply,
} from "../types";

export default function suppliesReducer(
  state: SuppliesState = {
    isFetching: false,
    payload: [],
    error: false,
  },
  { type, payload }: SuppliesAction
) {
  switch (type) {
    case SuppliesActionType.GET_SUPPLIES_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case SuppliesActionType.GET_SUPPLIES_SUCCESS:
      return {
        ...state,
        payload: payload as Supply[],
        isFetching: false,
      };
    case SuppliesActionType.GET_SUPPLIES_ERROR:
      return {
        ...state,
        error: true,
        isFetching: false,
      };
    default:
      return state;
  }
}
