import {
  DocumentData,
  QuerySnapshot,
  WriteBatch,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebaseconfig";

export default async function firebaseBatchLimitHandler(
  snapshotFetch: Promise<QuerySnapshot<DocumentData>>,
  action: "delete" = "delete"
) {
  const snapshot = await snapshotFetch;

  if (snapshot.size > 500) {
    // Chop up the snapshot into 500 document-sized groups
    const groupCount = Math.ceil(snapshot.size / 500);
    for (let i = 0; i < groupCount; i++) {
      const groupBatch = writeBatch(db);
      const groupDocuments = snapshot.docs.slice(500 * i, 500 * (i + 1));

      groupDocuments.forEach((snapshotDoc) =>
        groupBatch[action](snapshotDoc.ref)
      );

      await groupBatch.commit();
    }
  } else {
    const batch = writeBatch(db);
    snapshot.forEach((snapshotDoc) => batch[action](snapshotDoc.ref));

    await batch.commit();
  }
}
