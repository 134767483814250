import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ClassNames } from "@emotion/react";

import {
  getStatus,
  getLocationCleaningHistory,
  getEmployee,
  getEmployeeIdForShift,
  getLocation,
} from "../../../utils";
import {
  GlobalState,
  User,
  Shift,
  Location,
  CleaningHistory as CleaningHistoryType,
} from "../../../types";
import { useEmployees } from "hooks/useEmployees";
import { GeneralReducerActionType } from "reducers";
import { useLocations } from "hooks/useLocations";

function CleaningHistory() {
  const navigate = useNavigate();
  const { id } = useParams();
  const employees = useEmployees();
  const shifts = useSelector<GlobalState, Shift[]>(
    (state) => state.shifts.shifts.data
  );
  const locations = useLocations();

  /** Renders the shift for the cleaning history date */
  function renderCleaningHistoryShift(shift: Shift, i: number) {
    const employee = getEmployee(employees.data, getEmployeeIdForShift(shift));
    return (
      <Card className="mrm w25p mbs" variant="outlined">
        <CardContent>
          <Typography variant="body1">
            {employee ? `${employee.firstName} ${employee.lastName}` : ""}
          </Typography>
          <Typography variant="caption">
            {`${
              shift.checkIn ? moment(shift.checkIn).format("h:mm a") : "--:--"
            } ${
              shift.status === "complete"
                ? "- " + moment(shift.checkOut).format("h:mm a")
                : ""
            }`}
          </Typography>
          <Typography>{getStatus(shift.status)}</Typography>
        </CardContent>
      </Card>
    );
  }

  /** Renders cleaning history */
  function renderCleaningHistory(
    cleaningHistory: CleaningHistoryType,
    i: number
  ) {
    return (
      <div className="separate pbs mbs" key={i}>
        <Typography gutterBottom={true} variant="h6">
          {`${moment(cleaningHistory.date).format("dddd")}, ${moment(
            cleaningHistory.date
          ).format("MMM")} ${moment(cleaningHistory.date).format("DD")}`}
        </Typography>
        <div className="df fww w100p">
          {React.Children.toArray(
            cleaningHistory.shifts.map(renderCleaningHistoryShift)
          )}
        </div>
      </div>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${id}`);
  }

  if (employees.status === GeneralReducerActionType.LOADING) {
    return null;
  }
  const location = getLocation(locations.data, id!);
  const cleaningHistory = getLocationCleaningHistory(shifts, locations.data, id!);
  return (
    <ClassNames>
      {({ css }) => (
        <Dialog
          fullScreen={true}
          open={true}
          onClose={handleClose}
          className="cleaning-history"
        >
          <AppBar className={css({ position: "relative" })}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={css({ flex: 1 })}
              >
                Cleaning History | {location && location.name}
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="pm">
            {cleaningHistory && cleaningHistory.length
              ? // TODO: Maybe getShortCleaningHistory?
                cleaningHistory.map(renderCleaningHistory)
              : "No cleaning history."}
          </div>
        </Dialog>
      )}
    </ClassNames>
  );
}

export default CleaningHistory;
