import { FieldPath, WhereFilterOp } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebaseconfig";

const instructionsService = {
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(collection(db, "instructions"), where(fieldPath, operation, value))
    );
  },
};

export default instructionsService;
