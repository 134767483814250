import generalReducer, {
  GeneralReducerState,
  initialReducerState,
  GeneralReducerActionType,
} from "./generalReducer";
import { MessagesAction, MessagesActionType } from "../actions";
import { Message } from "../types";

export interface MessagesState {
  add: GeneralReducerState;
  messages: GeneralReducerState<Message[]>;
}

const initialMessagesState = {
  add: initialReducerState(),
  messages: initialReducerState([]),
};

const { ERROR, SUCCESS, LOADING } = GeneralReducerActionType;

export default function messagesReducer(
  state: MessagesState = initialMessagesState,
  action: MessagesAction
) {
  switch (action.type) {
    case MessagesActionType.GET_MESSAGES_LOADING:
      return {
        ...state,
        messages: generalReducer(state.messages, LOADING),
      };
    case MessagesActionType.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: generalReducer(state.messages, SUCCESS, action.payload),
      };
    case MessagesActionType.GET_MESSAGES_ERROR:
      return {
        ...state,
        messages: generalReducer(state.messages, ERROR, action.payload),
      };
    case MessagesActionType.ADD_MESSAGE_LOADING:
      return {
        ...state,
        add: generalReducer(state.add, LOADING),
      };
    case MessagesActionType.ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        add: generalReducer(state.add, SUCCESS, action.payload),
      };
    case MessagesActionType.ADD_MESSAGE_ERROR:
      return {
        ...state,
        add: generalReducer(state.add, ERROR, action.payload),
      };
    default:
      return state;
  }
}
