import * as React from "react";

import { Attachment } from "../types";

interface Props {
  attachment: Attachment;
}

/** A media item such as an image, video, or pdf */
function Media({ attachment }: Props) {
  if (!attachment.type || attachment.type?.includes("image")) {
    // @ts-ignore TODO: need to updated mobile app to account for video and image attachments
    return <img src={attachment.url || attachment} width="200" alt="" />;
  }
  if (attachment.type.includes("video")) {
    return (
      <a
        href={attachment.url}
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        Video attachment
      </a>
    );
  }
  if (attachment.type.includes("pdf")) {
    return (
      <a
        href={attachment.url}
        target="_blank"
        className="link"
        rel="noreferrer"
      >
        PDF attachment
      </a>
    );
  }
  return null;
}

export default Media;
