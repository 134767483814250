import { collection, onSnapshot } from "firebase/firestore";
import { db } from "firebaseconfig";
import { Schedule } from "types";
import { extractSnapshot } from "utils";
import { GeneralReducerActionType } from "reducers";
import React, { createContext, ReactNode, useEffect, useState } from "react";

export interface UseSchedulesState {
  data: Schedule[];
  error?: string;
  status: GeneralReducerActionType;
}

interface Props {
  children: ReactNode;
}

const defaultState = {
  data: [],
  error: undefined,
  status: GeneralReducerActionType.NONE,
};

const SchedulesContext = createContext<UseSchedulesState>(defaultState);

function SchedulesProvider({ children }: Props) {
  const [schedulesState, setSchedulesState] =
    useState<UseSchedulesState>(defaultState);

  useEffect(() => {
    setSchedulesState({
      ...schedulesState,
      status: GeneralReducerActionType.LOADING,
      error: undefined,
    });

    onSnapshot(collection(db, "frequentShifts"), (schedules) => {
      setSchedulesState({
        status: GeneralReducerActionType.SUCCESS,
        data: extractSnapshot<Schedule[]>(schedules),
        error: undefined,
      });
    });
  }, []);

  return (
    <SchedulesContext.Provider value={schedulesState}>
      {children}
    </SchedulesContext.Provider>
  );
}

function useSchedules() {
  const context = React.useContext(SchedulesContext);
  if (context === undefined) {
    throw new Error("useSchedules must be used within a SchedulesProvider");
  }
  return context;
}

export { SchedulesProvider, useSchedules };
