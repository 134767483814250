import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import authentication from "./authentication";
import events from "./events";
import files from "./files";
import inspectionSchedules from "./inspectionSchedules";
import generalReducer, {
  initialReducerState,
  GeneralReducerActionType,
  GeneralReducerState,
} from "./generalReducer";
import inspections from "./inspections";
import instructions from "./instructions";
import location from "./location";
import messages from "./messages";
import notifications from "./notifications";
import security from "./security";
import schedules from "./schedules";
import shifts from "./shifts";
import supplies from "./supplies";
import user from "./user";

export { generalReducer, initialReducerState, GeneralReducerActionType };
export type { GeneralReducerState };

export default combineReducers({
  authentication,
  events,
  files,
  inspectionSchedules,
  inspections,
  instructions,
  location,
  messages,
  notifications,
  router: routerReducer,
  schedules,
  security,
  shifts,
  supplies,
  user,
});
