import { InspectionsState } from "reducers/inspections";
import { Action, Dispatch } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { GeneralReducerState } from "../reducers/generalReducer";
import {
  InspectionsAction,
  InspectionSchedulesAction,
  MessagesAction,
  UserAction,
} from "../actions";
import { LocationAction } from "../actions/location";
import { LocationState } from "../reducers/location";
import { MessagesState } from "../reducers/messages";
import { SchedulesAction } from "../actions/schedules";
import { SchedulesState } from "../reducers/schedules";
import { InspectionSchedulesState } from "reducers/inspectionSchedules";
import { Location } from "./locations";
import { Shift } from "./shifts";
import { MessageType } from "./messages";
import { User } from "./users";

/** TYPES */

/** General */
export interface Credentials {
  userName?: string;
  password?: string;
  pin?: string;
}

export interface Supplies {
  [key: string]: Supply;
}

export interface Supply {
  location: string;
  id: string;
  list: SupplyItem[];
  outOfStockCount: number;
  lastDelivery?: Date;
  nextDelivery: string;
  frequency: number;
}

export interface SupplyItem {
  inStock: boolean;
  name: string;
  deleted: boolean;
}

export interface Inspections {
  [key: string]: Inspection;
}

export interface InspectionItem {
  title: string;
  text: string;
  images: string[];
}

export enum InspectionStatus {
  ASSIGNED = "assigned",
  WORKING = "working",
  CHECKED = "checked",
  COMPLETE = "complete",
}

export enum InspectionRating {
  EXCELLENT = "excellent",
  GOOD = "good",
  NEEDS_IMPROVEMENT = "needs-improvement",
  NOT_ACCEPTABLE = "not-acceptable",
}

export interface Inspection {
  location: string;
  id: string;
  newInspection: boolean;
  deleted?: boolean;
  inspector: string;
  rating: InspectionRating;
  status: InspectionStatus;
  date: any;
  list: InspectionItem[];
}

export enum InspectionFrequency {
  TWO_WEEKS = 2,
  THREE_WEEKS,
  FOUR_WEEKS,
  FIVE_WEEKS,
  SIX_WEEKS,
  SEVEN_WEEKS,
  EIGHT_WEEKS,
}

export interface InspectionSchedules {
  [key: string]: Inspection;
}

export interface InspectionSchedule {
  inspector: string;
  location: string;
  id: string;
  frequency: InspectionFrequency;
}

export interface SecurityItems {
  [key: string]: Security;
}

export interface SecurityItem {
  text: string;
  title: string;
  deleted: boolean;
}

export interface Security {
  location: string;
  id: string;
  list: SecurityItem[];
}

export interface Locations {
  [key: string]: Location;
}

export interface InstructionItem {
  title: string;
  text: string;
  type: string;
  deleted?: boolean;
}

export interface Instruction {
  location: string;
  id: string;
  list: InstructionItem[];
}

export type NewNotification = Pick<
  Notification,
  Exclude<keyof Notification, "id" | "new">
>;

export interface Attachment {
  url: string;
  type: string;
  name: string;
}
export interface Notification {
  attachment?: Attachment;
  date: Date;
  id?: string;
  new?: boolean;
  text: string;
  title: string;
  type: MessageType;
  users: string[];
}

export interface PendingNotification extends Notification {
  newlyAdded: boolean;
  deleted: boolean;
}

export type WeekDays = "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat";

export interface CleaningHistory {
  date: string;
  shifts: Shift[];
}

/** Actions */

/** Shifts */
export interface ShiftsState {
  shifts: GeneralReducerState<Shift[]>;
  update: GeneralReducerState;
}

export interface ShiftsAction extends Action {
  type: ShiftsActionType;
  payload?: Shift[];
}

export enum ShiftsActionType {
  GET_SHIFTS_LOADING = "GET_SHIFTS_LOADING",
  GET_SHIFTS_SUCCESS = "GET_SHIFTS_SUCCESS",
  GET_SHIFTS_ERROR = "GET_SHIFTS_ERROR",
  UPDATE_SHIFTS_LOADING = "UPDATE_SHIFTS_LOADING",
  UPDATE_SHIFTS_SUCCESS = "UPDATE_SHIFTS_SUCCESS",
  UPDATE_SHIFTS_ERROR = "UPDATE_SHIFTS_ERROR",
  DOWNLOAD_DATA_EMPTY = "DOWNLOAD_DATA_EMPTY",
}

/** Instructions */
export interface InstructionsState extends DefaultState {
  readonly payload: Instruction[];
}

export interface InstructionsAction extends Action {
  type: InstructionsActionType;
  payload?: Instruction[];
}

export enum InstructionsActionType {
  GET_INSTRUCTIONS_LOADING = "GET_INSTRUCTIONS_LOADING",
  GET_INSTRUCTIONS_SUCCESS = "GET_INSTRUCTIONS_SUCCESS",
  GET_INSTRUCTIONS_ERROR = "GET_INSTRUCTIONS_ERROR",
  UPDATE_INSTRUCTIONS_LOADING = "UPDATE_INSTRUCTIONS_LOADING",
  UPDATE_INSTRUCTIONS_SUCCESS = "UPDATE_INSTRUCTIONS_SUCCESS",
  UPDATE_INSTRUCTIONS_ERROR = "UPDATE_INSTRUCTIONS_ERROR",
}

/** Authentication */
export interface AuthenticationState extends DefaultState {
  readonly isAuthenticated: boolean;
  readonly payload: string | null;
}

export interface AuthenticationAction extends Action {
  type: AuthenticationActionType;
  payload?: string | null;
}

export enum AuthenticationActionType {
  AUTHENTICATE_USER_LOADING = "AUTHENTICATE_USER_LOADING",
  AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS",
  AUTHENTICATE_USER_ERROR = "AUTHENTICATE_USER_ERROR",
  DEAUTHENTICATE_USER = "DEAUTHENTICATE_USER",
}

/** User */
export interface UserState extends DefaultState {
  payload: User | null;
}

/** Events */
export interface EventsState {
  isOpen: boolean;
  payload: string;
}

export interface EventsAction extends Action {
  type: EventsActionType;
}

export enum EventsActionType {
  UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS",
  HIDE_SNACKBAR = "HIDE_SNACKBAR",
}

/** Supplies */
export interface SuppliesState extends DefaultState {
  payload: Supply[];
}

export interface SuppliesAction extends Action {
  type: SuppliesActionType;
  payload?: Supply[];
}

export enum SuppliesActionType {
  GET_SUPPLIES_LOADING = "GET_SUPPLIES_LOADING",
  GET_SUPPLIES_ERROR = "GET_SUPPLIES_ERROR",
  GET_SUPPLIES_SUCCESS = "GET_SUPPLIES_SUCCESS",
  UPDATE_SUPPLIES_LOADING = "UPDATE_SUPPLIES_LOADING",
  UPDATE_SUPPLIES_ERROR = "UPDATE_SUPPLIES_ERROR",
  UPDATE_SUPPLIES_SUCCESS = "UPDATE_SUPPLIES_SUCCESS",
}

/** Security */
export interface SecurityState extends DefaultState {
  payload: Security[];
}

export interface SecurityAction extends Action {
  type: SecurityActionType;
  payload?: Security[];
}

export enum SecurityActionType {
  GET_SECURITY_LOADING = "GET_SECURITY_LOADING",
  GET_SECURITY_ERROR = "GET_SECURITY_ERROR",
  GET_SECURITY_SUCCESS = "GET_SECURITY_SUCCESS",
  UPDATE_SECURITY_LOADING = "UPDATE_SECURITY_LOADING",
  UPDATE_SECURITY_ERROR = "UPDATE_SECURITY_ERROR",
  UPDATE_SECURITY_SUCCESS = "UPDATE_SECURITY_SUCCESS",
}

/** Notification */
export interface NotificationsState {
  notifications: GeneralReducerState<Notification[]>;
  createNotification: GeneralReducerState;
}

export interface NotificationsAction extends Action {
  type: NotificationsActionType;
  payload?: Notification[];
}

export enum NotificationsActionType {
  GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING",
  GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR",
  GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS",
  UPDATE_NOTIFICATIONS_LOADING = "UPDATE_NOTIFICATIONS_LOADING",
  UPDATE_NOTIFICATIONS_ERROR = "UPDATE_NOTIFICATIONS_ERROR",
  UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS",
  CREATE_NOTIFICATION_LOADING = "CREATE_NOTIFICATION_LOADING",
  CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR",
  CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS",
  CREATE_NOTIFICATION_RESET = "CREATE_NOTIFICATION_RESET",
}

/** Files */
export interface FilesState {
  uploadFile: GeneralReducerState<Attachment>;
}

export interface FilesAction extends Action {
  type: FilesActionType;
  payload?: Attachment;
}

export enum FilesActionType {
  UPLOAD_FILE_LOADING = "UPLOAD_FILE_LOADING",
  UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR",
  UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_RESET = "UPLOAD_FILE_RESET",
}

/** Global */

/** Global App State */

export interface DefaultState {
  readonly isFetching: boolean;
  readonly error: boolean;
}

export interface GlobalState {
  authentication: AuthenticationState;
  events: EventsState;
  files: FilesState;
  inspectionSchedules: InspectionSchedulesState;
  inspections: InspectionsState;
  instructions: InstructionsState;
  location: LocationState;
  messages: MessagesState;
  notifications: NotificationsState;
  security: SecurityState;
  schedules: SchedulesState;
  shifts: ShiftsState;
  supplies: SuppliesState;
  user: UserState;
}

/** Global App Actions */
export type GlobalActions =
  | AuthenticationAction
  | EventsAction
  | FilesAction
  | InspectionSchedulesAction
  | InspectionsAction
  | InstructionsAction
  | LocationAction
  | MessagesAction
  | NotificationsAction
  | SecurityAction
  | SchedulesAction
  | ShiftsAction
  | SuppliesAction
  | UserAction;

/** Global Thunk */
export type ThunkResult<R> = ThunkAction<
  Promise<GlobalActions | void>,
  GlobalState,
  null,
  GlobalActions
>;

export type ThunkDispatcher = ThunkDispatch<GlobalState, null, GlobalActions> &
  Dispatch;

export * from "./messages";
export * from "./locations";
export * from "./users";
export * from "./shifts";
export * from "./schedules";
