import * as React from "react";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ChatBubble from "@mui/icons-material/ChatBubble";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { ClassNames } from "@emotion/react";

import {
  getIcon,
  getEmployee,
  getLocationThreadedMessages,
} from "../../../utils";
import { User, Message } from "../../../types";

interface MessageBoardCardProps {
  employees: User[];
  locationId: string;
  messages: Message[];
  newMessages?: string[];
  onSeeAll(): void;
}

type Props = MessageBoardCardProps;

function MessageBoardCard({
  locationId,
  employees = [],
  messages = [],
  onSeeAll,
  newMessages = [],
}: Props) {
  const messageBoardMessages = React.useMemo(
    () => getLocationThreadedMessages(messages, locationId, newMessages, 3),
    [messages, locationId, newMessages]
  );
  const unreadMessage = messageBoardMessages.some((message) => message.new);

  /** Renders a single message */
  function renderMessage(message: Message, isReply?: boolean) {
    const employee = getEmployee(employees, message.by);
    return (
      // @ts-ignore
      <ListItem key={message.id} alignItems="flex-start">
        {<ListItemIcon>{getIcon(message.type)}</ListItemIcon>}
        <ListItemText
          primary={
            employee
              ? `${employee.firstName} ${employee.lastName} `
              : "Unknown name"
          }
          secondary={
            <>
              <Typography variant="caption" gutterBottom={true}>
                {moment(message.date).format("MMMM DD h:mm a")}
              </Typography>
              <Typography variant="body1" gutterBottom={true}>
                {message.body}
              </Typography>
              <Typography variant="caption">
                {message.replies.length} Replies
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  }

  return (
    <ClassNames>
      {({ css }) => {
        const card = css({
          margin: "1.5rem 0",
        });
        const newChip = css({
          backgroundColor: red[500],
          color: "white",
        });
        const title = css({
          display: "flex",
          justifyContent: "space-between",
        });
        return (
          <Card className={card}>
            <CardHeader
              title={
                <div className={title}>
                  <span>Message Board</span>
                  {unreadMessage && (
                    <Chip size="small" label="New" className={newChip} />
                  )}
                </div>
              }
              avatar={
                <Avatar>
                  <ChatBubble />
                </Avatar>
              }
            />
            <CardContent>
              {messageBoardMessages && messageBoardMessages.length ? (
                <List style={{ padding: 0 }} className="list">
                  {messageBoardMessages.map((message) =>
                    renderMessage(message)
                  )}
                </List>
              ) : (
                "No messages"
              )}
            </CardContent>
            <CardActions>
              <div className="mla">
                <Button color="primary" onClick={onSeeAll} variant="contained">
                  See All
                </Button>
              </div>
            </CardActions>
          </Card>
        );
      }}
    </ClassNames>
  );
}

export default MessageBoardCard;
