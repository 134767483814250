import * as React from "react";
import moment from "moment";
import { QuerySnapshot } from "firebase/firestore";
import Info from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import {
  red,
  grey,
  purple,
  deepPurple,
  teal,
  pink,
  yellow,
  amber,
  blueGrey,
} from "@mui/material/colors";

import { weekdays } from "../constants";
import {
  User,
  Location,
  Message,
  Supply,
  Instruction,
  Security,
  Schedule,
  Shift,
  Inspection,
  InspectionSchedule,
  MessageType,
  ReassignShift,
  Notification,
  Thread,
} from "../types";
import getNextDelivery from "./getNextDelivery";

interface CSVData {
  [key: string]: any;
}

interface CSVDataArgs {
  data: CSVData;
}

const urgent = (
  <path d="M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13,17h-2v-2h2V17z M13,13h-2V7h2V13z" />
);
const daily = (
  <path
    d="M7,17.7h10V9.9H7V17.7z M8.4,11.3h1.4v1.4H8.4V11.3z M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10
    S17.5,2,12,2z M18.4,17.6c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V7.7c0-0.8,0.6-1.4,1.4-1.4h0.8V4.9h1.4v1.4h5.8V4.9h1.4
    v1.4H17c0.8,0,1.4,0.7,1.4,1.4V17.6z"
  />
);
const monthly = (
  <path
    d="M7,17.7h10V9.9H7V17.7z M14.1,11.3h1.4v1.4h-1.4V11.3z M14.1,14.3h1.4v1.4h-1.4V14.3z M11.3,11.3h1.4v1.4h-1.4
  V11.3z M11.3,14.3h1.4v1.4h-1.4V14.3z M8.4,11.3h1.4v1.4H8.4V11.3z M8.4,14.3h1.4v1.4H8.4V14.3z M12,2C6.5,2,2,6.4,2,12
  s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M18.4,17.6c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4V7.7c0-0.8,0.6-1.4,1.4-1.4h0.8
  V4.9h1.4v1.4h5.8V4.9h1.4v1.4H17c0.8,0,1.4,0.7,1.4,1.4V17.6z"
  />
);
const weekly = (
  <path
    d="M7,17.7h10V9.9H7V17.7z M14.1,11.3h1.4v1.4h-1.4V11.3z M11.3,11.3h1.4v1.4h-1.4V11.3z M8.4,11.3h1.4v1.4H8.4
    V11.3z M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M18.4,17.6c0,0.8-0.6,1.4-1.4,1.4H7c-0.8,0-1.4-0.6-1.4-1.4
    V7.7c0-0.8,0.6-1.4,1.4-1.4h0.8V4.9h1.4v1.4h5.8V4.9h1.4v1.4H17c0.8,0,1.4,0.7,1.4,1.4V17.6z"
  />
);
const newIcon = (
  <path
    d="M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M13.9,17.8c0,0.4-0.3,0.6-0.6,0.6h-2.6
    c-0.4,0-0.6-0.3-0.6-0.6v-0.6H14C14,17.1,14,17.8,13.9,17.8z M14.6,13.8v1.4c0,0.4-0.3,0.6-0.6,0.6h-3.9c-0.4,0-0.6-0.3-0.6-0.6
    v-1.4c-1.2-0.8-1.9-2.1-1.9-3.7c0-2.5,2-4.5,4.5-4.5s4.5,2,4.6,4.5C16.6,11.6,15.8,12.9,14.6,13.8z M12,6.8c-1.8,0-3.3,1.4-3.3,3.3
    c0,1.1,0.6,2.1,1.4,2.7l0.6,0.4v1.5h2.6v-1.5l0.6-0.4c0.9-0.6,1.4-1.6,1.4-2.6C15.3,8.3,13.8,6.8,12,6.8z"
  />
);
const special = (
  <path
    d="M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.1,18.4L12,16.1l-4.1,2.2l0.7-4.6l-3.3-3.3
    l4.6-0.6L12,5.7l2.1,4.2l4.6,0.6l-3.3,3.3L16.1,18.4z"
  />
);
const time = (
  <path
    d="M12,6.4c-3.1,0-5.6,2.5-5.6,5.6s2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6S15.1,6.4,12,6.4z M14.9,14.9l-3.7-2.2V8.5h1.1
    v3.7l3.2,1.9L14.9,14.9z M12,2C6.5,2,2,6.4,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7
    s7,3.1,7,7S15.9,19,12,19z"
  />
);
const security = (
  <path
    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M17.4,11.4c0,3.3-2.3,6.4-5.4,7.1
    c-3.1-0.8-5.3-3.9-5.3-7.1V7.8L12,5.4l5.4,2.4V11.4z M12,6.8L7.8,8.6V12H12v5.3c2.2-0.7,3.9-2.9,4.1-5.3H12V6.8z"
  />
);

/** Turns FB data to map */
export function extractSnapshot<T>(snapshot: QuerySnapshot) {
  // @ts-expect-error
  return snapshot.docs.map((a) => {
    const rawData = a.data();
    const id = a.id;
    // Firebase returns the date as timestamp, need to convert this for moment formatting
    const dateKeys = ["date", "checkIn", "checkOut", "startDate", "endDate"];
    const dateKeysInData = Object.keys(rawData).filter((key) =>
      dateKeys.includes(key)
    );
    dateKeysInData.forEach((key) => {
      if (rawData[key]) {
        rawData[key] = rawData[key].toDate();
      }
    });
    return { id, ...rawData };
  }) as T;
}

export const sortByName = (a: User, b: User) => {
  let nameA = `${a.firstName} ${a.lastName}`;
  let nameB = `${b.firstName} ${b.lastName}`;
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortByLocationName = (a: Location, b: Location) => {
  let nameA = a.name;
  let nameB = b.name;
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAlphaAscending = (a: any, b: any, by: string) => {
  let nameA = a[by];
  let nameB = b[by];
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const sortAlpha = (a: string, b: string) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/** Sorts by date */
export const sortByDate = (a: any, b: any) => {
  if (moment(b.date).isBefore(a.date)) {
    return -1;
  }
  if (moment(a.date).isBefore(b.date)) {
    return 1;
  }
  return 0;
};

/** Sorts by the start date */
export const sortByStartDate = (a: any, b: any) => {
  if (moment(b.startDate).isBefore(a.startDate)) {
    return 1;
  }
  if (moment(a.startDate).isBefore(b.startDate)) {
    return -1;
  }
  return 0;
};

export const getStatus = (state: string) => {
  let status;
  switch (state) {
    case "complete":
      status = (
        <Typography variant="caption" className="cgreen">
          Complete
        </Typography>
      );
      break;
    case "no-show":
      status = (
        <Typography variant="caption" className="cred">
          No Show
        </Typography>
      );
      break;
    case "incomplete":
      status = (
        <Typography variant="caption" className="cred">
          Incomplete
        </Typography>
      );
      break;
    case "checked-in":
      status = (
        <Typography variant="caption" className="cyellow">
          Checked-In
        </Typography>
      );
      break;
    case "checked":
      status = (
        <Typography variant="caption" className="cyellow">
          Checked
        </Typography>
      );
      break;
    case "working":
      status = (
        <Typography variant="caption" className="cyellow">
          Working
        </Typography>
      );
      break;
    default:
      status = (
        <Typography variant="caption" className="cgray">
          Assigned
        </Typography>
      );
      break;
  }
  return status;
};

export const getRating = (state: string) => {
  let status: React.ReactElement;
  switch (state) {
    case "excellent":
      status = <span className="cgreen fsxs">Excellent</span>;
      break;
    case "needs-improvement":
      status = <span className="cred fsxs">Needs Improvement</span>;
      break;
    case "not-acceptable":
      status = <span className="cred fsxs">Not Acceptable</span>;
      break;
    case "good":
      status = <span className="cyellow fsxs">Good</span>;
      break;
    default:
      status = <span className="fsxs" />;
      break;
  }
  return status;
};

// Gets only the icon
export const getIconOnly = (type: MessageType | string) => {
  let icon;
  switch (type) {
    case "urgent":
    case "alert":
      icon = (
        <SvgIcon fontSize="small" style={{ color: red[500] }} className="cred">
          {urgent}
        </SvgIcon>
      );
      break;
    case "security":
      icon = (
        <SvgIcon
          fontSize="small"
          style={{ color: blueGrey[500] }}
          className="cbluegrey"
        >
          {security}
        </SvgIcon>
      );
      break;
    case "time":
      icon = (
        <SvgIcon
          fontSize="small"
          style={{ color: amber[500] }}
          className="camber"
        >
          {time}
        </SvgIcon>
      );
      break;
    default:
      icon = <Info fontSize="small" style={{ color: grey[500] }} />;
      break;
  }
  return icon;
};

export const getIcon = (
  type: MessageType | string | null,
  textVisible?: boolean
) => {
  let icon;
  switch (type) {
    case "urgent":
    case "alert":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: red[500] }}>
            {urgent}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Urgent
            </Typography>
          )}
        </div>
      );
      break;
    case "security":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: blueGrey[500] }}>
            {security}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Security
            </Typography>
          )}
        </div>
      );
      break;
    case "time":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: amber[500] }}>
            {time}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Time
            </Typography>
          )}
        </div>
      );
      break;
    case "special":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: yellow[500] }}>
            {special}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Special
            </Typography>
          )}
        </div>
      );
      break;
    case "new":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: teal[500] }}>
            {newIcon}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              New
            </Typography>
          )}
        </div>
      );
      break;
    case "daily":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: deepPurple[500] }}>
            {daily}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Daily
            </Typography>
          )}
        </div>
      );
      break;
    case "monthly":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: purple[500] }}>
            {monthly}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Monthly
            </Typography>
          )}
        </div>
      );
      break;
    case "weekly":
      icon = (
        <div className="df aic icon-container">
          <SvgIcon fontSize="small" style={{ color: pink[500] }}>
            {weekly}
          </SvgIcon>
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Weekly
            </Typography>
          )}
        </div>
      );
      break;
    default:
      icon = (
        <div className="df aic icon-container">
          <Info style={{ color: grey[500] }} fontSize="small" />
          {textVisible && (
            <Typography variant="body2" gutterBottom={true}>
              Info
            </Typography>
          )}
        </div>
      );
      break;
  }
  return icon;
};

/** Gets a location name */
export const getLocationName = (location?: Location) => {
  if (location) {
    return location.name;
  }
  return "";
};

/** Gets a single inspection */
export const getInspection = (
  inspections: Inspection[] | null,
  inspectionId: string
) => {
  if (!inspections || !inspectionId) {
    return;
  }
  return inspections.find((message) => message.id === inspectionId);
};

/** Gets a single message */
export const getMessage = (messages: Message[] | null, messageId: string) => {
  if (!messages || !messageId) {
    return;
  }
  return messages.find((message) => message.id === messageId);
};

/** Gets all replies for a message */
export const getReplies = (messages: Message[], replies: string[]) => {
  return messages.filter((message) => {
    return replies.includes(message.id);
  });
};

/** Gets all cleaners assigned to location based on id */
export const getLocationCleaners = (
  cleaners: User[] | null,
  schedules: Schedule[] | null,
  locationId: string
) => {
  if (!cleaners || !schedules) {
    return [];
  }
  const locationCleaners = schedules
    .filter((shift) => shift.location === locationId)
    .map((shift) => {
      return cleaners.find((item) => item.id === shift.employee);
    });
  // Remove undefined cleaners
  return locationCleaners.filter((cleaner) => cleaner) as User[];
};

/** Gets a location based on location id */
export const getLocation = (
  locations: Location[] | null,
  locationId: string
) => {
  if (!locations) {
    return;
  }
  return locations.find((location) => location.id === locationId);
};

/** Gets location supplies based on location id */
export const getLocationSupplies = (
  supplies: Supply[] | null,
  locationId: string
): Supply => {
  const defaultSupply = {
    location: locationId,
    id: "",
    list: [],
    outOfStockCount: 0,
    nextDelivery: "",
    frequency: 0,
  };
  if (!supplies) {
    return defaultSupply;
  }
  const locationSupplies = supplies.find(
    (supply) => supply.location === locationId
  );
  if (!locationSupplies) {
    return defaultSupply;
  }
  return {
    ...locationSupplies,
    outOfStockCount: getOutOfStockCount(locationSupplies),
    nextDelivery: getNextDelivery(
      locationSupplies.frequency,
      locationSupplies.lastDelivery
    ),
  };
};

/** Gets location inspections based on location id */
export const getLocationInspections = (
  inspections: Inspection[] | null,
  locationId: string
) => {
  if (!inspections) {
    return;
  }
  const locationInspections = inspections.filter(
    (inspection) => inspection.location === locationId
  );
  if (!locationInspections) {
    return;
  }
  return locationInspections;
};

/** Gets instructions for a location based on id */
export const getLocationInstructions = (
  instructions: Instruction[] | null,
  locationId: string
) => {
  if (!instructions) {
    return;
  }
  const locationInstructions = instructions.find(
    (instruction) => instruction.location === locationId
  );
  if (!locationInstructions) {
    return {
      list: [],
      id: "",
      location: locationId,
    };
  }
  return locationInstructions;
};

/** Gets most recent inspection for a location based on id */
export const getLocationRecentInspection = (
  inspections: Inspection[] | null,
  locationId: string
) => {
  if (!inspections) {
    return;
  }
  return inspections.find((inspection) => inspection.location === locationId);
};

/** Gets general count of a list */
export const getListCount = (countOf: any) => {
  return countOf && countOf.list ? countOf.list.length : 0;
};

export const getEmployeeLocations = (
  id: string,
  shiftsSchedule: Schedule[]
) => {
  return shiftsSchedule
    .filter((shift) => shift.employee === id)
    .map((shift) => shift.location);
};

export const getEmployeeShifts = (shifts: Shift[], id: string) => {
  return shifts.filter(
    (shift) => shift.employee === id || shift.reassignedTo === id
  );
};

/** Gets all reassigned shifts */
export const getReassignedShifts = (shifts: Shift[]) => {
  return shifts.filter((shift) => shift.reassignedTo || shift.unassigned);
};

export const flattenObject = (object: any) => {
  if (!object) {
    return [];
  }
  return Object.keys(object).map((id) => ({
    ...object[id],
    id,
  }));
};

/** Gets an employee based on id */
export const getEmployee = (
  employees: User[] | null,
  employeeId: string
): User | undefined => {
  if (!employees) {
    return;
  }
  return employees.find((employee) => employee.id === employeeId);
};

/** Removes a deleted item from a list */
export const removeDeletedItems = (items: any[]) =>
  items
    .filter((item) => !item.deleted)
    .map((item) => {
      const { deleted, ...cleanItem } = item;
      return cleanItem;
    });

/** formats a phone number string */
export const formatPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return "";
  }
  const strippedPhoneNumber = phoneNumber.replace(/-/g, "");
  let areaCode = strippedPhoneNumber.substring(0, 3);
  let middle = strippedPhoneNumber.substring(3, 6);
  let end = strippedPhoneNumber.substring(6, 10);
  return `${areaCode}-${middle}-${end}`;
};

export const renderCleaner = (cleaner: User) => (
  <MenuItem value={cleaner.id} key={cleaner.id}>
    <ListItemText primary={`${cleaner.firstName} ${cleaner.lastName}`} />
  </MenuItem>
);

/** MESSAGES */

/** Gets messages with threading for a location based on id */
export function getLocationThreadedMessages(
  messages: Message[] | null,
  locationId: string,
  unreadMessagesIds: string[] = [],
  limit: number = -1
) {
  if (!messages) {
    return [];
  }
  const locationMessages = getLocationMessages(locationId, messages);
  const threadedMessages: Thread[] = [];
  locationMessages.every((message) => {
    // The limit allows us to only build what the consumer needs
    if (limit !== -1 && threadedMessages.length === limit) {
      return false;
    }
    const isReply = messages.find((thisMessage) =>
      (thisMessage.replies as string[]).includes(message.id)
    );
    if (!isReply) {
      const replies =
        message.replies && message.replies.length
          ? messages
              .filter((thisMessage) =>
                (message.replies as string[]).includes(thisMessage.id)
              )
              .sort(sortByDate)
          : [];
      const cleanMessage: Thread = {
        ...message,
        new: unreadMessagesIds.includes(message.id),
        replies,
      };
      threadedMessages.push(cleanMessage);
    }
    return true;
  });
  return threadedMessages;
}

/** LOCATIONS */

/** Gets location security based on location id */
export const getLocationSecurity = (
  securities: Security[] | null,
  locationId: string
) => {
  if (!securities) {
    return;
  }
  const locationSecurity = securities.find(
    (item) => item.location === locationId
  );
  if (!locationSecurity) {
    return {
      list: [],
      id: "",
      location: locationId,
    };
  }
  return locationSecurity;
};

/** Gets location shift schedules based on location id */
export const getLocationSchedules = (
  schedules: Schedule[] | null,
  locationId: string
) => {
  if (!schedules) {
    return;
  }
  return schedules.filter((schedule) => schedule.location === locationId);
};

/** Gets shifts for a particular location */
export function getLocationShifts(locationId: string, shifts: Shift[]) {
  return shifts.filter(
    (shift) =>
      shift.location === locationId && moment().diff(shift.date, "weeks") < 1
  );
}

/** Gets messages for a particular location */
export function getLocationMessages(locationId: string, messages: Message[]) {
  return messages.filter((message) => message.location === locationId);
}

/** Gets total number of out of stock items */
export function getOutOfStockCount(supply?: Supply): number {
  let outOfStockCount = 0;
  if (supply && supply.list && supply.list.length) {
    outOfStockCount = supply.list.filter((item) => !item.inStock).length;
  }
  return outOfStockCount;
}

/** Gets whether or not a location has something out of stock */
export function getLocationOutOfStock(locationId: string, supplies: Supply[]) {
  const supply = supplies.find(
    (thisSupply) => thisSupply.location === locationId
  );
  let outOfStock = false;
  if (supply && supply.list.length) {
    outOfStock = !!supply.list.find((item) => !item.inStock);
  }
  return outOfStock;
}

/** Gets a unique list of assigned cleaners and shift status */
export function getLocationUniqueShiftsList(
  locationId: string,
  shifts: Shift[]
) {
  return getLocationShifts(locationId, shifts).filter(
    (shift) => shift.status && shift.status !== "assigned"
  );
}

/** Gets a unique list of message types  */
export function getLocationUniqueMessagesList(
  locationId: string,
  messages: Message[]
) {
  const existingMessages: string[] = [];
  return getLocationMessages(locationId, messages).filter((message) => {
    if (existingMessages.includes(message.type as MessageType)) {
      return false;
    } else {
      existingMessages.push(message.type as MessageType);
      return true;
    }
  });
}

/** Gets inspection schedule for a location based on id */
export const getLocationInspectionSchedule = (
  inspectionSchedules: InspectionSchedule[] | null,
  locationId: string
) => {
  if (!inspectionSchedules) {
    return;
  }
  return inspectionSchedules.find(
    (schedule) => schedule.location === locationId
  );
};

/** Gets a list of cleaning history grouped by dates based on location id */
export function getLocationCleaningHistory(
  shifts: Shift[] | null,
  locations: Location[] | null,
  locationId: string
) {
  if (!shifts || !locations) {
    return;
  }
  let history = [];
  let groupedShifts: {
    [key: string]: {
      shifts: Shift[];
    };
  } = {};
  let uniqueDates: string[] = [];
  // Get all date groups
  shifts
    .filter((shift) => !!shift.status && shift.location === locationId)
    .forEach((shift) => {
      let date = moment(shift.startDate).format("YYYY-MM-DD");
      if (groupedShifts[date]) {
        groupedShifts[date].shifts.push(shift);
      } else {
        groupedShifts[date] = { shifts: [shift] };
      }
      if (!uniqueDates.includes(date)) {
        uniqueDates.push(date);
      }
    });

  history = uniqueDates.map((date) => ({
    date: date,
    shifts: groupedShifts[date].shifts,
  }));
  return history;
}

/** EMPLOYEES */

/** Gets schedule for a employee based on id */
export const getEmployeeSchedule = (
  schedules: Schedule[] | null,
  employeeId: string
) => {
  if (!schedules) {
    return;
  }
  return schedules.filter((schedule) => schedule.employee === employeeId);
};

/** Gets notifications for a employee based on id */
export const getEmployeeNotifications = (
  notifications: Notification[] | null,
  employeeId: string
) => {
  if (!notifications) {
    return;
  }
  return notifications.filter((notification) =>
    notification.users.includes(employeeId)
  );
};

/** Gets an employee's full name */
export const getEmployeeName = (employeeId: string, employees: User[]) => {
  const employee = employees!.find((item) => item.id === employeeId);
  return employee ? `${employee.firstName} ${employee.lastName}` : "";
};

/** Gets a list for each employee based on assigned locations */
export function getEmployeeLocationsList(shifts: Schedule[]) {
  let employeeLocationsList: {
    [key: string]: string[];
  } = {};
  shifts.forEach((shift) => {
    if (employeeLocationsList[shift.employee]) {
      employeeLocationsList[shift.employee].push(shift.location);
    } else {
      employeeLocationsList[shift.employee] = [];
      employeeLocationsList[shift.employee].push(shift.location);
    }
  });
  return employeeLocationsList;
}

/** Gets the employee's cleaning history */
export function getEmployeeCleaningHistory(
  shifts: Shift[],
  employeeId: string
) {
  let history = [];
  let groupedShifts: {
    [key: string]: {
      shifts: Shift[];
    };
  } = {};
  let uniqueDates: string[] = [];
  // Get all date groups
  shifts
    .filter(
      (shift) => !!shift.status && getEmployeeIdForShift(shift) === employeeId
    )
    .forEach((shift) => {
      let date = moment(shift.date).format("YYYY-MM-DD");
      if (groupedShifts[date]) {
        groupedShifts[date].shifts.push(shift);
      } else {
        groupedShifts[date] = { shifts: [shift] };
      }
      if (!uniqueDates.includes(date)) {
        uniqueDates.push(date);
      }
    });

  history = uniqueDates.map((date) => ({
    date: date,
    shifts: groupedShifts[date].shifts,
  }));
  return history;
}

/** Gets all newly assigned shifts (whether unassigned or assigned) */
export function getNewAssignments(shifts: (Shift | ReassignShift)[]) {
  return (
    shifts
      .filter((shift) => shift.newAssignment)
      // @ts-ignore Need to fix these types
      .map(({ newAssignment, deleted, ...rest }) => rest)
  );
}

/** OTHER */
export function orderWeekdays(shiftWeekdays: string[]) {
  let orderedWeekdays: string[] = [];
  weekdays.forEach((day) => {
    if (shiftWeekdays.includes(day)) {
      orderedWeekdays.push(day);
    }
  });
  return orderedWeekdays;
}

/** Gets employee ID for any given shift */
export function getEmployeeIdForShift(shift: Shift) {
  return shift.reassignedTo || shift.employee;
}

/** Gets the CSV data */
export function getCSVData(args: CSVDataArgs) {
  let result: string;
  let ctr: number;
  let keys: string[];
  let columnDelimiter: string = ",";
  let lineDelimiter: string = "\n";
  let data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  keys = Object.keys(data[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item: CSVData) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }
      let itemData = item[key];
      // Escape commas since it is the delimeter
      if (itemData.includes(",")) {
        itemData = `"${itemData}"`;
      }

      result += itemData;
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;
}

export function downloadCSV(csvList: {}[], fileName: string) {
  let link;
  let csv = getCSVData({
    data: csvList,
  });
  if (csv == null) {
    return;
  }

  if (!csv.match(/^data:text\/csv/i)) {
    csv = "data:text/csv;charset=utf-8," + csv;
  }
  const data = encodeURI(csv);

  link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", fileName);
  link.click();
}
