import { InspectionsAction, InspectionsActionType } from "actions";
import { Inspection } from "../types";
import generalReducer, {
  GeneralReducerActionType,
  GeneralReducerState,
  initialReducerState,
} from "./generalReducer";

export interface InspectionsState {
  inspections: GeneralReducerState<Inspection[]>;
}

const initialInspectionsState = {
  inspections: initialReducerState([]),
};
const { ERROR, SUCCESS, LOADING } = GeneralReducerActionType;

export default function inspectionsReducer(
  state: InspectionsState = initialInspectionsState,
  action: InspectionsAction
) {
  switch (action.type) {
    case InspectionsActionType.GET_INSPECTIONS_LOADING:
      return {
        ...state,
        inspections: generalReducer(state.inspections, LOADING),
      };
    case InspectionsActionType.GET_INSPECTIONS_SUCCESS:
      return {
        ...state,
        inspections: generalReducer(state.inspections, SUCCESS, action.payload),
      };
    case InspectionsActionType.GET_INSPECTIONS_ERROR:
      return {
        ...state,
        inspections: generalReducer(state.inspections, ERROR, action.payload),
      };
    case InspectionsActionType.UPDATE_INSPECTIONS_LOADING:
      return {
        ...state,
        inspections: generalReducer(state.inspections, LOADING, action.payload),
      };
    case InspectionsActionType.UPDATE_INSPECTIONS_SUCCESS:
    case InspectionsActionType.UPDATE_INSPECTIONS_ERROR:
      return {
        ...state,
        inspections: generalReducer(
          state.inspections,
          SUCCESS,
          state.inspections.data
        ),
      };
    default:
      return state;
  }
}
