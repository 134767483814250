import {
  InstructionsAction,
  InstructionsState,
  InstructionsActionType,
  Instruction,
} from "../types";

export default function instructionsReducer(
  state: InstructionsState = {
    isFetching: false,
    payload: [],
    error: false,
  },
  { type, payload }: InstructionsAction
) {
  switch (type) {
    case InstructionsActionType.GET_INSTRUCTIONS_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case InstructionsActionType.GET_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        payload: payload as Instruction[],
        isFetching: false,
      };
    case InstructionsActionType.GET_INSTRUCTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    default:
      return state;
  }
}
