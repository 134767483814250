import { FieldPath, WhereFilterOp } from "firebase/firestore";
import moment from "moment";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";

import { db } from "../firebaseconfig";

const inspectionsService = {
  getAll() {
    return query(
      collection(db, "inspections"),
      where(
        "date",
        ">=",
        moment().subtract(12, "weeks").startOf("day").toDate()
      ),
      orderBy("date", "desc")
    );
  },
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(collection(db, "inspections"), where(fieldPath, operation, value))
    );
  },
};

export default inspectionsService;
