import * as React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import History from "@mui/icons-material/History";
import Typography from "@mui/material/Typography";
import { CardActions, CircularProgress } from "@mui/material";

import {
  getStatus,
  getEmployeeCleaningHistory,
  getLocation,
} from "../../../utils";
import { Shift, CleaningHistory, GlobalState } from "../../../types";
import { useLocations } from "hooks/useLocations";
import { GeneralReducerActionType } from "reducers";

function CleaningHistoryCard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const shifts = useSelector((state: GlobalState) => state.shifts.shifts.data);
  const locations = useLocations();
  /** Renders the shift for the cleaning history date */
  function renderCleaningHistoryShift(shift: Shift, i: number) {
    const location = getLocation(locations.data, shift.location);
    return (
      <Card className="mrm w25p mbs" variant="outlined">
        <CardContent>
          <Typography variant="body1">
            {location ? location.name : "Unknown location"}
          </Typography>
          <Typography variant="caption">
            {`${
              shift.checkIn ? moment(shift.checkIn).format("h:mm a") : "--:--"
            } ${
              shift.status === "complete"
                ? "- " + moment(shift.checkOut).format("h:mm a")
                : ""
            }`}
          </Typography>
          <Typography>{getStatus(shift.status)}</Typography>
        </CardContent>
      </Card>
    );
  }

  /** Renders cleaning history */
  function renderCleaningHistory(cleaningHistory: CleaningHistory, i: number) {
    return (
      <div className="separate pbs mbs" key={i}>
        <Typography gutterBottom={true} variant="h6">
          {`${moment(cleaningHistory.date).format("dddd")}, ${moment(
            cleaningHistory.date
          ).format("MMM")} ${moment(cleaningHistory.date).format("DD")}`}
        </Typography>
        <div className="df fww w100p">
          {React.Children.toArray(
            cleaningHistory.shifts.map(renderCleaningHistoryShift)
          )}
        </div>
      </div>
    );
  }

  if (locations.status === GeneralReducerActionType.LOADING || !shifts) {
    return <CircularProgress />;
  }
  const cleaningHistory = getEmployeeCleaningHistory(shifts, id!);
  return (
    <Card className="cleaning-history-card">
      <CardHeader
        title="Cleaning History"
        avatar={
          <Avatar>
            <History />
          </Avatar>
        }
      />
      <CardContent>
        {cleaningHistory && cleaningHistory.length
          ? cleaningHistory.slice(0, 3).map(renderCleaningHistory)
          : "No cleaning history"}
      </CardContent>
      <CardActions>
        {cleaningHistory && cleaningHistory.length > 3 && (
          <div className="mla">
            <Button
              color="primary"
              onClick={() => navigate(`/employees/${id}/cleaningHistory`)}
              variant="contained"
            >
              See All
            </Button>
          </div>
        )}
      </CardActions>
    </Card>
  );
}

export default CleaningHistoryCard;
