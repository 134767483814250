import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { indigo, pink } from "@mui/material/colors";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import store from "./store";
import App from "./containers/app/App";
import "./index.scss";
import { PrivacyPolicy } from "./containers/privacyPolicy/PrivacyPolicy";
import { EmployeesProvider } from "hooks/useEmployees";
import { LocationsProvider } from "hooks/useLocations";
import { SchedulesProvider } from "hooks/useSchedules";

const target = document.querySelector("#root");

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: pink[500],
    },
  },
});

ReactDOM.createRoot(target!).render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <EmployeesProvider>
          <LocationsProvider>
            <SchedulesProvider>
              <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<App />} />
              </Routes>
            </SchedulesProvider>
          </LocationsProvider>
        </EmployeesProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
