import { LocationAction, LocationActionType } from "../actions";
import generalReducer, {
  GeneralReducerActionType,
  GeneralReducerState,
  initialReducerState,
} from "./generalReducer";

export interface LocationState {
  location: GeneralReducerState;
  update: GeneralReducerState;
  delete: GeneralReducerState;
}

const initialLocationState = {
  location: initialReducerState(),
  update: initialReducerState(),
  delete: initialReducerState(),
};

const { ERROR, SUCCESS, LOADING, NONE } = GeneralReducerActionType;

export default function locationReducer(
  state: LocationState = initialLocationState,
  action: LocationAction
) {
  switch (action.type) {
    case LocationActionType.GET_LOCATION_LOADING:
      return {
        ...state,
        location: generalReducer(state.location, LOADING),
      };
    case LocationActionType.GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: generalReducer(state.location, SUCCESS, action.payload),
      };
    case LocationActionType.GET_LOCATION_ERROR:
      return {
        ...state,
        location: generalReducer(state.location, ERROR, action.payload),
      };
    case LocationActionType.UPDATE_LOCATION_LOADING:
      return {
        ...state,
        update: generalReducer(state.update, LOADING),
      };
    case LocationActionType.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        update: generalReducer(state.update, SUCCESS, action.payload),
      };
    case LocationActionType.UPDATE_LOCATION_ERROR:
      return {
        ...state,
        update: generalReducer(state.update, ERROR, action.payload),
      };
    case LocationActionType.DELETE_LOCATION_LOADING:
      return {
        ...state,
        delete: generalReducer(state.delete, LOADING),
      };
    case LocationActionType.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        delete: generalReducer(state.delete, SUCCESS, action.payload),
      };
    case LocationActionType.DELETE_LOCATION_RESET:
      return {
        ...state,
        delete: generalReducer(state.delete, NONE),
      };
    case LocationActionType.DELETE_LOCATION_ERROR:
      return {
        ...state,
        delete: generalReducer(state.delete, ERROR, action.payload),
      };
    default:
      return state;
  }
}
