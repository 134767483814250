import * as React from "react";
import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import { ClassNames } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Toolbar, CircularProgress } from "@mui/material";

import { updateInspectionSchedule, updateInspections } from "../../../actions";
import {
  getLocationInspectionSchedule,
  getEmployee,
  getLocation,
  getLocationInspections,
} from "../../../utils";
import EditInspection from "../dialogs/EditInspection";
import {
  Inspection,
  GlobalState,
  InspectionSchedule as IInspectionSchedule,
  InspectionStatus,
  InspectionRating,
  InspectionFrequency,
} from "../../../types";
import { InspectionSchedule, InspectionSummary } from "components";
import { GeneralReducerActionType } from "reducers";
import { InspectionsState } from "reducers/inspections";
import { InspectionSchedulesState } from "reducers/inspectionSchedules";
import { useEmployees } from "hooks/useEmployees";
import { useLocations } from "hooks/useLocations";

interface State {
  inspectionItemText: string;
  inspectionItemTitle: string;
  inspectionSchedule: IInspectionSchedule | null;
  inspections: Inspection[];
  isAddingInspection: boolean;
  isAddingItem: boolean;
  isEditingInspection: boolean;
  openInspection: Inspection | null;
  openInspectionIndex: number;
}

const initialState: State = {
  inspectionItemText: "",
  inspectionItemTitle: "",
  inspections: [],
  inspectionSchedule: null,
  isAddingInspection: false,
  isAddingItem: false,
  isEditingInspection: false,
  openInspection: null,
  openInspectionIndex: -1,
};

// TODO: Decompose EditInspection

function InspectionsForm() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locations = useLocations();
  const employees = useEmployees();
  const inspectionsState = useSelector<
    GlobalState,
    InspectionsState["inspections"]
  >((state) => state.inspections.inspections);
  const inspectionSchedules = useSelector<
    GlobalState,
    InspectionSchedulesState["inspectionSchedules"]
  >((state) => state.inspectionSchedules.inspectionSchedules);
  const [state, setState] = React.useState(initialState);
  const location = React.useMemo(
    () => getLocation(locations.data, id!),
    [locations.data, id]
  );

  React.useEffect(() => {
    if (
      inspectionSchedules.status === GeneralReducerActionType.SUCCESS &&
      !state.inspectionSchedule
    ) {
      const emptyInspection = {
        inspector: "",
        location: id!,
        frequency: InspectionFrequency.TWO_WEEKS,
        id: "",
      };
      const inspectionSchedule = getLocationInspectionSchedule(
        inspectionSchedules.data,
        id!
      );
      setState({
        ...state,
        inspectionSchedule: inspectionSchedule || emptyInspection,
      });
    }
  }, [inspectionSchedules, id, state]);

  React.useEffect(() => {
    if (
      inspectionsState.status === GeneralReducerActionType.SUCCESS &&
      !state.inspections.length
    ) {
      const locationInspections = getLocationInspections(
        inspectionsState.data,
        id!
      );
      setState({ ...state, inspections: locationInspections || [] });
    }
  }, [inspectionsState.status, state]);

  /** Deletes inspection item from inspection list */
  function handleListItemDeletion(inspectionItemIndex: number) {
    const { openInspection } = state;
    setState({
      ...state,
      openInspection: {
        ...(openInspection! as Inspection),
        list: (openInspection! as Inspection).list.filter(
          (item, index) => index !== inspectionItemIndex
        ),
      },
    });
  }

  /** Handles generic changes */
  function handleChange({
    target: { value, name },
  }: React.ChangeEvent<HTMLSelectElement>) {
    const { openInspection } = state;
    setState({
      ...state,
      openInspection: {
        ...(openInspection! as Inspection),
        [name]: value,
      },
    });
  }

  /** Adds inspection to list */
  function handleAddNewInspection() {
    const {
      inspections,
      openInspection,
      isAddingInspection,
      openInspectionIndex,
    } = state;
    setState({
      ...state,
      inspections: isAddingInspection
        ? [openInspection! as Inspection, ...(inspections! as Inspection[])]
        : Object.assign(inspections, {
            [openInspectionIndex]: openInspection,
          }),
      openInspection: null,
      isAddingInspection: false,
      isEditingInspection: false,
    });
  }

  /** Adds an inspection */
  function handleAddInspection() {
    const { inspections, inspectionSchedule } = state;
    const tempId = inspections ? (inspections as Inspection[]).length : 0;
    const inspectors = employees.data
      ? employees.data.filter((employee) => employee.inspector)
      : [];
    const defaultInspector = inspectors.length ? inspectors[0].id : "";
    setState({
      ...state,
      openInspection: {
        newInspection: true,
        location: id!,
        list: [],
        rating: InspectionRating.GOOD,
        status: InspectionStatus.ASSIGNED,
        // @ts-ignore
        inspector:
          inspectionSchedule && inspectionSchedule.inspector
            ? inspectionSchedule.inspector
            : (defaultInspector as string),
        date: new Date(),
        id: "",
      },
      openInspectionIndex: tempId,
      isAddingInspection: true,
    });
  }

  /** Closes the add inspection modal */
  function handleAddInspectionClose() {
    setState({
      ...state,
      openInspection: null,
      isAddingInspection: false,
      isEditingInspection: false,
    });
  }

  /** Toggles add item modal */
  function toggleAddItem() {
    setState({ ...state, isAddingItem: !state.isAddingItem });
  }

  /** Handles frequent inspection changes */
  function handleFrequentInspectionChange(value: string, name: string) {
    setState({
      ...state,
      inspectionSchedule: {
        ...(state.inspectionSchedule! as IInspectionSchedule),
        [name]: value,
      },
    });
  }

  /** Handles the item title and text changes */
  function handleEditInspectionItem({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, [name]: value });
  }

  /** Adds an inspection item to an inspection */
  function handleAddInspectionItem() {
    const {
      inspections,
      inspectionItemTitle,
      openInspectionIndex,
      inspectionItemText,
      openInspection,
    } = state;
    if (inspections && inspections[openInspectionIndex]) {
      const inspection = inspections[openInspectionIndex];
      setState({
        ...state,
        inspections: Object.assign([], inspections, {
          [openInspectionIndex]: {
            ...(inspection! as Inspection),
            list: [
              ...(inspection as Inspection).list,
              ...[
                {
                  title: inspectionItemTitle,
                  text: inspectionItemText,
                  images: [],
                },
              ],
            ],
          },
        }),
        isAddingItem: false,
        inspectionItemTitle: "",
        inspectionItemText: "",
        openInspection: {
          ...(openInspection! as Inspection),
          list: [
            ...(openInspection! as Inspection).list,
            ...[
              {
                title: inspectionItemTitle,
                text: inspectionItemText,
                images: [],
              },
            ],
          ],
        },
      });
    } else {
      setState({
        ...state,
        openInspection: {
          ...(openInspection! as Inspection),
          list: [
            ...(openInspection! as Inspection).list,
            ...[
              {
                title: inspectionItemTitle,
                text: inspectionItemText,
                images: [],
              },
            ],
          ],
        },
        isAddingItem: false,
        inspectionItemTitle: "",
        inspectionItemText: "",
      });
    }
  }

  /** Toggles the inspection modal */
  function handleInspectionClick(
    openInspection: Inspection,
    openInspectionIndex: number
  ) {
    setState({
      ...state,
      isEditingInspection: true,
      openInspectionIndex,
      openInspection,
    });
  }

  /** Renders a single inspection card */
  function renderInspection(inspection: Inspection, index: number) {
    const inspector = getEmployee(employees.data, inspection.inspector);
    return (
      <Card
        className="single-inspection"
        onClick={() => handleInspectionClick(inspection, index)}
        key={index}
      >
        <CardContent>
          <InspectionSummary inspector={inspector} inspection={inspection} />
        </CardContent>
      </Card>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${id}`);
  }

  /** Handles saving */
  function handleSave() {
    dispatch(updateInspections(state.inspections! as Inspection[]));
    dispatch(
      updateInspectionSchedule(state.inspectionSchedule! as IInspectionSchedule)
    );
  }

  if (
    [employees.status, inspectionsState.status].includes(
      GeneralReducerActionType.LOADING
    ) ||
    !state.inspections
  ) {
    return <CircularProgress />;
  }
  return (
    <ClassNames>
      {({ css }) => {
        const appBar = css({
          position: "relative",
        });
        const flex = css({
          flex: 1,
        });
        const fab = css({
          position: "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        return (
          <Dialog fullScreen={true} open={true} onClose={handleClose}>
            {state.openInspection && (
              <EditInspection
                inspection={state.openInspection}
                isAddingItem={state.isAddingItem}
                isEditing={state.isEditingInspection}
                isOpen={state.isEditingInspection || state.isAddingInspection}
                item={{
                  title: state.inspectionItemTitle,
                  text: state.inspectionItemText,
                  images: [],
                }}
                locationName={location!.name}
                onAddItem={handleAddInspectionItem}
                onChange={handleChange}
                onClose={handleAddInspectionClose}
                onEditItem={handleEditInspectionItem}
                onSave={handleAddNewInspection}
                onToggleAddItem={toggleAddItem}
                onDeleteListItem={handleListItemDeletion}
              />
            )}
            <AppBar className={appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" className={flex}>
                  Inspections | {location && location.name}
                </Typography>
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div className="pm">
              <InspectionSchedule
                employees={employees.data}
                schedule={state.inspectionSchedule}
                onChange={handleFrequentInspectionChange}
                inspections={state.inspections}
              />
            </div>
            <Divider />
            <DialogContent className="inspections ptm">
              {state.inspections.map(renderInspection)}
            </DialogContent>
            <Fab
              onClick={handleAddInspection}
              color="primary"
              className={fab}
              variant="extended"
            >
              <Add />
              Add Inspection
            </Fab>
          </Dialog>
        );
      }}
    </ClassNames>
  );
}

export default InspectionsForm;
