import * as React from "react";
import Typography from "@mui/material/Typography";
import { CircularProgress, Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";

import AddNewLocation from "./dialogs/AddNewLocation";
import { MessageType } from "../../types";
import {
  LocationFilters,
  LocationsTable,
  DownloadShiftHistoryDialog,
} from "../../components";
import useLocationsList from "./useLocationsList";
import { removeAllNewMessages } from "../../actions";

const Actions = styled.div`
  margintop: 32px;
  button {
    display: block;
    margin-bottom: 8px;
  }
`;
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Count = styled.div`
  display: flex;
`;

// TODO: Optimize the list by having loading state for individual data instead of blocking the rendering of the entire list.

function Locations() {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const [messageTypeFilter, setMessageTypeFilter] = React.useState<
    MessageType[]
  >([]);
  const { state, actions } = useLocationsList(search, messageTypeFilter);
  const [addLocationVisible, setAddLocationVisible] = React.useState(false);
  const [downloadDataVisible, setDownloadDataVisible] = React.useState(false);

  /** Toggles the visibly of the add location dialog */
  function toggleAddNewLocation() {
    setAddLocationVisible(!addLocationVisible);
  }

  /** Clears all newMessages from user */
  function handleClearUnreadMessage() {
    dispatch(removeAllNewMessages(state.userId!));
  }

  return (
    <>
      <Grid container={true} spacing={8}>
        <Grid item={true} md={3} sm={12} xs={12}>
          <LocationFilters
            onSearchChange={(event) => setSearch(event.target.value)}
            search={search}
            messageType={messageTypeFilter}
            onMessageTypeChange={setMessageTypeFilter}
          />
          <Actions>
            <Typography variant="h6" gutterBottom={true}>
              Actions
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={actions.downloadStockList}
            >
              Download Stock List
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setDownloadDataVisible(true)}
            >
              Download Times
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleAddNewLocation}
            >
              Add Location
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearUnreadMessage}
            >
              Clear Unread Messages
            </Button>
          </Actions>
        </Grid>
        <Grid item={true} md={9} sm={12}>
          <TableHeader>
            <Count>
              <Typography variant="h6" gutterBottom={true}>
                {state.filteredLocations.length}
              </Typography>
              <div className="mrs" />
              <Typography variant="h6" gutterBottom={true}>
                Locations
              </Typography>
            </Count>
          </TableHeader>
          {state.loading ? (
            <CircularProgress />
          ) : (
            <LocationsTable locations={state.filteredLocations} />
          )}
        </Grid>
      </Grid>
      <AddNewLocation
        isOpen={addLocationVisible}
        toggleAddNewLocation={toggleAddNewLocation}
      />
      <DownloadShiftHistoryDialog
        cleaners={state.employees.data}
        locations={state.locations.data}
        open={downloadDataVisible}
        shifts={state.shifts.data}
        onCancel={() => setDownloadDataVisible(false)}
      />
    </>
  );
}

export default Locations;
