import * as React from "react";
import moment from "moment";
import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import { ClassNames } from "@emotion/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getStatus,
  getEmployeeCleaningHistory,
  getLocation,
} from "../../../utils";
import {
  Shift,
  GlobalState,
  CleaningHistory as CleaningHistoryType,
} from "../../../types";
import { useLocations } from "hooks/useLocations";
import { GeneralReducerActionType } from "reducers";

function CleaningHistory() {
  const navigate = useNavigate();
  const { id } = useParams();
  const locations = useLocations();
  const shifts = useSelector<GlobalState, Shift[]>(
    (state) => state.shifts.shifts.data
  );

  /** Renders the shift for the cleaning history date */
  function renderCleaningHistoryShift(shift: Shift, i: number) {
    const location = getLocation(locations.data, shift.location);
    return (
      <Card className="mrm w25p mbs" variant="outlined">
        <CardContent>
          <Typography variant="body1">
            {location ? location.name : ""}
          </Typography>
          <Typography variant="caption">
            {`${
              shift.checkIn ? moment(shift.checkIn).format("h:mm a") : "--:--"
            } ${
              shift.status === "complete"
                ? "- " + moment(shift.checkOut).format("h:mm a")
                : ""
            }`}
          </Typography>
          <Typography>{getStatus(shift.status)}</Typography>
        </CardContent>
      </Card>
    );
  }

  /** Renders cleaning history */
  function renderCleaningHistory(
    cleaningHistory: CleaningHistoryType,
    i: number
  ) {
    return (
      <div className="separate pbs mbs" key={i}>
        <Typography gutterBottom={true} variant="h6">
          {`${moment(cleaningHistory.date).format("dddd")}, ${moment(
            cleaningHistory.date
          ).format("MMM")} ${moment(cleaningHistory.date).format("DD")}`}
        </Typography>
        <div className="df fww w100p">
          {React.Children.toArray(
            cleaningHistory.shifts.map(renderCleaningHistoryShift)
          )}
        </div>
      </div>
    );
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/employees/${id}`);
  }

  if (locations.status === GeneralReducerActionType.LOADING || !shifts) {
    return null;
  }
  const cleaningHistory = getEmployeeCleaningHistory(shifts, id!);
  return (
    <ClassNames>
      {({ css }) => (
        <Dialog fullScreen={true} open={true} onClose={handleClose}>
          <AppBar className={css({ position: "relative" })}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={css({ flex: 1 })}
              >
                Cleaning History
              </Typography>
            </Toolbar>
          </AppBar>
          <div className="pm">
            {cleaningHistory && cleaningHistory.map(renderCleaningHistory)}
          </div>
        </Dialog>
      )}
    </ClassNames>
  );
}

export default CleaningHistory;
