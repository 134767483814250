export enum MessageType {
  INFO = "info",
  SECURITY = "security",
  TIME = "time",
  URGENT = "urgent",
}

export interface Message {
  by: string;
  id: string;
  replies: string[] | Message[];
  location: string;
  date: any;
  // Replies should not have a type
  type: MessageType | null;
  body: string;
}

export interface Thread extends Message {
  new: boolean;
}
