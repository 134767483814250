import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import moment from "moment";
import { TextField } from "@mui/material";

import {
  getEmployee,
  getLocation,
  getEmployeeIdForShift,
  downloadCSV,
} from "../utils";
import { Shift, User, Location } from "../types";

interface Props {
  open: boolean;
  onCancel: () => void;
  shifts: Shift[];
  cleaners: User[];
  locations: Location[];
}
function DownloadShiftHistoryDialog({
  open,
  onCancel,
  shifts,
  cleaners,
  locations,
}: Props) {
  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, "weeks").startOf("day").toDate()
  );
  const [endDate, setEndDate] = React.useState(moment().endOf("day").toDate());

  /** Downloads the CSV data */
  function download() {
    const filteredShifts = shifts.filter(
      (shift) =>
        moment(shift.checkIn).isSameOrAfter(startDate) &&
        moment(shift.checkIn).isSameOrBefore(endDate)
    );
    const csvData = filteredShifts.map((shift) => {
      const employee = getEmployee(cleaners, getEmployeeIdForShift(shift));
      const location = getLocation(locations, shift.location);
      return {
        "Shift ID": shift.id,
        "Location ID": location ? location.assignedId : "-",
        Location: location ? location.name : "Unknown",
        "Employee ID": employee ? employee.id : "-",
        "Employee Name": employee
          ? employee.firstName + " " + employee.lastName
          : "Unknown",
        Status: shift.status.toUpperCase(),
        "Check-in": shift.checkIn
          ? moment(shift.checkIn).format("MM/DD/YY HH:mm:ss")
          : "--:--",
        "Check-out": shift.checkOut
          ? moment(shift.checkOut).format("MM/DD/YY HH:mm:ss")
          : "--:--",
      };
    });

    downloadCSV(csvData, `bishop-shifts-${new Date()}.csv`);
    onCancel();
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Download data</DialogTitle>
      <DialogContent>
        <DialogContentText className="mbs">
          Choose a date range below for which to download data.
        </DialogContentText>
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          defaultValue={moment(startDate).format("YYYY-MM-DD")}
          onChange={(e) =>
            setStartDate(moment(e.target.value).startOf("day").toDate())
          }
        />
        <TextField
          id="endDate"
          label="End Date"
          type="date"
          defaultValue={moment(endDate).format("YYYY-MM-DD")}
          onChange={(e) =>
            setEndDate(moment(e.target.value).endOf("day").toDate())
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={download} color="primary" variant="contained">
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadShiftHistoryDialog;
