import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebaseconfig";
import { fetchSupplies } from "./supplies";
import { fetchSchedules } from "./schedules";
import {
  Credentials,
  AuthenticationActionType,
  ThunkResult,
  AuthenticationAction,
} from "../types";
import { fetchShifts } from "./shifts";
import { fetchMessages } from "./messages";
import { fetchInstructions } from "./instructions";
import { fetchSecurity } from "./security";
import { fetchInspections } from "./inspections";
import { fetchInspectionSchedules } from "./inspectionSchedules";
import { fetchNotifications } from "./notifications";

/** Authenticate user success */
export const authenticateUserSuccess = (
  payload: string
): AuthenticationAction => {
  return {
    type: AuthenticationActionType.AUTHENTICATE_USER_SUCCESS,
    payload,
  };
};

/** Deauthenticate user */
export const deauthenticateUser = (): AuthenticationAction => {
  return {
    type: AuthenticationActionType.DEAUTHENTICATE_USER,
  };
};

/** Fetches all data */
export const fetchApplicationData = (): ThunkResult<any> => {
  return async (dispatch) => {
    dispatch(fetchShifts());
    dispatch(fetchSupplies());
    dispatch(fetchSchedules());
    dispatch(fetchMessages());
    dispatch(fetchInstructions());
    dispatch(fetchSecurity());
    dispatch(fetchInspections());
    dispatch(fetchInspectionSchedules());
    dispatch(fetchNotifications());
  };
};

/** Checks sessionstorage for existing authentication */
export const checkUserAuthentication =
  (): ThunkResult<AuthenticationAction> => {
    return async (dispatch) => {
      if (sessionStorage.userId !== "null" && sessionStorage.userId) {
        dispatch(authenticateUserSuccess(sessionStorage.userId));
        dispatch(fetchApplicationData());
      } else {
        dispatch(deauthenticateUser());
      }
    };
  };

/** Authenticate user error */
export const authenticateUserError = (): AuthenticationAction => ({
  type: AuthenticationActionType.AUTHENTICATE_USER_ERROR,
});

/** Authenticate user loading */
export const authenticateUserLoading = (): AuthenticationAction => ({
  type: AuthenticationActionType.AUTHENTICATE_USER_LOADING,
});

/** Authenticate user */
export const authenticateUser = (
  credentials: Credentials
): ThunkResult<string> => {
  return async (dispatch) => {
    dispatch(authenticateUserLoading());
    return getDocs(
      query(
        collection(db, "employees"),
        where("userName", "==", credentials.userName),
        where("password", "==", credentials.password),
        where("admin", "==", true)
      )
    )
      .then((doc) => {
        if (doc.empty) {
          dispatch(authenticateUserError());
        } else {
          sessionStorage.userId = doc.docs[0].id;
          dispatch(fetchApplicationData());
          dispatch(authenticateUserSuccess(doc.docs[0].id));
        }
      })
      .catch((e) => console.log(e));
  };
};
