import * as React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Event from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import RemoveShoppingCart from "@mui/icons-material/RemoveShoppingCart";
import SaveIcon from "@mui/icons-material/Save";
import Select from "@mui/material/Select";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  ListItemText,
  InputLabel,
  CircularProgress,
  TextField,
  FormControl,
} from "@mui/material";

import AddSupplyItem from "../dialogs/AddSupplyItem";
import { Supply, GlobalState, SupplyItem } from "../../../types";
import {
  removeDeletedItems,
  sortAlphaAscending,
  getLocationSupplies,
  getLocation,
} from "../../../utils";
import { updateSupplies } from "../../../actions";
import { ClassNames } from "@emotion/react";
import getNextDelivery from "utils/getNextDelivery";
import { useLocations } from "hooks/useLocations";

function SuppliesForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [addSupplyItem, setAddSupplyItem] = React.useState(false);
  const [checked, setChecked] = React.useState([-1]);
  const [allChecked, setAllChecked] = React.useState(false);
  const [supply, setSupply] = React.useState<Supply | null>(null);
  const suppliesState = useSelector<GlobalState, GlobalState["supplies"]>(
    (state) => state.supplies
  );
  const locations = useLocations();
  const location = React.useMemo(
    () => getLocation(locations.data, id!),
    [id, locations.data]
  );

  React.useEffect(() => {
    if (suppliesState.payload && !supply) {
      const locationSupplies = getLocationSupplies(suppliesState.payload, id!);
      setSupply(locationSupplies || null);
    }
  }, [suppliesState.payload]);

  /** Handles generic changes */
  function handleChange(data: string | Date, property: string) {
    setSupply({
      ...supply!,
      [property]: data,
    });
  }

  /** Handles a checked supply */
  function handleChecked(index: number) {
    const exists = checked.indexOf(index);
    let updates = [];
    if (exists !== -1) {
      updates = checked.filter((checkedIndex) => checkedIndex !== index);
    } else {
      updates = [index].concat(checked);
    }
    setChecked(updates);
  }

  /** Deletes a supply */
  function handleDeletion(supplyIndex: number) {
    setSupply({
      ...supply!,
      list: Object.assign([], supply!.list, {
        [supplyIndex]: {
          ...supply!.list[supplyIndex],
          deleted: true,
        },
      }),
    });
  }

  /** Handles all out of stock toggle */
  function handleAllInStock() {
    setSupply({
      ...supply!,
      list: supply!.list.map((supplyItem, i) => ({
        ...supplyItem,
        inStock: checked.includes(i) ? true : supplyItem.inStock,
      })),
    });
  }

  /** Handles all in stock toggle */
  function handleAllOutOfStock() {
    setSupply({
      ...supply!,
      list: supply!.list.map((supplyItem, i) => ({
        ...supplyItem,
        inStock: checked.includes(i) ? false : supplyItem.inStock,
      })),
    });
  }

  /** Handles a delete all */
  function handleDeleteAll() {
    let list = [...supply!.list];
    setSupply({
      ...supply!,
      list: list.map((supplyItem, i) => ({
        ...supplyItem,
        deleted: checked.includes(i) ? true : supplyItem.deleted,
      })),
    });
  }

  function handleToggleOutOfStock(i: number) {
    setSupply({
      ...supply!,
      list: Object.assign([], supply!.list, {
        [i]: {
          ...supply!.list[i],
          inStock: !supply!.list[i].inStock,
        },
      }),
    });
  }

  /** Handles the confirmation of adding an item from the modal */
  function handleAddSupplyItem(name: string) {
    // @ts-ignore
    const tempId = supply.list.length;
    setSupply({
      ...supply!,
      list: Object.assign([], supply!.list, {
        [tempId]: {
          ...supply!.list[tempId],
          newSupply: true,
          inStock: true,
          name,
        },
      }),
    });
    setAddSupplyItem(false);
  }

  /** Toggles the add supply modal */
  function toggleAddSupplyItem() {
    setAddSupplyItem(!addSupplyItem);
  }

  function getAllCheckedValues(allValuesChecked: boolean) {
    const list = supply!.list;
    if (allValuesChecked) {
      return [];
    } else {
      return list.map((item, i) => i);
    }
  }

  /** Renders the supplies */
  function renderSupplyItem(supplyItem: SupplyItem, supplyItemIndex: number) {
    return (
      <div className="separate" key={supplyItemIndex}>
        <div
          className={`hidden-actions pvs df jcsb aic ${
            supplyItem.deleted ? "card-disabled" : ""
          }`}
        >
          <div className="df aic">
            <Checkbox
              className="mrl"
              disabled={supplyItem.deleted}
              checked={checked.indexOf(supplyItemIndex) !== -1 || allChecked}
              key={supplyItemIndex}
              onChange={() => handleChecked(supplyItemIndex)}
              color="primary"
            />
            <InputLabel>{supplyItem.name}</InputLabel>
          </div>
          <div className="df aic">
            <Chip
              label={supplyItem.inStock ? "In stock" : "Out of stock"}
              variant="outlined"
              className="mls"
              color={supplyItem.inStock ? "default" : "secondary"}
            />
            <div className="df soh">
              <div
                className="cp mrs"
                onClick={() => handleToggleOutOfStock(supplyItemIndex)}
              >
                {supplyItem.inStock ? (
                  <RemoveShoppingCart color="action" />
                ) : (
                  <ShoppingCart color="action" />
                )}
              </div>
              <Delete
                onClick={() => handleDeletion(supplyItemIndex)}
                className="cp"
                color="action"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function toggleSelectAll() {
    setAllChecked(allChecked);
    setChecked(getAllCheckedValues(allChecked));
  }

  /** Handles closing */
  function handleClose() {
    navigate(`/locations/${id}`);
  }

  /** Handles save */
  function handleSave() {
    if (supply) {
      const updatedSupplies = {
        ...supply!,
        list: removeDeletedItems(supply!.list),
      };
      dispatch(updateSupplies(updatedSupplies));
    }
  }

  /** Renders content */
  function renderContent() {
    if (suppliesState.isFetching || !supply) {
      return <CircularProgress />;
    }
    return (
      <>
        <div className="df aic pbs">
          <Event className="mrs" color="action" />
          <TextField
            id="deliveryStartDate"
            label="Delivery Start Date"
            type="date"
            defaultValue={moment((supply! as Supply).lastDelivery).format(
              "YYYY-MM-DD"
            )}
            onChange={(e) => handleChange(e.target.value, "lastDelivery")}
            className="mrm"
          />
          <div className="mrm">
            <FormControl>
              <InputLabel id="frequency">Frequency</InputLabel>
              <Select
                labelId="frequency"
                label="Frequency"
                value={(supply! as Supply).frequency}
                // @ts-ignore
                onChange={(e) => handleChange(e.target.value, "frequency")}
              >
                <MenuItem value={1}>
                  <ListItemText primary="1 Week" />
                </MenuItem>
                <MenuItem value={2}>
                  <ListItemText primary="2 Weeks" />
                </MenuItem>
                <MenuItem value={3}>
                  <ListItemText primary="3 Weeks" />
                </MenuItem>
                <MenuItem value={4}>
                  <ListItemText primary="4 Weeks" />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="input-fake">
            <span className="input-label-mimic">Next Delivery</span>
            {/* 
        // @ts-ignore */}
            {getNextDelivery(supply.frequency, supply.lastDelivery)}
          </div>
        </div>
        <Divider />
        <div className="pvm">
          <span className="b">
            {(supply! as Supply).list
              ? (supply! as Supply).list.filter((item) => !item.inStock).length
              : 0}
            &nbsp;
          </span>
          Items out of stock
        </div>
        <div className="pvs separate df jcsb aic">
          <div className="df aic">
            <Checkbox
              className="mrl"
              onChange={toggleSelectAll}
              color="primary"
            />
            <InputLabel>Select All</InputLabel>
          </div>
          {checked.length !== 0 && (
            <div className="df">
              <ShoppingCart
                onClick={() => handleAllInStock()}
                className="cp mrs"
                color="action"
              />
              <RemoveShoppingCart
                onClick={() => handleAllOutOfStock()}
                className="cp mrs"
                color="action"
              />
              <Delete
                onClick={() => handleDeleteAll()}
                className="cp"
                color="action"
              />
            </div>
          )}
        </div>
        {(supply! as Supply).list &&
          (supply! as Supply).list
            .sort((a, b) => sortAlphaAscending(a, b, "name"))
            .map(renderSupplyItem)}
        <Button
          color="primary"
          onClick={toggleAddSupplyItem}
          variant="contained"
          className="__add-supply-item"
        >
          Add Supply Item
        </Button>
      </>
    );
  }

  return (
    <ClassNames>
      {({ css }) => (
        <>
          <Dialog
            fullScreen={true}
            open={true}
            onClose={handleClose}
            className="supplies-form"
          >
            <AppBar className={css({ position: "relative" })}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  onClick={handleClose}
                  aria-label="Close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={css({ flex: 1 })}
                >
                  Supplies | {location && location.name}
                </Typography>
                <IconButton color="inherit" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent className="ptm">{renderContent()}</DialogContent>
          </Dialog>
          <AddSupplyItem
            isOpen={addSupplyItem}
            onAddItem={handleAddSupplyItem}
            onToggleAddItem={toggleAddSupplyItem}
          />
        </>
      )}
    </ClassNames>
  );
}

export default SuppliesForm;
