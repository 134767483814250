import generalReducer, {
  GeneralReducerActionType,
  initialReducerState,
} from "./generalReducer";
import { ShiftsState, ShiftsAction, ShiftsActionType } from "../types";

const initialShiftsState = {
  shifts: initialReducerState([]),
  update: initialReducerState(),
};

const { ERROR, SUCCESS, LOADING } = GeneralReducerActionType;

export default function shiftsReducer(
  state: ShiftsState = initialShiftsState,
  action: ShiftsAction
) {
  switch (action.type) {
    case ShiftsActionType.GET_SHIFTS_LOADING:
      return {
        ...state,
        shifts: generalReducer(state.shifts, LOADING),
      };
    case ShiftsActionType.GET_SHIFTS_SUCCESS:
      return {
        ...state,
        shifts: generalReducer(state.shifts, SUCCESS, action.payload),
      };
    case ShiftsActionType.GET_SHIFTS_ERROR:
      return {
        ...state,
        shifts: generalReducer(state.shifts, ERROR, action.payload),
      };
    case ShiftsActionType.UPDATE_SHIFTS_LOADING:
      return {
        ...state,
        update: generalReducer(state.update, LOADING),
      };
    case ShiftsActionType.UPDATE_SHIFTS_SUCCESS:
      return {
        ...state,
        update: generalReducer(state.update, SUCCESS, action.payload),
      };
    case ShiftsActionType.UPDATE_SHIFTS_ERROR:
      return {
        ...state,
        update: generalReducer(state.update, ERROR, action.payload),
      };
    default:
      return state;
  }
}
