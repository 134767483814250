import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CircularProgress from "@mui/material/CircularProgress";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import Typography from "@mui/material/Typography";
import { CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  orderWeekdays,
  getLocationSchedules,
  getEmployee,
} from "../../../utils";
import { GlobalState, Schedule } from "../../../types";
import { GeneralReducerActionType } from "../../../reducers";
import { useEmployees } from "hooks/useEmployees";

const { LOADING } = GeneralReducerActionType;

interface Props {
  locationId: string;
}

function AssignedCleanersCard({ locationId }: Props) {
  const navigate = useNavigate();
  const employees = useEmployees();
  const isSchedulesLoading = useSelector<GlobalState, boolean>(
    (state) => state.schedules.schedules.status === LOADING
  );
  const schedules = useSelector<GlobalState, Schedule[]>(
    (state) => state.schedules.schedules.data
  );

  function renderSchedule(schedule: Schedule, i: number) {
    const cleaner = getEmployee(employees.data, schedule.employee);
    return (
      <Card className="mrm mbs w25p" variant="outlined">
        <CardContent>
          <Typography variant="body1" gutterBottom={true}>
            {cleaner ? `${cleaner.firstName} ${cleaner.lastName}` : ""}
          </Typography>
          <Typography variant="caption" component="div">
            {cleaner ? (
              <a className="link" href={`tel:${cleaner.phone}`}>
                {cleaner.phone}
              </a>
            ) : (
              ""
            )}
          </Typography>
          <Typography variant="caption" component="div">
            {schedule.duration}
          </Typography>
          <Typography variant="caption">
            {orderWeekdays(schedule.weekdays).join(" - ")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  function renderContent() {
    if (
      employees.status === LOADING ||
      isSchedulesLoading
    ) {
      return <CircularProgress />;
    }
    const locationSchedules = getLocationSchedules(schedules, locationId);
    return (
      <>
        <div className="df fww">
          {locationSchedules && locationSchedules.length
            ? React.Children.toArray(locationSchedules.map(renderSchedule))
            : "No assigned cleaners"}
        </div>
      </>
    );
  }

  return (
    <Card className="card-spacing">
      <CardHeader
        title="Assigned Cleaners"
        avatar={
          <Avatar>
            <SupervisorAccount />
          </Avatar>
        }
      />
      <CardContent>{renderContent()}</CardContent>
      <CardActions>
        <div className="mla">
          <Button
            color="primary"
            onClick={() =>
              navigate(`/locations/${locationId}/assignedCleaners`)
            }
            variant="contained"
          >
            Edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

export default AssignedCleanersCard;
