import * as React from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Person from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { CardContent, InputLabel } from "@mui/material";

import FillInShift from "./FillInShift";
import { User, Location, Shift } from "../../types";

interface Props {
  onAssign: (index: number) => void;
  onAssignAll: () => void;
  onSelect: (checked: boolean, index: number) => void;
  onToggleSelectAll: () => void;
  onUnassign: (index: number) => void;
  onUnassignAll: () => void;
  selectedShifts: number[];
  shifts: Shift[];
  employees: User[];
  locations: Location[];
}

function FillInList({
  shifts,
  employees,
  locations,
  onToggleSelectAll,
  selectedShifts,
  onUnassignAll,
  onAssignAll,
  onSelect,
  onUnassign,
  onAssign,
}: Props) {
  if (shifts.length) {
    return (
      <Card>
        <CardContent>
          <div className="pvs separate df jcsb aic">
            <div className="df aic">
              <Checkbox
                className="checkbox--small-padding"
                onChange={onToggleSelectAll}
                color="primary"
                checked={selectedShifts.length === shifts.length}
              />
              <InputLabel>Select All</InputLabel>
            </div>
            {!!selectedShifts.length && (
              <div className="df">
                <PersonOutline
                  onClick={() => onUnassignAll()}
                  className="cp mrs"
                />
                <Person onClick={() => onAssignAll()} className="cp" />
              </div>
            )}
          </div>
          {shifts.map((shift, index) => (
            <FillInShift
              key={shift.id}
              shift={shift}
              employees={employees}
              locations={locations}
              checked={
                selectedShifts.includes(index) ||
                shifts.length === selectedShifts.length
              }
              onCheck={(e) => onSelect(e.target.checked, index)}
              onUnassign={() => onUnassign(index)}
              onAssign={() => onAssign(index)}
            />
          ))}
        </CardContent>
      </Card>
    );
  }
  return <div>No shifts to display</div>;
}

export default FillInList;
