import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";

import { hideSnackbar, checkUserAuthentication } from "../../actions";
import Routes from "../../routes";
import { EventsState, GlobalState } from "../../types";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const userAuthenticated = useSelector<GlobalState>(
    (state) => state.authentication.isAuthenticated
  );
  const userFetching = useSelector<GlobalState>(
    (state) => state.authentication.isFetching
  );
  const dispatch = useDispatch();
  const eventsState = useSelector<GlobalState, EventsState>(
    (state) => state.events
  );

  useEffect(() => {
    if (eventsState.isOpen) {
      setTimeout(() => dispatch(hideSnackbar()), 4000);
    }
  });

  useEffect(() => {
    if (!userFetching) {
      if (userAuthenticated) {
        // Route to default page if just logged in
        if (["/login", "/"].includes(location.pathname)) {
          navigate("/locations");
        }
      } else {
        navigate("/login");
      }
    }
  }, [userAuthenticated, userFetching]);

  useEffect(() => {
    dispatch(checkUserAuthentication());
  }, []);

  if (userFetching) {
    return <CircularProgress />;
  }
  return (
    <div>
      <Routes />
      <Snackbar
        open={eventsState.isOpen}
        message={eventsState.payload}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </div>
  );
}

export default App;
