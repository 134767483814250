import * as React from "react";
import { TableContainer, Table, TableBody, Paper } from "@mui/material";

import Location from "../containers/locations/Location";
import { Location as LocationType } from "../types";

interface Props {
  locations: LocationType[];
}

function LocationsTable({ locations }: Props) {
  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {locations.length ? (
              React.Children.toArray(
                // @ts-ignore
                // tslint:disable-next-line:jsx-key
                locations.map((item) => <Location {...item} />)
              )
            ) : (
              <div className="tac mvm">No results found.</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default LocationsTable;
