import * as React from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ClassNames } from "@emotion/react";
import { Grid, CircularProgress, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import fileDataUrl from "../../utils/fileDataUrl";
import { GeneralReducerActionType } from "../../reducers";
import {
  User,
  MessageType,
  NewNotification,
  GlobalState,
  FilesState,
  NotificationsState,
} from "../../types";
import { createNotification, createNotificationReset } from "../../actions";
import { getIcon, getEmployee } from "../../utils";
import { messageTypes } from "../../constants";
import { uploadFile, uploadFileReset } from "../../actions/files";

const { LOADING, SUCCESS } = GeneralReducerActionType;

const initialNotification = {
  text: "",
  title: "",
  date: moment().toDate(),
  type: MessageType.INFO,
  users: [],
  seenBy: [],
};

interface Props {
  employees: User[] | null;
  onClose: () => void;
}

function AddNotificationForm({ employees, onClose }: Props) {
  const dispatch = useDispatch();
  const uploadFileState = useSelector<GlobalState, FilesState["uploadFile"]>(
    (state) => state.files.uploadFile
  );
  const createNotificationState = useSelector<
    GlobalState,
    NotificationsState["createNotification"]
  >((state) => state.notifications.createNotification);
  const [notification, setNotification] =
    React.useState<NewNotification>(initialNotification);
  const allSelected =
    notification.users.length === (employees ? employees.length : 0);

  /** On post success */
  React.useEffect(() => {
    if (createNotificationState.status === SUCCESS) {
      dispatch(createNotificationReset());
      dispatch(uploadFileReset());
      onClose();
    }
  }, [createNotificationState, dispatch, onClose]);

  /**
   * Post notification once attachment is uploaded
   */
  React.useEffect(() => {
    if (uploadFileState.status === SUCCESS) {
      dispatch(
        createNotification({
          ...notification,
          date: moment().toDate(),
          attachment: uploadFileState.data,
        })
      );
    }
  }, [uploadFileState.status, dispatch, uploadFileState, notification]);

  /** Handles notification changes */
  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<
    HTMLTextAreaElement | HTMLSelectElement | HTMLInputElement
  >) {
    setNotification({ ...notification, [name]: value });
  }

  /** Posts notification or uploads attachment first */
  function addNotification() {
    if (notification.attachment) {
      dispatch(uploadFile(notification.attachment));
    } else {
      dispatch(
        createNotification({
          ...notification,
          date: moment().toDate(),
        })
      );
    }
  }

  function handleUsersChange({
    target: { value },
  }: React.ChangeEvent<HTMLSelectElement>) {
    let users: string[] = [];
    if (value.includes("All") && value.length > 1) {
      users = [];
    } else if (value.includes("All")) {
      users = employees!.map((employee) => employee.id as string);
    } else {
      // @ts-ignore
      users = value;
    }
    setNotification({ ...notification, users });
  }

  /** Renders an employee's name */
  function employeeName(selectedEmployees: any): React.ReactNode {
    return selectedEmployees
      .map((employeeId: string) => {
        const employee = getEmployee(employees, employeeId);
        return employee
          ? `${employee.firstName} ${employee.lastName}`
          : "Unknown";
      })
      .join(", ");
  }

  return (
    <div className="fixed-textarea">
      <ClassNames>
        {({ css }) => {
          const input = css`
            width: 12rem;
          `;
          return (
            <Grid container={true} spacing={2}>
              <Grid item={true} md={5} sm={5}>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select
                  className={input}
                  value={notification.type}
                  input={<Input name="type" />}
                  // @ts-ignore
                  onChange={handleChange}
                  renderValue={(selected) =>
                    messageTypes.find((type) => type.key === selected)!.label
                  }
                >
                  {messageTypes.map((type) => (
                    <MenuItem value={type.key} key={type.key}>
                      {getIcon(type.key)}
                      <ListItemText primary={type.label} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item={true} md={7} sm={7}>
                <InputLabel htmlFor="to">To</InputLabel>
                <Select
                  className={input}
                  value={notification.users}
                  input={<Input id="users" />}
                  // @ts-ignore
                  onChange={handleUsersChange}
                  renderValue={employeeName}
                  multiple={true}
                >
                  <MenuItem value="All">
                    <Checkbox checked={allSelected} color="primary" />
                    <ListItemText primary="All" />
                  </MenuItem>
                  {employees &&
                    employees.map((employee) => (
                      <MenuItem value={employee.id} key={employee.id}>
                        <Checkbox
                          checked={
                            (notification.users as string[]).includes(
                              employee!.id as string
                            ) || allSelected
                          }
                          color="primary"
                        />
                        <ListItemText
                          primary={`${employee.firstName} ${employee.lastName}`}
                        />
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item={true} md={5} sm={5}>
                <InputLabel htmlFor="title" className="db">
                  Title
                </InputLabel>
                <Input
                  value={notification.title}
                  onChange={handleChange}
                  name="title"
                  placeholder="Enter a title here..."
                />
              </Grid>
              <Grid item={true} md={7} sm={7}>
                <InputLabel
                  htmlFor="attachment"
                  error={!!uploadFileState.error}
                >
                  Attachment
                </InputLabel>
                <input
                  accept="video/*,image/*,application/pdf"
                  id="attachment"
                  name="attachment"
                  type="file"
                  onChange={(e) => {
                    // @ts-ignore
                    const file = e.target.files[0];
                    if (file) {
                      // @ts-ignore
                      fileDataUrl(file, (url) =>
                        setNotification({
                          ...notification,
                          attachment: {
                            url,
                            type: file.type,
                            name: `${file.name}${Math.floor(
                              Math.random() * 100
                            )}`,
                          },
                        })
                      );
                    } else {
                      setNotification({
                        ...notification,
                        attachment: undefined,
                      });
                    }
                  }}
                />
                {!!uploadFileState.error && (
                  <FormHelperText error>
                    Unable to upload file, please try again
                  </FormHelperText>
                )}
              </Grid>
              <Grid item={true} md={12} sm={12}>
                <InputLabel htmlFor="text">Text</InputLabel>
                <textarea
                  value={notification.text}
                  name="text"
                  onChange={handleChange}
                  placeholder="Enter notification text here..."
                />
              </Grid>
              <Grid item={true} md={12} sm={12}>
                <Button
                  color="primary"
                  onClick={addNotification}
                  variant="contained"
                >
                  {[
                    createNotificationState.status,
                    uploadFileState.status,
                  ].includes(LOADING) ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Post"
                  )}
                </Button>
              </Grid>
            </Grid>
          );
        }}
      </ClassNames>
    </div>
  );
}

export default AddNotificationForm;
