import { InspectionFrequency, MessageType } from "../types";

export const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const hours = [
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
  "12:00 AM",
];

export const endHours = [
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
  "11:59 PM",
];

export const inspectionFrequencies: { [key: number]: InspectionFrequency } = {
  2: InspectionFrequency.TWO_WEEKS,
  3: InspectionFrequency.THREE_WEEKS,
  4: InspectionFrequency.FOUR_WEEKS,
  5: InspectionFrequency.FIVE_WEEKS,
  6: InspectionFrequency.SIX_WEEKS,
  7: InspectionFrequency.SEVEN_WEEKS,
  8: InspectionFrequency.EIGHT_WEEKS,
};

export const inspectionStatus: { [key: string]: string } = {
  assigned: "Assigned",
  working: "Working",
  checked: "Checked",
  complete: "Complete",
};

export const inspectionRating: { [key: string]: string } = {
  excellent: "Excellent",
  good: "Good",
  "needs-improvement": "Needs Improvement",
  "not-acceptable": "Not Acceptable",
};

export const messageTypes = [
  {
    key: MessageType.TIME,
    label: "Time",
  },
  {
    key: MessageType.INFO,
    label: "Info",
  },
  {
    key: MessageType.SECURITY,
    label: "Security",
  },
  {
    key: MessageType.URGENT,
    label: "Urgent",
  },
];

enum InstructionType {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  SPECIAL = "special",
  NEW = "new",
}

export enum ShiftStatus {
  CHECKED_IN = "checked-in",
  COMPLETE = "complete",
  INCOMPLETE = "incomplete",
  NO_SHOW = "no-show",
}

export const instructionTypes = [
  {
    key: InstructionType.DAILY,
    label: "Daily",
  },
  {
    key: InstructionType.WEEKLY,
    label: "Weekly",
  },
  {
    key: InstructionType.MONTHLY,
    label: "Monthly",
  },
  {
    key: InstructionType.SPECIAL,
    label: "Special",
  },
  {
    key: InstructionType.NEW,
    label: "New",
  },
];
