import * as React from "react";
import { useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";

import { authenticateUser } from "../../actions";
import "./styles.scss";

interface State {
  user: {
    userName: string;
    password: string;
  };
}

const initialState = {
  user: {
    userName: "",
    password: "",
  },
};

function Login() {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<State>(initialState);

  function handleChange(key: string, value: string) {
    setState({ user: { ...state.user, [key]: value } });
  }

  function handleLogin() {
    dispatch(authenticateUser(state.user));
  }

  return (
    <form
      className="df aic fdc vh100 mtl login"
      autoComplete="off"
      onSubmit={handleLogin}
    >
      <input name="password" type="password" style={{ display: "none" }} />
      <Typography variant="h2" gutterBottom={true}>
        Bishop Clean Care
      </Typography>
      <Card className="__card">
        <CardContent>
          <InputLabel htmlFor="username">Username</InputLabel>
          <Input
            onChange={({ target: { value } }) =>
              handleChange("userName", value)
            }
            defaultValue={state.user.userName}
            fullWidth={true}
            name="username"
            className="mbm"
          />
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            onChange={({ target: { value } }) =>
              handleChange("password", value)
            }
            defaultValue={state.user.password}
            type="password"
            fullWidth={true}
            name="password"
            className="mbm"
            autoComplete="false"
          />
          <div className="df jcfe">
            <Button
              color="primary"
              variant="contained"
              onClick={handleLogin}
              className="mvm"
            >
              Login
            </Button>
          </div>
        </CardContent>
      </Card>
    </form>
  );
}

export default Login;
