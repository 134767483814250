import * as React from "react";
import ChatBubble from "@mui/icons-material/ChatBubble";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import Typography from "@mui/material/Typography";
import { TableCell, TableRow } from "@mui/material";
import { red, grey, indigo } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { ClassNames } from "@emotion/react";

import { getStatus } from "../../utils";
import { Shift, Address, User, MessageType } from "../../types";
import { ChatBubbleOutlineOutlined } from "@mui/icons-material";

interface ParsedShift extends Omit<Shift, "employee"> {
  employee: User;
}

interface Props {
  address: Address;
  id: string;
  name: string;
  shifts: ParsedShift[];
  outOfStockCount: number;
  newMessageType: MessageType;
}

function Location({
  address,
  id,
  name,
  shifts,
  outOfStockCount,
  newMessageType,
}: Props) {
  const navigate = useNavigate();

  /** Render employee list item. */
  function renderEmployee(shift: ParsedShift, i: number) {
    return i < 3 ? (
      <li key={shift.id} className="df aic">
        {getStatus(shift.status)}
        <Typography variant="caption" className="mlxs">
          {shift.employee
            ? `${shift.employee.firstName} ${shift.employee.lastName}`
            : ""}
        </Typography>
      </li>
    ) : (
      ""
    );
  }

  /** Takes the user to the message board for the location */
  function handleSeeMessages(event: React.MouseEvent<SVGSVGElement>) {
    event.stopPropagation();
    navigate(`/locations/${id}/messageBoard`);
  }

  return (
    <TableRow onClick={() => navigate(`/locations/${id}`)}>
      <TableCell style={{ width: "45%" }}>
        <Typography variant="subtitle1" gutterBottom={true}>
          {name}
        </Typography>
        <Typography variant="caption" gutterBottom={true}>
          {`${address.line1} ${address.line2} ${address.city}, ${address.state}`}
        </Typography>
      </TableCell>
      <TableCell>
        {shifts && shifts.length ? (
          <ul>
            {shifts.slice(0, 3).map(renderEmployee)}
            {shifts.length > 3 && (
              <Typography variant="caption" gutterBottom={true} color="primary">
                +{shifts.length - 3} more
              </Typography>
            )}
          </ul>
        ) : (
          <Typography variant="body2" gutterBottom={true}>
            No shifts this past week
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {outOfStockCount > 0 && (
          <div className="__icon-detail">
            <ShoppingCart fontSize="small" />
            <Typography variant="caption" className="mlxs">
              {outOfStockCount}
            </Typography>
          </div>
        )}
        <ClassNames>
          {({ css }) => {
            if (!newMessageType) {
              return (
                <ChatBubbleOutlineOutlined
                  className={css`
                    color: ${grey[500]};
                    cursor: pointer;
                  `}
                  onClick={handleSeeMessages}
                />
              );
            }
            const messageStyling = {
              [MessageType.INFO]: css`
                color: ${indigo[500]};
                cursor: pointer;
              `,
              [MessageType.SECURITY]: css`
                color: ${indigo[500]};
                cursor: pointer;
              `,
              [MessageType.TIME]: css`
                color: ${indigo[500]};
                cursor: pointer;
              `,
              [MessageType.URGENT]: css`
                color: ${red[500]};
                cursor: pointer;
              `,
            };
            return (
              <ChatBubble
                className={messageStyling[newMessageType]}
                onClick={handleSeeMessages}
              />
            );
          }}
        </ClassNames>
      </TableCell>
    </TableRow>
  );
}

export default Location;
