import { Action } from "redux";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";

import { db } from "../firebaseconfig";
import { extractSnapshot } from "../utils";
import { ThunkResult, InspectionSchedule } from "../types";

export interface InspectionSchedulesAction extends Action {
  type: InspectionSchedulesActionType;
  payload?: InspectionSchedule[];
}

export enum InspectionSchedulesActionType {
  GET_INSPECTION_SCHEDULES_LOADING = "GET_INSPECTION_SCHEDULES_LOADING",
  GET_INSPECTION_SCHEDULES_ERROR = "GET_INSPECTION_SCHEDULES_ERROR",
  GET_INSPECTION_SCHEDULES_SUCCESS = "GET_INSPECTION_SCHEDULES_SUCCESS",
  UPDATE_INSPECTION_SCHEDULES_LOADING = "UPDATE_INSPECTION_SCHEDULES_LOADING",
  UPDATE_INSPECTION_SCHEDULES_ERROR = "UPDATE_INSPECTION_SCHEDULES_ERROR",
  UPDATE_INSPECTION_SCHEDULES_SUCCESS = "UPDATE_INSPECTION_SCHEDULES_SUCCESS",
}

export const fetchInspectionSchedules = (): ThunkResult<
  InspectionSchedule[]
> => {
  return async (dispatch) => {
    dispatch(fetchInspectionSchedulesLoading());
    onSnapshot(collection(db, "frequentInspections"), (inspections) => {
      dispatch(fetchInspectionSchedulesSuccess(extractSnapshot(inspections)));
    });
  };
};

export const updateInspectionSchedule = (
  inspectionSchedule: InspectionSchedule
): ThunkResult<InspectionSchedulesAction> => {
  const { id, ...rest } = inspectionSchedule;
  return async (dispatch) => {
    dispatch(updateInspectionScheduleLoading());
    const timestamped = {
      ...rest,
      timestamp: serverTimestamp(),
    };
    return id
      ? setDoc(doc(db, "frequentInspections", id), rest).then(() =>
          dispatch(updateInspectionScheduleSuccess())
        )
      : addDoc(collection(db, "frequentInspections"), timestamped).then(() =>
          dispatch(updateInspectionScheduleSuccess())
        );
  };
};

export const updateInspectionScheduleSuccess =
  (): InspectionSchedulesAction => ({
    type: InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_SUCCESS,
  });

export const updateInspectionScheduleLoading =
  (): InspectionSchedulesAction => ({
    type: InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_LOADING,
  });

export const updateInspectionScheduleError = (): InspectionSchedulesAction => ({
  type: InspectionSchedulesActionType.UPDATE_INSPECTION_SCHEDULES_ERROR,
});

export const fetchInspectionSchedulesSuccess = (
  payload: InspectionSchedule[]
): InspectionSchedulesAction => {
  return {
    type: InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_SUCCESS,
    payload,
  };
};

export const fetchInspectionSchedulesLoading =
  (): InspectionSchedulesAction => ({
    type: InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_LOADING,
  });

export const fetchInspectionSchedulesError = (): InspectionSchedulesAction => ({
  type: InspectionSchedulesActionType.GET_INSPECTION_SCHEDULES_ERROR,
});
