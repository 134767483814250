import moment from "moment";

/** Gets the next delivery date for a location's supplies */
function getNextDelivery(frequency: number, lastDelivery?: Date) {
  if (!frequency) {
    return "No frequency set";
  }
  const weeksPassed = moment().diff(lastDelivery, "weeks");
  if (weeksPassed < frequency) {
    return moment(lastDelivery).add(frequency, "weeks").format("MM/DD/YYYY");
  } else {
    let count = frequency;
    for (let i = 0; i < weeksPassed + frequency / frequency; i++) {
      const newDate = moment(lastDelivery)
        .add(count, "weeks")
        .format("MM/DD/YYYY");
      if (moment(newDate).isSameOrAfter(moment(), "day")) {
        return newDate;
      }
      count += frequency;
    }
  }
  return "Unknown date";
}

export default getNextDelivery;
