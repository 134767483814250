import AppBar from "@mui/material/AppBar";
import * as React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import packageJson from "../../../package.json";
import { usePrevious } from "../../hooks";

const tabs: string[] = ["locations", "employees", "fill-in", "notifications"];

function Dashboard() {
  const location = useLocation();
  const page = location.pathname.split("/")[1];
  const index = tabs.indexOf(page);
  const tab = index === -1 ? 0 : index;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = React.useState(tab);
  const previousTab = usePrevious(currentTab);

  /**
   * Handle tab changes
   */
  React.useEffect(() => {
    if (previousTab !== currentTab) {
      navigate("/" + tabs[currentTab]);
    }
  }, [currentTab]);

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="inherit" className="flex">
            Bishop Clean ({packageJson.version})
          </Typography>
        </Toolbar>
        <Tabs
          value={currentTab}
          onChange={(e, tabIndex) => setCurrentTab(tabIndex)}
          scrollButtons="auto"
          variant="scrollable"
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab label="Locations" />
          <Tab label="Employees" />
          <Tab label="Fill-In Shifts" />
          <Tab label="Notifications" />
        </Tabs>
      </AppBar>
      <main style={{ padding: 32 }}>
        <Outlet />
      </main>
    </>
  );
}

export default Dashboard;
