import generalReducer, {
  GeneralReducerActionType,
  initialReducerState,
} from "./generalReducer";
import {
  NotificationsState,
  NotificationsAction,
  NotificationsActionType,
} from "../types";

const { LOADING, SUCCESS, ERROR, NONE } = GeneralReducerActionType;

const initialNotificationsState = {
  notifications: initialReducerState([]),
  createNotification: initialReducerState(),
};

export default function notificationsReducer(
  state: NotificationsState = initialNotificationsState,
  action: NotificationsAction
) {
  switch (action.type) {
    case NotificationsActionType.GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: generalReducer(state.notifications, LOADING),
      };
    case NotificationsActionType.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: generalReducer(
          state.notifications,
          SUCCESS,
          action.payload
        ),
      };
    case NotificationsActionType.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: generalReducer(
          state.notifications,
          ERROR,
          action.payload
        ),
      };
    case NotificationsActionType.CREATE_NOTIFICATION_LOADING:
      return {
        ...state,
        createNotification: generalReducer(state.createNotification, LOADING),
      };
    case NotificationsActionType.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        createNotification: generalReducer(
          state.createNotification,
          SUCCESS,
          action.payload
        ),
      };
    case NotificationsActionType.CREATE_NOTIFICATION_ERROR:
      return {
        ...state,
        createNotification: generalReducer(
          state.createNotification,
          ERROR,
          action.payload
        ),
      };
    case NotificationsActionType.CREATE_NOTIFICATION_RESET:
      return {
        ...state,
        createNotification: generalReducer(state.createNotification, NONE),
      };
    default:
      return state;
  }
}
