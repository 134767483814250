import { Action } from "redux";
import { onSnapshot, serverTimestamp } from "firebase/firestore";

import { db } from "../firebaseconfig";
import { Inspection, ThunkResult } from "../types";
import { extractSnapshot, getInspection } from "../utils";
import { collection, doc, writeBatch } from "firebase/firestore";
import { inspectionsService } from "services";

export enum InspectionsActionType {
  GET_INSPECTIONS_LOADING = "GET_INSPECTIONS_LOADING",
  GET_INSPECTIONS_ERROR = "GET_INSPECTIONS_ERROR",
  GET_INSPECTIONS_SUCCESS = "GET_INSPECTIONS_SUCCESS",
  UPDATE_INSPECTIONS_LOADING = "UPDATE_INSPECTIONS_LOADING",
  UPDATE_INSPECTIONS_ERROR = "UPDATE_INSPECTIONS_ERROR",
  UPDATE_INSPECTIONS_SUCCESS = "UPDATE_INSPECTIONS_SUCCESS",
}

export interface InspectionsAction extends Action {
  type: InspectionsActionType;
  payload?: Inspection[];
}

export const fetchInspections = (): ThunkResult<Inspection[]> => {
  return async (dispatch) => {
    dispatch(fetchInspectionsLoading());
    onSnapshot(inspectionsService.getAll(), (inspections) => {
      dispatch(getInspectionsSuccess(extractSnapshot(inspections)));
    });
  };
};

export const getInspectionsSuccess = (
  payload: Inspection[]
): InspectionsAction => ({
  type: InspectionsActionType.GET_INSPECTIONS_SUCCESS,
  payload,
});

export const fetchInspectionsError = (): InspectionsAction => ({
  type: InspectionsActionType.GET_INSPECTIONS_ERROR,
});

export const fetchInspectionsLoading = (): InspectionsAction => ({
  type: InspectionsActionType.GET_INSPECTIONS_LOADING,
});

export const updateInspections = (
  inspections: Inspection[]
): ThunkResult<InspectionsAction> => {
  return (dispatch, store) => {
    dispatch(updateInspectionsLoading());
    let batch = writeBatch(db);
    for (let i = 0; i < inspections.length; i++) {
      const { id, newInspection, deleted, ...thisInspection } = inspections[i];
      if (newInspection) {
        const newId = doc(collection(db, "inspections")).id;
        const timestamped = {
          ...thisInspection,
          timestamp: serverTimestamp(),
        };
        batch.set(doc(db, "inspections", newId), timestamped);
      } else if (
        JSON.stringify(thisInspection) !==
        JSON.stringify(getInspection(store().inspections.inspections.data, id))
      ) {
        batch.set(doc(db, "inspections", id), thisInspection);
      }
    }
    return batch.commit().then(() => dispatch(updateInspectionsSuccess()));
  };
};

export const updateInspectionsSuccess = (): InspectionsAction => ({
  type: InspectionsActionType.UPDATE_INSPECTIONS_SUCCESS,
});

export const updateInspectionsError = (): InspectionsAction => ({
  type: InspectionsActionType.UPDATE_INSPECTIONS_ERROR,
});

export const updateInspectionsLoading = (): InspectionsAction => ({
  type: InspectionsActionType.UPDATE_INSPECTIONS_LOADING,
});
