import {
  AuthenticationState,
  AuthenticationAction,
  AuthenticationActionType,
} from "../types";

export default function authenticationReducer(
  state: AuthenticationState = {
    isFetching: true,
    isAuthenticated: false,
    payload: null,
    error: false,
  },
  { payload, type }: AuthenticationAction
) {
  switch (type) {
    case AuthenticationActionType.AUTHENTICATE_USER_LOADING:
      return {
        ...state,
        isFetching: true,
      };
    case AuthenticationActionType.AUTHENTICATE_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        payload: payload as string,
        isFetching: false,
      };
    case AuthenticationActionType.AUTHENTICATE_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        error: true,
      };
    case AuthenticationActionType.DEAUTHENTICATE_USER:
      return {
        ...state,
        isFetching: false,
        error: false,
        payload: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
