import { WhereFilterOp } from "firebase/firestore";
import { doc, writeBatch } from "firebase/firestore";

import { db } from "../firebaseconfig";
import {
  frequentInspectionsService,
  frequentShiftsService,
  inspectionsService,
  messagesService,
  securityService,
  shiftsService,
  suppliesService,
  instructionsService,
} from ".";
import firebaseBatchLimitHandler from "utils/firebaseBatchLimitHandler";

const locationService = {
  async delete(id: string) {
    const locationQuery: [string, WhereFilterOp, string] = [
      "location",
      "==",
      id,
    ];
    await firebaseBatchLimitHandler(
      frequentInspectionsService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(
      frequentShiftsService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(
      inspectionsService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(
      messagesService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(
      securityService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(shiftsService.getByQuery(...locationQuery));
    await firebaseBatchLimitHandler(
      suppliesService.getByQuery(...locationQuery)
    );
    await firebaseBatchLimitHandler(
      instructionsService.getByQuery(...locationQuery)
    );

    // TODO: Show snackbar if something fails through this whole orchestration

    const batch = writeBatch(db);
    batch.delete(doc(db, "locations", id));

    return await batch.commit();
  },
};

export default locationService;
