import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";

import { Inspection, User } from "types";
import { getRating, getStatus } from "utils";

interface Props {
  inspector?: User;
  inspection: Inspection;
}

function InspectionSummary({ inspector, inspection }: Props) {
  return (
    <>
      <div className="df aic jcsb">
        <Typography variant="caption">Inspector</Typography>
        <Typography variant="body2">
          {inspector ? `${inspector.firstName} ${inspector.lastName}` : ""}
        </Typography>
      </div>
      <div className="df aic jcsb">
        <Typography variant="caption">Date</Typography>
        <Typography variant="body2">
          {moment(inspection.date).format("MM/DD/YYYY")}
        </Typography>
      </div>
      <div className="df aic jcsb">
        <Typography variant="caption">Status</Typography>
        <Typography variant="body2">{getStatus(inspection.status)}</Typography>
      </div>
      <div className="df aic jcsb">
        <Typography variant="caption">Rating</Typography>
        <Typography variant="body2">{getRating(inspection.rating)}</Typography>
      </div>
    </>
  );
}

export default InspectionSummary;
