export default function fileDataUrl(
  file: Blob,
  handler: (url: string) => void
) {
  const reader = new FileReader();

  reader.addEventListener(
    "load",
    // @ts-ignore
    (r) => {
      // @ts-ignore
      handler(r.target.result);
    },
    false
  );
  reader.readAsDataURL(file);
}
